import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import initialTheme from '../../theme/theme';
import { Hebrfonts } from '../../theme/foundations/fonts';
// custom hook which return theme and update theme function
export default function useUpdateFFTheme() {
  const [theme, setTheme] = useState(initialTheme);
  const { i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language === 'he') {
      const HebrewStyle = { ...initialTheme };
      HebrewStyle.fonts = Hebrfonts;
      setTheme(HebrewStyle);
    } else {
      setTheme(initialTheme);
    }
  }, [i18n.language]);
  return { theme, setTheme };
}
