// Chakra imports
import {
  Button,
  Flex,
  HStack,
  IconButton,
  Show,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { useTranslation } from 'react-i18next';
import { MdCancel, MdCheck } from 'react-icons/md';

// Custom components

export default function RequestComponent(props: {
  date: string;
  name: string;
  [x: string]: any;
}) {
  const { date, sum, icon, name, ...rest } = props;
  const { t } = useTranslation();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      {...rest}
      gap={4}>
      <AuthImage
        w={34}
        h={34}
        path={''}
        isAvatar
        avatarDefIconStyles={{ h: 5, w: 5 }}
      />
      <Flex direction="column" align="start" me="auto">
        <Text color={textColor} fontSize="sm" me="6px" fontWeight="700">
          {name}
        </Text>
        <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
          {date}
        </Text>
      </Flex>

      <Show below="md">
        <HStack>
          <IconButton variant="accept" aria-label="Accept" icon={<MdCheck />} />
          <IconButton
            variant="decline"
            aria-label="Decline"
            icon={<MdCancel />}
          />
        </HStack>
      </Show>
      <Show above="md">
        <HStack>
          <Button variant="decline">
            {t('the_office.colleague_requests.decline')}
          </Button>
          <Button variant="accept">
            {t('the_office.colleague_requests.accept')}
          </Button>
        </HStack>
      </Show>
    </Flex>
  );
}
