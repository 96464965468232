import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { UserWithTokens, isAdminExist, SignUpUser, User } from './@types';
import store from '../contexts/redux/store';
import {
  clearCredentials,
  setCredentials,
} from '../contexts/redux/auth/authSlice';
import { clearCookie } from 'utils/cookie.util';

const BASE_URL =
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1';
const CLIENT_URL = process.env.REACT_APP_CLIENT_URL || 'http://localhost:3001';
class AuthService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Add an interceptor to attach the token to each request
    this.api.interceptors.request.use((config) => {
      const state = store.getState();
      const token = state.auth?.tokens?.access.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
    // Add an interceptor which detect 401 status code
    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          try {
            try {
              const logOut = async () => {
                try {
                  const state = store.getState();
                  const refreshToken = state.auth.tokens?.refresh.token;
                  await this.api.post('/auth/logout', { refreshToken });
                  store.dispatch(clearCredentials());
                  localStorage.removeItem('token');
                  clearCookie('auth');
                } catch (error) {
                  console.error('Error while logging out', error);
                }
              };
              logOut();
            } catch (e) {
              console.log('Error in logout:', e);
            }
          } catch (error) {
            console.error('Error while logging out', error);
          }
        }

        return Promise.reject(error);
      },
    );
  }
  public async healthCheck(): Promise<void> {
    await this.api.get('/auth/health');
  }

  public async register(user: {
    fullName: string;
    email: string;
    password: string;
    firebaseUID: string;
  }): Promise<void> {
    const response: AxiosResponse<UserWithTokens> = await this.api.post(
      '/auth/register',
      user,
    );
    store.dispatch(setCredentials(response.data));
    localStorage.setItem('token', JSON.stringify(response.data));
  }

  public async signup(user: SignUpUser): Promise<User | null> {
    const response: AxiosResponse<UserWithTokens> = await this.api.post(
      '/auth/signup',
      user,
    );
    store.dispatch(setCredentials(response.data));
    localStorage.setItem('token', JSON.stringify(response.data));
    return response.data.user;
  }

  public async login(credentials: {
    email: string;
    password: string;
  }): Promise<void> {
    const response: AxiosResponse<UserWithTokens> = await this.api.post(
      '/auth/login',
      credentials,
    );
    store.dispatch(setCredentials(response.data as UserWithTokens));
    localStorage.setItem('token', JSON.stringify(response.data));
  }

  public async loginWithToken(accessToken: string): Promise<void> {
    console.log('loginWithToken...', accessToken);
    const response: AxiosResponse<UserWithTokens> = await this.api.get(
      '/auth/login-token',
    );
    store.dispatch(setCredentials(response.data as UserWithTokens));
    localStorage.setItem('token', JSON.stringify(response.data));
  }

  public async logout(): Promise<void> {
    try {
      const state = store.getState();
      const refreshToken = state.auth.tokens?.refresh.token;
      await this.api.post('/auth/logout', { refreshToken });
      store.dispatch(clearCredentials());
      localStorage.removeItem('token');
    } catch (e) {
      console.log('Error in logout:', e);
    }
  }

  public async refreshTokens(): Promise<void> {
    const state = store.getState();
    const refreshToken = state.auth.tokens?.refresh;
    const response: AxiosResponse<UserWithTokens> = await this.api.post(
      '/auth/refresh-tokens',
      { refreshToken },
    );
    store.dispatch(setCredentials(response.data as UserWithTokens));
    localStorage.setItem('token', JSON.stringify(response.data));
  }

  public async forgotPassword(email: string): Promise<void> {
    await this.api.post('/auth/forgot-password', { email });
  }

  public async resetPassword(token: string, password: string): Promise<void> {
    await this.api.post(
      '/auth/reset-password',
      { password },
      {
        params: { token },
      },
    );
  }

  public async sendVerificationEmail(): Promise<void> {
    await this.api.post('/auth/send-verification-email');
  }

  public async verifyEmail(token: string): Promise<void> {
    await this.api.post(
      '/auth/verify-email',
      {},
      {
        params: { token },
      },
    );
  }

  public async isAdminExist(): Promise<isAdminExist> {
    const response: AxiosResponse<isAdminExist> = await this.api.get(
      '/auth/is-admin-exist',
    );
    return response.data;
  }

  public async signInWithGoogle(): Promise<void> {
    window.location.href = `${BASE_URL}/auth/google`;
  }

  public async signOutWithGoogle(error?: string): Promise<void> {
    const redirectUrl = `${CLIENT_URL}/auth/sign-in${
      error ? `?error=${error}` : ''
    }`;
    document.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${redirectUrl}`;
  }

  public async changePassword(body: {
    oldPassword: string;
    newPassword: string;
  }): Promise<any> {
    const response = await this.api.post(`/auth/change-password`, body);
    return response;
  }
}

const _authService = new AuthService(BASE_URL);
export default _authService;
