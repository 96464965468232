import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  setCurrentCalendar,
  updateCalendar,
} from 'contexts/redux/calendar/calendarsSlice';
import {
  closeCalendarsDialog,
  setAddCalendarDialog,
  setShareMyCalendarDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiPlus, PiShareNetwork } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar } from 'services/@types';
import { CalendarItemComponent } from './CalendarItemComponent';
export const CalendarsModal = (props: {}) => {
  const { onClose } = useDisclosure();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();

  const [editInput, setEditInput] = useState('');
  const [editingNameForCalendar, setEditingNameForCalendar] =
    useState<string>();

  const { calendarsDialog } = useSelector((state: RootState) => state.dialogs);
  const { calendars, currentCalendar } = useSelector(
    (state: RootState) => state.calendars,
  );

  const handleUpdateCalendarName = useCallback(() => {
    dispatch(
      updateCalendar({
        userId: currentCalendar?.id,
        updates: { name: editInput },
      }),
    );
    dispatch(setCurrentCalendar(null));
    setEditingNameForCalendar(null);
  }, [currentCalendar, dispatch, editInput]);

  const handleStartEditingCalendar = useCallback(() => {
    setEditingNameForCalendar(currentCalendar?.id);
    setEditInput(currentCalendar?.name || '');
  }, [currentCalendar?.id, currentCalendar?.name]);

  const handleInputChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, calendar: Calendar) => {
      editingNameForCalendar === calendar.id && setEditInput(e.target.value);
    },
    [editingNameForCalendar],
  );
  const calendarsSections = useMemo(() => {
    // Group calendars by type and provider
    const grouped = calendars.reduce(
      (acc: any, calendar) => {
        if (calendar.type === 'internal') {
          acc.internal.push(calendar);
        } else if (calendar.type === 'external') {
          if (!acc.external[calendar.provider]) {
            acc.external[calendar.provider] = [];
          }
          acc.external[calendar.provider].push(calendar);
        } else if (calendar.type === 'shared') {
          acc.shared.push(calendar);
        }
        return acc;
      },
      { internal: [], external: {}, shared: [] },
    );

    // Create the final sections array
    const sections = [
      { title: 'internal', calendars: grouped.internal },
      ...Object.entries(grouped.external).map(([provider, calendars]) => ({
        title: `${provider}`,
        calendars,
      })),
      { title: 'shared', calendars: grouped.shared },
    ];

    // Remove any empty sections
    return sections.filter((section) => section.calendars.length > 0);
  }, [calendars]);
  return (
    <Modal
      isOpen={!!calendarsDialog}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered>
      <ModalOverlay />
      <ModalContent mx="16px" borderRadius={'3xl'} py={4} maxW={540}>
        <ModalBody>
          <HStack mb={5} w="100%">
            <Text flex={1} fontSize="2xl" fontWeight={'bold'}>
              {t('calendar.calendars_modal.title')}
            </Text>
            <ModalCloseButton
              position="relative"
              top={0}
              right={0}
              onClick={() => {
                dispatch(closeCalendarsDialog());
              }}
            />
          </HStack>
          <VStack gap={6} w="100%">
            <HStack w="100%" gap={6} spacing={0}>
              <Button
                onClick={() => {
                  dispatch(setShareMyCalendarDialog({}));
                }}
                flex={1}
                variant={'h1cta'}
                rightIcon={<PiShareNetwork />}>
                {t('calendar.calendars_modal.share_my_calendar')}
              </Button>
              <Button
                onClick={() => {
                  dispatch(setAddCalendarDialog({}));
                }}
                flex={1}
                variant="default"
                rightIcon={<PiPlus />}>
                {t('calendar.calendars_modal.add_calendar')}
              </Button>
            </HStack>
            {calendarsSections.map((section) => (
              <VStack
                key={section.title}
                w="100%"
                spacing={0}
                gap={1}
                align="start">
                <Text
                  fontSize="sm"
                  fontWeight="bold"
                  color="brand.300"
                  display={section.title === 'internal' ? 'none' : 'block'}>
                  {t(`calendar.calendars_modal.${section.title}`)}
                </Text>
                <VStack gap={5} w="100%">
                  {section.calendars.map((calendar: Calendar) => (
                    <CalendarItemComponent
                      key={calendar.id}
                      calendar={calendar}
                      handleInputChanged={handleInputChanged}
                      editingNameForCalendar={editingNameForCalendar}
                      setEditingNameForCalendar={setEditingNameForCalendar}
                      editInput={editInput}
                      handleUpdateCalendarName={handleUpdateCalendarName}
                      handleStartEditingCalendar={handleStartEditingCalendar}
                      setCurrentCalendar={setCurrentCalendar}
                      currentCalendar={currentCalendar}
                    />
                  ))}
                </VStack>
              </VStack>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
