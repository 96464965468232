import { Text, VStack } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactPerson } from 'services/@types';
interface AddContactPersonProps {
  contact: ContactPerson;
  label: string;
  handleContactChange: (contact: ContactPerson) => void;
}
export const AddContactPerson: React.FC<AddContactPersonProps> = ({
  contact,
  label,
  handleContactChange,
}) => {
  const { t } = useTranslation();
  const [contactProps, setContactProps] = useState<ContactPerson>({});

  useEffect(() => {
    handleContactChange(contactProps);
  }, [contactProps, handleContactChange]);

  useEffect(() => {
    setContactProps(contact || {});
  }, [contact]);

  return (
    <VStack gap={4} w="100%" maxW={{ base: '100%', md: '310px' }} align="start">
      <Text fontSize={'md'} fontWeight="bold" color="brand.900">
        {label || 'Contact A'}
      </Text>
      <InputField
        id="name"
        placeholder={t('suppliers.modals.contact_person.full_name')}
        w="100%"
        label={t('suppliers.modals.contact_person.full_name')}
        value={contactProps?.fullName}
        onChange={(e: any) =>
          setContactProps({ ...contactProps, fullName: e.target.value })
        }
      />
      <InputField
        id="phone"
        w="100%"
        placeholder={t('suppliers.modals.contact_person.phone')}
        label={t('suppliers.modals.contact_person.phone')}
        value={contactProps?.phone}
        onChange={(e: any) =>
          setContactProps({ ...contactProps, phone: e.target.value })
        }
      />
      <InputField
        w="100%"
        id="email"
        placeholder={t('suppliers.modals.contact_person.email_address')}
        label={t('suppliers.modals.contact_person.email_address')}
        value={contactProps?.email}
        onChange={(e: any) =>
          setContactProps({ ...contactProps, email: e.target.value })
        }
      />
    </VStack>
  );
};
