import {
  Button,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import {
  createAlbum,
  deleteAlbum,
  updateAlbum,
} from 'contexts/redux/albums/albumSlice';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  closeAddAlbumDialog,
  closeAlertDialog,
  openAlertDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Album } from 'services/@types';
interface AddAlbumModalProps {}
export const AddAlbumModal: React.FC<AddAlbumModalProps> = () => {
  const { onClose } = useDisclosure();
  const { t } = useTranslation();
  const dialog = useSelector(
    (state: RootState) => state.dialogs.addAlbumDialog,
  );
  const user = useMyUser();
  const dispatch = useDispatch<any>();
  const [albumProps, setAlbumProps] = useState<Partial<Album>>({});
  const [nameErrorMessage, setNameErrorMessage] = React.useState<string>('');

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setNameErrorMessage('');
    setAlbumProps((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleSaveClicked = useCallback(() => {
    if (!albumProps.name) {
      setNameErrorMessage(t('gallery.new_album.error.name_is_required'));
      return;
    }
    if (dialog?.item?.id) {
      dispatch(
        updateAlbum({
          albumId: dialog.item.id,
          updates: { ...albumProps } as Album,
        }),
      );
      return;
    }
    dispatch(
      createAlbum({
        ...albumProps,
        targetId: user.id,
        target: 'user',
      } as Album),
    );
    dispatch(closeAddAlbumDialog());
  }, [albumProps, dialog?.item?.id, dispatch, user?.id, t]);

  useEffect(() => {
    setAlbumProps(dialog?.item || {});
  }, [dialog?.item]);

  const handleCancelClicked = useCallback(() => {
    dispatch(closeAddAlbumDialog());
  }, [dispatch]);

  const handleDeleteClicked = useCallback(() => {
    if (dialog?.item?.id) {
      dispatch(
        openAlertDialog({
          title: t('gallery.new_album.delete.title'),
          content: t('gallery.new_album.delete.description'),
          onConfirm: () => {
            dispatch(deleteAlbum({ albumId: dialog.item.id }));
            dispatch(closeAddAlbumDialog());
          },
          onClose: () => dispatch(closeAlertDialog()),
        }),
      );
    }
  }, [dialog?.item?.id, dispatch, t]);

  return (
    <Modal isOpen={!!dialog?.item} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent p={'24px'} maxW="540px" borderRadius="3xl" mx={2} gap={6}>
        <ModalHeader p={0}>
          <HStack w="100%">
            <Text fontSize="2xl">
              {albumProps.id
                ? t('gallery.new_album.edit_title')
                : t('gallery.new_album.title')}
            </Text>
            <Spacer />

            <ModalCloseButton
              top={0}
              right={0}
              position={'relative'}
              onClick={handleCancelClicked}
            />
          </HStack>
        </ModalHeader>
        <VStack w="100%" gap={8}>
          <VStack align="start" gap={8} w="100%">
            <VStack align="start" justify={'start'} w="100%">
              <InputField
                name="name"
                value={albumProps.name}
                w="100%"
                maxLength={120}
                onChange={handleChange}
                placeholder={t('gallery.new_album.placeholder.add_title')}
                errorLabel={nameErrorMessage}
              />

              <TextField
                name="description"
                value={albumProps.description}
                w="100%"
                h="120px"
                maxH="120px"
                tooltipLabel={t('gallery.description_text_tooltip')}
                maxLength={500}
                onChange={handleChange}
                placeholder={t('gallery.new_album.placeholder.add_description')}
              />
            </VStack>
          </VStack>

          <HStack w="100%">
            <Spacer />
            <Button
              variant="underlineRed"
              onClick={handleDeleteClicked}
              px={6}
              display={dialog?.item?.id ? 'flex' : 'none'}>
              {t('gallery.new_album.delete.title')}
            </Button>
            <Button
              variant="h1cta"
              disabled={!albumProps.name}
              onClick={handleSaveClicked}
              px={14}>
              {t('gallery.dialogs.upload_photo.save')}
            </Button>
          </HStack>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
