import { Flex, FlexProps, IconButton, Spacer } from '@chakra-ui/react';
import AuthImage, { AuthImageProps } from 'components/authImage/AuthImage';
import {
  closeAlertDialog,
  openAlertDialog,
  setAddPhotoDialog,
  setAddVideoLinkModal,
  setViewPhotoDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { deleteMediaItem } from 'contexts/redux/mediaItem/mediaItemSlice';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import { TbPlayerPlayFilled } from 'react-icons/tb';

import { RiDeleteBinLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { MediaItem } from 'services/@types';

type MediaItemComponentProps = { item: MediaItem } & AuthImageProps & FlexProps;
export const MediaItemComponent: React.FC<MediaItemComponentProps> = ({
  item,
  ...rest
}) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const handleDeleteMediaItem = useCallback(
    (e) => {
      e.stopPropagation();
      console.debug('Delete media item -> ', item);
      dispatch(
        openAlertDialog({
          title: t('gallery.delete_this_photo'),
          content: t('gallery.delete_this_photo_confirmation'),
          confirmLabel: t('gallery.yes_delete'),
          cancelLabel: t('gallery.no_cancel'),
          onClose: () => {
            dispatch(closeAlertDialog());
          },
          onConfirm: () => {
            dispatch(closeAlertDialog());
            console.debug('Delete media item confirmed -> ', item);
            dispatch(deleteMediaItem({ mediaItemId: item.id }));
          },
        }),
      );
    },
    [dispatch, item, t],
  );

  const handleEditMediaItem = useCallback(
    (e) => {
      e.stopPropagation();
      console.debug('Edit media item -> ', item);
      if (item.url) {
        dispatch(
          setAddVideoLinkModal({
            item,
          }),
        );
        return;
      }

      dispatch(
        setAddPhotoDialog({
          item,
        }),
      );
    },
    [dispatch, item],
  );

  const handleMediaItemClicked = useCallback(() => {
    console.debug('Media item clicked -> ', item);
    dispatch(
      setViewPhotoDialog({
        item: item,
      }),
    );
  }, [dispatch, item]);

  return (
    <Flex
      borderRadius="2xl"
      overflow={'hidden'}
      w={'100%'}
      position={'relative'}
      h={'100%'}
      {...rest}>
      <AuthImage
        w="100%"
        size="md"
        imageProps={{ objectFit: 'cover', cursor: 'pointer' }}
        path={item.media && item.media.length > 0 ? item.media[0].url : ''}
      />

      {/* overlay buttons layout */}
      <Flex
        position={'absolute'}
        w={'100%'}
        cursor={'pointer'}
        h={'100%'}
        justify={'center'}
        p={2}
        onClick={handleMediaItemClicked}>
        <IconButton
          aria-label="left"
          variant="actionIconBlue"
          icon={<RiDeleteBinLine color="white" />}
          onClick={handleDeleteMediaItem}
          bg="#0000004D"
        />
        <Spacer />
        <IconButton
          aria-label="right"
          variant="actionIconBlue"
          bg="#0000004D"
          onClick={handleEditMediaItem}
          icon={<MdEdit color="white" />}
        />
        <IconButton
          aria-label="right"
          variant="actionIconBlue"
          fontSize={32}
          display={item.url && item.type === 'video' ? 'block' : 'none'}
          isRound
          bg="#00000066"
          size="lg"
          p={2}
          onClick={handleMediaItemClicked}
          position={'absolute'}
          alignSelf={'center'}
          as={TbPlayerPlayFilled}
          colorScheme="primary"
        />
      </Flex>
    </Flex>
  );
};
