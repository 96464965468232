import { Flex, Icon, IconButton, Text, Tooltip } from '@chakra-ui/react';
import Dropzone from 'components/dropzone';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Business, BusinessCategory, UrlItem } from 'services/@types';
import { AiOutlineInfoCircle } from 'react-icons/ai';
interface BusinessInfoProps {
  isEditModeEnabled: boolean;
  inputValues: Record<string, any>;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  myBusiness: Business;
  setInputValues: (value: any) => void;
  setLoading?: (value: boolean) => void;
}
export default function BusinessInfo({
  isEditModeEnabled,
  inputValues,
  handleChange,
  myBusiness,
  setInputValues,
  setLoading,
}: BusinessInfoProps) {
  const { t } = useTranslation();
  const { businessCategories } = useSelector(
    (state: RootState) => state.businessCategories,
  );
  const handleBusinessCategoriesSelected = useCallback(
    (selected: string) => {
      const tagNames = selected.split('.').pop();
      const selectedId = businessCategories?.find(
        (tag: BusinessCategory) => tag.name === tagNames,
      )?.id;
      if (inputValues?.category.includes(selectedId)) {
        setInputValues({
          ...inputValues,
          category: inputValues?.category?.filter((i: any) => i !== selectedId),
        });
        return;
      }
      setInputValues({
        ...inputValues,
        category: [...inputValues?.category, selectedId],
      });
    },
    [businessCategories, inputValues, setInputValues],
  );
  const selectedCategory = useMemo(() => {
    let res: string[] = [];
    businessCategories?.forEach((el) => {
      if (inputValues?.category.includes(el?.id)) {
        res.push(`categories.${el?.name}`);
      }
    });
    return res;
  }, [businessCategories, inputValues?.category]);

  const showedOptions = useMemo(
    () => selectedCategory.map((i) => t(i)).join(', '),
    [selectedCategory, t],
  );

  const categoryOptions = useMemo(
    () => businessCategories?.map((tag) => `categories.${tag.name}`),
    [businessCategories],
  );
  const selectedCategoryResult = useMemo(() => {
    return (
      selectedCategory
        .reduce((acc: string, val: string) => {
          return acc + t(val) + ', ';
        }, '')
        .slice(0, -2) || t('business_settings.not_added')
    );
  }, [selectedCategory, t]);
  const user = useMyUser();

  return (
    <Flex
      wrap="wrap"
      ps="20px"
      pe="52px"
      py={8}
      justifyContent="space-between"
      gap={6}>
      {/* 1 column */}
      <Flex flexDirection="column" flex="1" gap={4} minW={270}>
        {isEditModeEnabled && (
          <Flex flexDirection="column" gap={2}>
            <Text variant="cardSectionTitle">
              {t('business_settings.business_name')}
            </Text>
            <InputField
              flex="1"
              w={{ base: '100%', md: '310px' }}
              h="50px"
              name="businessName"
              value={inputValues?.businessName || ''}
              onChange={handleChange}
            />
          </Flex>
        )}
        <Flex flexDirection="column" gap={2}>
          <Text variant="cardSectionTitle">{t('business_settings.email')}</Text>
          {isEditModeEnabled ? (
            <InputField
              flex="1"
              w={{ base: '100%', md: '310px' }}
              h="50px"
              name="email"
              value={inputValues?.email || ''}
              onChange={handleChange}
            />
          ) : (
            <Text variant="cardSectionInfo">{myBusiness?.email}</Text>
          )}
        </Flex>
        <Flex flexDirection="column" gap={2}>
          <Text variant="cardSectionTitle">
            {t('business_settings.about_business')}
            {isEditModeEnabled && (
              <Tooltip
                variant="business"
                label={t('business_settings.about_business_tooltip')}>
                <IconButton
                  minW="30px"
                  aria-label=""
                  variant="unstyled"
                  color="#93C3B3"
                  icon={
                    <Icon
                      as={AiOutlineInfoCircle}
                      w="20px"
                      h="20px"
                      verticalAlign="sub"
                    />
                  }
                />
              </Tooltip>
            )}
          </Text>
          {isEditModeEnabled ? (
            <TextField
              flex="1"
              w={{ base: '100%', md: '310px' }}
              h="131px"
              resize={'none'}
              name="aboutMe"
              value={inputValues?.aboutMe || ''}
              onChange={handleChange}
              placeholder={t('business_settings.about_business_placeholder')}
            />
          ) : (
            <Text
              variant="cardSectionInfo"
              color={!myBusiness?.aboutMe && 'rgba(0, 0, 0, 0.3)'}>
              {myBusiness?.aboutMe || t('business_settings.not_added')}
            </Text>
          )}
        </Flex>
      </Flex>
      {/* 2 column */}
      <Flex flexDirection="column" flex="1" gap={4} minW={280}>
        <Flex flexDirection="column" gap={2} maxW={310}>
          <Flex>
            <Text variant="cardSectionTitle">
              {t('business_settings.logo')} &nbsp;
            </Text>
            <Text variant="cardSectionTitle" fontWeight="400">
              {t('business_settings.for_documents_and_business_profile_pic')}
            </Text>
          </Flex>
          <Flex gap={2}>
            <Dropzone
              disabled={!isEditModeEnabled}
              uploadKey="logo"
              target="user"
              uploadLabel={t(
                'signup.onboarding.business_info.add_logo_or_drag',
              )}
              labelSize="xs"
              targetId={user?.id}
              access="public"
              currentImage={
                inputValues?.media?.find((i: any) => i.type === 'logo')?.url ||
                myBusiness?.media?.find((i) => i.type === 'logo')?.url
              }
              dropzoneOptions={{
                accept: 'image/*',
                multiple: false,
              }}
              onUploadCompleted={(urlItem: UrlItem) => {
                handleChange({
                  target: {
                    name: 'media',
                    value: urlItem,
                  },
                } as any);
                setLoading(false);
              }}
              onUploadError={() => setLoading(false)}
              onStartUpload={() => {
                setLoading(true);
              }}
              h={70}
            />

            <Dropzone
              disabled={!isEditModeEnabled}
              imageSize="md"
              uploadKey="profileImage"
              target="user"
              labelSize="xs"
              targetId={user?.id}
              access="public"
              dropzoneOptions={{
                accept: 'image/*',
                multiple: false,
              }}
              uploadLabel={t('signup.onboarding.business_info.add_logo')}
              currentImage={
                inputValues?.media?.find((i: any) => i.type === 'profileImage')
                  ?.url ||
                myBusiness?.media?.find((i) => i.type === 'profileImage')?.url
              }
              onUploadCompleted={(urlItem: UrlItem) => {
                handleChange({
                  target: {
                    name: 'media',
                    value: urlItem,
                  },
                } as any);
                setLoading(false);
              }}
              onUploadError={() => setLoading(false)}
              onStartUpload={() => setLoading(true)}
              h={70}
              w={120}
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap={2}>
          <Text variant="cardSectionTitle">
            {t('business_settings.business_category')}
          </Text>
          {isEditModeEnabled ? (
            <DropdownField
              options={categoryOptions}
              placeholder={t(
                'signup.onboarding.business_info.select_categories',
              )}
              onSelected={handleBusinessCategoriesSelected}
              name="category"
              selectedOptions={selectedCategory}
              menuButton={{
                w: {
                  base: '100%',
                  md: '310px',
                },
                borderColor: '#00000099',
              }}
              maxItems={3}
              showedOptions={showedOptions}
            />
          ) : (
            <Text
              variant="cardSectionInfo"
              color={selectedCategory?.length === 0 && 'rgba(0, 0, 0, 0.3)'}>
              {selectedCategoryResult}
            </Text>
          )}
        </Flex>

        {isEditModeEnabled ? (
          // <TagsField
          //   id="description"
          //   mb="0px"
          //   h="90px"
          //   placeholderTags={[]}
          //   tags={inputValues?.companyServices || []}
          //   setTags={(e: any) => {
          //     handleChange({
          //       target: {
          //         name: 'companyServices',
          //         value: e,
          //       },
          //     } as any);
          //   }}
          //   w={{ base: '100%', md: '310px' }}
          // />
          <></>
        ) : (
          // <Flex gap={2} wrap="wrap">
          //   {/* service components */}
          //   {inputValues?.companyServices?.map(
          //     (service: string, index: number) => (
          //       <TagBadge key={index} label={service} />
          //     ),
          //   )}
          // </Flex>
          <Flex flexDirection="column" gap={2}>
            <Text variant="cardSectionTitle">
              {t('business_settings.company_services')}
            </Text>
            <Text variant="cardSectionInfo" color={'rgba(0, 0, 0, 0.3)'}>
              {t('business_settings.not_added')}
            </Text>
          </Flex>
        )}
      </Flex>
      {/* 3 column */}
      <Flex flexDirection="column" flex="1" gap={4} minW={280}>
        <Flex flexDirection="column" gap={2}>
          <Text variant="cardSectionTitle">
            {t('business_settings.capacity')}
            {isEditModeEnabled && (
              <Tooltip
                hasArrow
                arrowShadowColor="#00000008"
                variant="business"
                label={t('business_settings.capacity_tooltip')}>
                <IconButton
                  minW="30px"
                  aria-label=""
                  variant="unstyled"
                  color="#93C3B3"
                  icon={
                    <Icon
                      as={AiOutlineInfoCircle}
                      w="20px"
                      h="20px"
                      verticalAlign="sub"
                    />
                  }
                />
              </Tooltip>
            )}
          </Text>
          {isEditModeEnabled ? (
            <DropdownField
              flex="1"
              w={{ base: '100%', md: '310px' }}
              options={[1, 3, 5].map(
                (tag) =>
                  `${t('business_settings.events_per_day', { count: tag })}`,
              )}
              onSelected={(selected: any) => {
                handleChange({
                  target: {
                    name: 'capacity',
                    value: parseInt(selected?.split(' ')[0]),
                  },
                } as any);
              }}
              placeholder={
                t('business_settings.events_per_day', {
                  count: inputValues?.capacity || 1,
                }) || ''
              }
              selectedOptions={[`${inputValues?.capacity} event per day`]}
              menuButton={{
                w: { base: '100%', md: '310px' },
                borderColor: '#00000099',
              }}
              closeOnSelect
            />
          ) : (
            <Text variant="cardSectionInfo">
              {typeof inputValues?.capacity === 'number'
                ? t('business_settings.events_per_day', {
                    count: inputValues?.capacity,
                  })
                : inputValues?.capacity}
            </Text>
          )}
        </Flex>
        <Flex flexDirection="column" gap={2}>
          <Text variant="cardSectionTitle">
            {t('business_settings.business_phone')}
          </Text>
          {isEditModeEnabled ? (
            <InputField
              flex="1"
              w={{ base: '100%', md: '310px' }}
              h="50px"
              name="phone"
              value={inputValues?.phone || ''}
              onChange={handleChange}
            />
          ) : (
            <Text
              variant="cardSectionInfo"
              color={!inputValues?.phone && 'rgba(0, 0, 0, 0.3)'}>
              {inputValues?.phone || t('business_settings.not_added')}
            </Text>
          )}
        </Flex>
        <Flex flexDirection="column" gap={2}>
          <Text variant="cardSectionTitle">
            {t('business_settings.business_address')}
          </Text>
          {isEditModeEnabled ? (
            <InputField
              flex="1"
              w={{ base: '100%', md: '310px' }}
              h="50px"
              name="address"
              value={inputValues?.address || ''}
              onChange={handleChange}
            />
          ) : (
            <Text
              variant="cardSectionInfo"
              color={!myBusiness?.address && 'rgba(0, 0, 0, 0.3)'}>
              {myBusiness?.address || t('business_settings.not_added')}
            </Text>
          )}
        </Flex>
        <Flex flexDirection="column" gap={2}>
          <Text variant="cardSectionTitle">
            {t('business_settings.business_id')}
          </Text>
          {isEditModeEnabled ? (
            <InputField
              flex="1"
              w={{ base: '100%', md: '310px' }}
              h="50px"
              name="businessVATId"
              value={inputValues?.businessVATId || ''}
              onChange={handleChange}
            />
          ) : (
            <Text
              variant="cardSectionInfo"
              color={!myBusiness?.businessVATId && 'rgba(0, 0, 0, 0.3)'}>
              {myBusiness?.businessVATId || t('business_settings.not_added')}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
