import { FlexProps, IconButton } from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
interface EditBtnProps {
  onClick: () => void;
  containerProps?: FlexProps;
  iconProps?: any;
}
/**
 * default behavior of EditBtn component
 * parent must have role equal to group to make the hover effect work
 */
export default function EditBtn(props: EditBtnProps) {
  const { onClick } = props;
  return (
    <IconButton
      transition={{ all: '0.2s linear' }}
      _groupHover={{ opacity: 1 }}
      opacity={{ base: 0, sm: 1, md: 0 }}
      variant="actionIconBlue"
      icon={<MdEdit />}
      onClick={onClick}
      aria-label={'edit button'}
    />
  );
}
