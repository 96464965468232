import { Flex } from '@chakra-ui/react';
import { RootState } from 'contexts/redux/store';
import { useSelector } from 'react-redux';
import PasswordChanger from './passwordChanger/index';
import PersonalSettings from './personal/index';

export default function ProfileSettings() {
  const user = useSelector((state: RootState) => state.auth.user);
  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      pb="20px">
      <Flex direction="column" width="stretch">
        <Flex wrap="wrap" gap={5}>
          <PersonalSettings />
          {!user?.googleID && <PasswordChanger />}
        </Flex>
      </Flex>
    </Flex>
  );
}
