import { Flex, Stack, Text } from '@chakra-ui/react';
import InputDateField from 'components/fields/InputDateField';
import InputField from 'components/fields/InputField';
import LocationAutocomplete from 'components/locationAutocomplete';
import TimeSelector from 'components/timeSelector';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
interface CreateEventDetailsProps {
  handleChange: (key: string, value: any) => void;
  event: Record<string, any>;
  errorList: string[];
}
export default function CreateEventDetails(props: CreateEventDetailsProps) {
  const { handleChange, event, errorList } = props;
  const { t } = useTranslation();

  const dateAndTime = useMemo(() => event?.dateAndTime, [event?.dateAndTime]);
  const handleChangeDateAndTime = useCallback(
    (name: string, value: string) => {
      let newDateAndTime = dateAndTime;
      if (!newDateAndTime) {
        const newDate = new Date();
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
        newDateAndTime = newDate;
      }
      if (name === 'date') {
        newDateAndTime.setFullYear(new Date(value).getFullYear());
        newDateAndTime.setMonth(new Date(value).getMonth());
        newDateAndTime.setDate(new Date(value).getDate());
      }
      if (name === 'time') {
        newDateAndTime.setHours(Number(value.split(':')[0]));
        newDateAndTime.setMinutes(Number(value.split(':')[1]));
      }
      handleChange('dateAndTime', newDateAndTime);
    },
    [dateAndTime, handleChange],
  );
  return (
    <Stack gap={3}>
      <Text variant="eventSubTitle">{t('create_event.event_details')}</Text>
      <Flex wrap="wrap" gap="40px" alignItems="end">
        <LocationAutocomplete
          label={t('create_event.placeholder.location')}
          value={event?.location || ''}
          onChange={(e: any) => handleChange('location', e)}
          placeholder={t('create_event.placeholder.location')}
          controlStyles={{
            borderColor: errorList.includes('location') ? '#EE5D50' : '#E0E5F2',
            width: '100%',
          }}
          flexContainer={{ w: '100%' }}
          isIconHidden
        />
        <Flex gap={1} w={{ base: '100%', lg: '318px' }} alignItems="end">
          <InputDateField
            label={t('create_event.label.event_date')}
            value={dateAndTime}
            onChange={(e: Date) => {
              handleChangeDateAndTime('date', e.toString());
            }}
            justifyContent="end"
            w="100%"
            buttonStyles={{
              w: { base: '220px', md: 'fit-content' },
              maxW: { base: '220px', md: 'fit-content' },
            }}
            isGrey
          />
          <TimeSelector
            label={t('create_event.label.time')}
            value={dateAndTime?.toLocaleTimeString().slice(0, 5)}
            flex="1"
            onChange={(e: string) => {
              handleChangeDateAndTime('time', e);
            }}
          />
        </Flex>

        <InputField
          label={t('create_event.label.number_of_guests')}
          placeholder={t('create_event.placeholder.number_of_guests')}
          w={{ base: '100%', lg: '174px' }}
          flex="1"
          type="number"
          name="numberOfGuests"
          onChange={(e: any) => handleChange(e.target.name, e.target.value)}
          value={event?.numberOfGuests}
          zIndex="1"
          iconStyle={{ justifyContent: 'start' }}
        />
      </Flex>
    </Stack>
  );
}
