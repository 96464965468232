import { Flex, Text } from '@chakra-ui/layout';
import AuthImage from 'components/authImage/AuthImage';
import EditBtn from 'components/editBtn';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { useTranslation } from 'react-i18next';
interface BusinessHeaderProps {
  isEditModeEnabled: boolean;
  setIsEditModeEnabled: (value: boolean) => void;
  updateBusiness: () => void;
  loading: boolean;
}
export default function BusinessHeader(props: BusinessHeaderProps) {
  const { isEditModeEnabled, setIsEditModeEnabled, loading, updateBusiness } =
    props;
  const { t } = useTranslation();
  const myBusiness = useMyBusiness();
  return (
    <Flex p="32px 26px 0" justifyContent="space-between">
      <Flex w="100%">
        <AuthImage
          w="75px"
          h="75px"
          minW="75px"
          minH="75px"
          borderRadius="100%"
          path={myBusiness?.media?.find((i) => i.type === 'profileImage')?.url}
          border={
            myBusiness?.media?.find((i) => i.type === 'profileImage')?.url
              ? undefined
              : '0.5px dashed rgba(0, 0, 0, 0.6)'
          }
          isAvatar
          id={myBusiness?.id}
        />
        <Flex
          m="0 27px"
          flexDirection="column"
          justifyContent="center"
          flex="1"
          wordBreak="break-all">
          <Text variant="cardTitle" noOfLines={1}>
            {myBusiness?.businessName}
          </Text>
          <Text variant="cardSubTitle">
            {t('business_settings.account_information')}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems="start" justifyContent="end">
        {!isEditModeEnabled && (
          <EditBtn onClick={() => setIsEditModeEnabled(true)} />
        )}
      </Flex>
    </Flex>
  );
}
