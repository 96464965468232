import {
  withDefaultColorScheme,
  extendTheme,
  HTMLChakraProps,
  ThemingProps,
} from '@chakra-ui/react';
import { CardComponent } from './additions/card/card';
import { buttonStyles } from './components/button';
import { badgeStyles } from './components/badge';
import { inputStyles } from './components/input';
import { radioStyles } from './components/radio';
import { progressStyles } from './components/progress';
import { sliderStyles } from './components/slider';
import { textareaStyles } from './components/textarea';
import { switchStyles } from './components/switch';
import { linkStyles } from './components/link';
import { checkboxStyles } from './components/checkbox';
import { breakpoints } from './foundations/breakpoints';
import { Engfonts } from './foundations/fonts';
import { globalStyles } from './styles';
import { TextStyles } from './components/text';
import { AlertStyles } from './components/alert';
import { AvatarStyles } from './components/avatar';
import { iconButtonStyles } from './components/iconButton';
import { tooltipStyles } from './components/tooltip';
import { menuStyles } from './components/menu';
import { tabsStyles } from './components/tabs';
// import { globalStyles as globalStylesRed } from './stylesRed';
import '@fontsource-variable/rubik';

let theme = extendTheme(
  { breakpoints, fonts: Engfonts }, // Breakpoints | Fonts
  globalStyles,
  badgeStyles, // badge styles
  buttonStyles, // button styles
  iconButtonStyles, // icon button styles
  linkStyles, // link styles
  progressStyles, // progress styles
  radioStyles, // radio styles
  sliderStyles, // slider styles
  checkboxStyles, // checkbox styles
  inputStyles, // input styles
  textareaStyles, // textarea styles
  switchStyles, // switch styles
  CardComponent, // card component
  TextStyles, // text component
  AlertStyles, // alert styles for toaster
  AvatarStyles, // avatar styles
  tooltipStyles, // tooltip styles
  menuStyles, // menu styles
  tabsStyles, // table styles
  withDefaultColorScheme({
    colorScheme: 'brand.900',
    components: ['Checkbox'],
  }), // default color scheme
);

export default theme;

export interface CustomCardProps extends HTMLChakraProps<'div'>, ThemingProps {}
