declare global {
  interface Window {
    dataLayer: any[];
  }
}
const src: string = 'https://www.googletagmanager.com/gtag/js?id=G-VG0G6FQB6E';
const id: string = 'G-VG0G6FQB6E';

const sendGtags = () => {
  if (typeof window !== undefined) {
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer.push({
      event: 'js',
      date: new Date(),
    });
    window.dataLayer.push({
      event: 'config',
      id: id,
    });
  }
};

const loadGAScript = () => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.onload = sendGtags;
  document.body.appendChild(script);
};

export { loadGAScript };
