import { createSlice } from '@reduxjs/toolkit';

export interface DashboardState {
  selectedDate: string;
}

const initialState: DashboardState = {
  selectedDate:
    new Date().toDateString() +
    ' 00:00:00 GMT+0000 (Coordinated Universal Time)',
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
  },
});

export const { updateSelectedDate } = dashboardSlice.actions;

export default dashboardSlice.reducer;
