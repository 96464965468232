import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Business, BusinessCategory, PaginatedResults } from 'services/@types';
import BusinessCategoryService from 'services/category.api';
import { updateMyUser } from '../auth/authSlice';
import { closeSplashDialog } from '../dialog/dialogsSlice';
import store from '../store';
export interface BusinessState {
  businessCategories: BusinessCategory[];
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: BusinessState = {
  businessCategories: [],
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchBusinessCategories = createAsyncThunk(
  'businessCategories/fetchBusinessCategories',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await BusinessCategoryService.getBusinessCategories(
        params,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchBusinessCategory = createAsyncThunk(
  'businessCategories/fetchBusinessCategory',
  async (tagId: string, { rejectWithValue }) => {
    try {
      const response = await BusinessCategoryService.getBusinessCategory(tagId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createBusinessCategory = createAsyncThunk(
  'businessCategories/createBusinessCategory',
  async (businessTag: BusinessCategory, { rejectWithValue }) => {
    try {
      const newBusiness = await BusinessCategoryService.createBusinessCategory(
        businessTag,
      );
      console.log('#Redux create business ->', newBusiness);
      await store.dispatch(
        updateMyUser({ updates: { businessID: newBusiness.id } }),
      );
      store.dispatch(closeSplashDialog());

      return newBusiness;
    } catch (error) {
      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);

export const updateBusinessCategory = createAsyncThunk(
  'businessCategories/updateBusinessCategory',
  async (
    { userId, updates }: { userId: string; updates: Partial<Business> },
    { rejectWithValue },
  ) => {
    try {
      const response = await BusinessCategoryService.updateBusinessCategory(
        userId,
        updates,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteBusinessCategory = createAsyncThunk(
  'businessCategories/deleteBusinessCategory',
  async (tagid: string, { rejectWithValue }) => {
    try {
      await BusinessCategoryService.deleteBusinessCategory(tagid);
      return tagid;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
  },
  extraReducers: (builder) => {
    builder
      // Fetch all businesses
      .addCase(fetchBusinessCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchBusinessCategories.fulfilled,
        (state, action: PayloadAction<PaginatedResults<BusinessCategory>>) => {
          state.businessCategories = action.payload.results;
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      .addCase(
        fetchBusinessCategories.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      // Create a tag
      .addCase(createBusinessCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createBusinessCategory.fulfilled,
        (state, action: PayloadAction<BusinessCategory>) => {
          state.businessCategories.push(action.payload);
          state.loading = false;
        },
      )
      .addCase(
        createBusinessCategory.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      // Update a user
      .addCase(updateBusinessCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateBusinessCategory.fulfilled,
        (state, action: PayloadAction<BusinessCategory>) => {
          const index = state.businessCategories.findIndex(
            (tag) => tag.id === action.payload.id,
          );
          if (index !== -1) {
            state.businessCategories[index] = action.payload;
          }
          state.loading = false;
        },
      )
      .addCase(
        updateBusinessCategory.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      // Delete a user
      .addCase(deleteBusinessCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteBusinessCategory.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.businessCategories = state.businessCategories.filter(
            (tag) => tag.id !== action.payload,
          );
          state.loading = false;
        },
      )
      .addCase(
        deleteBusinessCategory.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      );
  },
});

export default userSlice.reducer;
