// Chakra imports
import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Show,
  Text,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import packageIcon from 'assets/svg/products/package-icon.svg';
import { openAlertDialog } from 'contexts/redux/dialog/dialogsSlice';
import { deleteProduct } from 'contexts/redux/product/productSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FiMoreHorizontal } from 'react-icons/fi';
import { MdEdit } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from 'services/@types';

export default function ProductHeader(props: {
  product: Product;
  onSendInvite?: () => void;
  [x: string]: any;
}) {
  const { product, onSendInvite, onClick, ...rest } = props;
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isMd] = useMediaQuery('(min-width: 768px)');
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const { name } = product;
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const { productCategories } = useSelector(
    (state: RootState) => state.product,
  );

  const productCategoryLabel = useMemo(() => {
    return productCategories.find((p) => p.id === product.category)?.name;
  }, [productCategories, product.category]);

  const handleDeleteProduct = useCallback(() => {
    dispatch(
      openAlertDialog({
        title: t('products.dialog.delete.title'),
        content: t('products.dialog.delete.description', {
          name: product.name,
        }),
        onConfirm: () => dispatch(deleteProduct(product.id)),
      }),
    );
  }, [dispatch, product?.id, product?.name, t]);

  return (
    <Flex
      w="100%"
      py={4}
      px={6}
      gap={6}
      flex={1}
      _hover={{ bg: 'gray.100' }}
      cursor="pointer"
      position="relative"
      onClick={() => {
        if (isMd) {
          onClick();
        }
      }}
      {...rest}
      align="start">
      <Flex
        direction="column"
        flex={1}
        gap={2}
        opacity={{ base: isOpen ? 0.5 : 1, md: 1 }}>
        <VStack align="start" spacing={0} flex={1} wordBreak={'break-all'}>
          <HStack spacing={0} gap={1} align="center">
            <Image
              src={packageIcon}
              w={6}
              h={6}
              display={product?.subItems?.length > 0 ? 'flex' : 'none'}
            />
            <Text
              color={textColor}
              fontSize={{ base: 'xl', sm: 'md', lg: 'lg' }}
              noOfLines={1}
              fontWeight="700">
              {name === 'your_first_product'
                ? t('products.your_first_product')
                : name}
            </Text>
            <Text
              color="brand.900"
              noOfLines={1}
              fontWeight="700"
              flex={1}
              fontSize={{ base: 'sm', sm: 'xs', lg: 'lg' }}
              wordBreak={'break-word'}
              // display={category?.length > 0 ? 'flex' : 'none'}
            >
              {productCategoryLabel === 'general_category'
                ? t('products.general_category')
                : productCategoryLabel}
            </Text>
          </HStack>
        </VStack>
        <Text color="gray.400" fontSize={'sm'} fontWeight={500}>
          {product?.price ? `$${product?.price}` : t('products.add_price')}
        </Text>
      </Flex>
      <Button
        onClick={onSendInvite}
        size="xs"
        variant={true ? 'outlineYellowSmall' : 'outlineBrandSmall'}
        display={product.businessID ? 'none' : 'flex'}>
        Send
      </Button>
      <Show below="md">
        <HStack
          align="center"
          justify="center"
          alignSelf="center"
          onBlur={onClose}>
          <Menu
            placement="right"
            variant="threeDots"
            isOpen={isOpen}
            closeOnBlur>
            <MenuButton
              as={IconButton}
              variant="actionIconTransparent"
              icon={<Icon as={FiMoreHorizontal} w="20px" h="20px" />}
              onClick={onToggle}
            />
            <MenuList>
              <Button
                as={IconButton}
                variant="actionIconRed"
                icon={<Icon as={FaRegTrashAlt} w="20px" h="20px" />}
                onClick={() => handleDeleteProduct()}
              />
              <Button
                as={IconButton}
                aria-label="edit"
                variant="actionIconGrey"
                icon={<Icon as={MdEdit} w="20px" h="20px" />}
                onClick={() => onClick()}
              />
            </MenuList>
          </Menu>
        </HStack>
      </Show>
    </Flex>
  );
}
