import { RootState } from 'contexts/redux/store';
import { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function QuoteReqest() {
  const [searchParams] = useSearchParams();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  useLayoutEffect(() => {
    localStorage.setItem('requestQuoteToken', searchParams.get('token'));
  }, [searchParams]);
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth/get-started');
    }
  }, [isAuthenticated, navigate]);

  return <></>;
}
