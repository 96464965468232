// src/features/calendars/userSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Calendar } from 'services/@types';
import CalendarService from 'services/calendar.api';
import {
  closeAddCalendarDialog,
  closeSplashDialog,
  openAlertDialog,
} from '../dialog/dialogsSlice';
import store from '../store';
export interface CalendarsState {
  currentCalendar: Calendar | null;
  calendars: Calendar[];
  loading: boolean;
  loadingId: string | null;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: CalendarsState = {
  currentCalendar: null,
  calendars: [],
  loadingId: null,
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchCalendars = createAsyncThunk(
  'calendars/fetchCalendars',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await CalendarService.getCalendars(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchCalendar = createAsyncThunk(
  'calendars/fetchCalendar',
  async (tagId: string, { rejectWithValue }) => {
    try {
      const response = await CalendarService.getCalendar(tagId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createCalendar = createAsyncThunk(
  'calendars/createCalendar',
  async (calendar: Partial<Calendar>, { rejectWithValue }) => {
    try {
      const newCalendar = await CalendarService.createCalendar({
        ...calendar,
        type: 'internal',
      });
      console.log('#Redux create calendar ->', newCalendar);
      store.dispatch(closeSplashDialog());
      store.dispatch(closeAddCalendarDialog());
      return newCalendar;
    } catch (error) {
      const e = error as AxiosError;
      if (e.response.status === 409) {
        store.dispatch(
          openAlertDialog({
            title: 'invite_calendar.already_invited',
            content: 'invite_calendar.already_invited_description',
          }),
        );
      }

      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);

export const updateCalendar = createAsyncThunk(
  'calendars/updateCalendar',
  async (
    { userId, updates }: { userId: string; updates: Partial<Calendar> },
    { rejectWithValue },
  ) => {
    try {
      const response = await CalendarService.updateCalendar(userId, updates);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteCalendar = createAsyncThunk(
  'calendars/deleteCalendar',
  async (
    {
      id,
    }: {
      id: string;
    },
    { rejectWithValue },
  ) => {
    try {
      await CalendarService.deleteCalendar(id);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
    setCurrentCalendar: (state, action: PayloadAction<Calendar | null>) => {
      state.currentCalendar = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all businesses
      .addCase(fetchCalendars.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchCalendars.fulfilled,
        (state, action: PayloadAction<Calendar[]>) => {
          state.calendars = [...action.payload];
          state.loading = false;
        },
      )
      .addCase(fetchCalendars.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create a tag
      .addCase(createCalendar.pending, (state, { meta }) => {
        state.loading = true;
        state.loadingId = meta.arg.email;
      })
      .addCase(
        createCalendar.fulfilled,
        (state, action: PayloadAction<Calendar>) => {
          state.calendars.push(action.payload);
          state.loading = false;
          state.loadingId = null;
        },
      )
      .addCase(createCalendar.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.loadingId = null;
        state.error = action.payload;
      })
      // Update a user
      .addCase(updateCalendar.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateCalendar.fulfilled,
        (state, action: PayloadAction<Calendar>) => {
          const index = state.calendars.findIndex(
            (tag) => tag.id === action.payload.id,
          );
          if (index !== -1) {
            state.calendars[index] = action.payload;
          }
          state.loading = false;
        },
      )
      .addCase(updateCalendar.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete a user
      .addCase(deleteCalendar.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteCalendar.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.calendars = state.calendars.filter(
            (tag) => tag.id !== action.payload,
          );
          state.loading = false;
        },
      )
      .addCase(deleteCalendar.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;

export const { setCurrentCalendar } = userSlice.actions;
