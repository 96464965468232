import { AxiosInstance, AxiosResponse } from 'axios';
import { Album, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class AlbumService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createAlbum(business: Album): Promise<Album> {
    const response: AxiosResponse<Album> = await this.api.post(
      '/albums',
      business,
    );
    return response.data;
  }

  public async getAlbums(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<Album>> {
    const response: AxiosResponse<PaginatedResults<Album>> = await this.api.get(
      '/albums',
      {
        params: queryParams,
      },
    );
    return response.data;
  }

  public async getAlbum(businessId: string): Promise<Album> {
    const response: AxiosResponse<Album> = await this.api.get(
      `/albums/${businessId}`,
    );
    return response.data;
  }

  public async updateAlbum(
    albumId: string,
    updates: Partial<Album>,
  ): Promise<Album> {
    const response: AxiosResponse<Album> = await this.api.patch(
      `/albums/${albumId}`,
      updates,
    );
    return response.data;
  }

  public async deleteAlbum(businessId: string): Promise<void> {
    await this.api.delete(`/albums/${businessId}`);
  }
}

const _albumService = new AlbumService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _albumService;
