import { Flex, Text, VStack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { OnboardFlow, OnboardHeaders, SignUpStep } from '../SignUpCentered';
interface IndicatorProps {
  index: number;
  activeTab: SignUpStep;
  updateMyUser: any;
}
const SignUpFlowToActiveIndex = {
  signup: 0,
  business: 2,
  personalization: 3,
  pricing: 3,
  plans: 4,
  payment: 4,
  completed: 5,
};
export default function Indicator(props: IndicatorProps) {
  const { index, activeTab, updateMyUser } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();

  const getActiveIndex = useCallback(() => {
    return SignUpFlowToActiveIndex[activeTab];
  }, [activeTab]);

  const getColor = useCallback(() => {
    const activeIndex = getActiveIndex();
    if (activeIndex >= index) {
      return 'brand.300';
    }
    return 'white';
  }, [getActiveIndex, index]);

  const getCircleBg = useCallback(() => {
    const activeIndex = getActiveIndex();
    if (activeIndex > index) {
      return 'brand.300';
    }
    return 'transparent';
  }, [getActiveIndex, index]);

  const getLineBg = useCallback(() => {
    const activeIndex = getActiveIndex();
    if (activeIndex > index) {
      return 'brand.300';
    }
    return 'white';
  }, [getActiveIndex, index]);

  return (
    <Flex
      align="center"
      justify={'center'}
      key={index}
      mb={4}
      overflow="visible"
      pt={5}
      position="relative"
      flex={index % 2 === 0 ? 0 : 1}
      flexDirection="column">
      {index % 2 === 0 && (
        <VStack overflow="visible" pt={2}>
          <Text
            top={0}
            w={40}
            textAlign="center"
            overflow="visible"
            position={'absolute'}
            zIndex={4}
            onClick={() => {
              if (process.env.NODE_ENV !== 'development') return;
              dispatch(
                updateMyUser({
                  updates: { onboardingStep: OnboardFlow[index / 2] },
                }),
              );
            }}
            textTransform="capitalize"
            fontSize={{
              base: 'sm',
            }}
            color={getColor()}>
            {t(`signup.onboarding.headers.${OnboardHeaders[index / 2]}`)}
          </Text>
          <Flex
            key={index}
            w="14px"
            h="14px"
            bg={getCircleBg()}
            borderWidth={1}
            borderColor={getColor()}
            borderRadius="50%"
          />
        </VStack>
      )}
      {index % 2 !== 0 && <Flex h={'1px'} mt={4} bg={getLineBg()} w="100%" />}
    </Flex>
  );
}
