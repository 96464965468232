// Chakra imports
// Assets
import { Flex } from '@chakra-ui/react';
import Splash from 'components/splash';
import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import useGoogleSignin from 'views/auth/signUp/hooks/useGoogleSignin';

function Token() {
    const navigate = useNavigate();
    useGoogleSignin();
    const location = useLocation();
    // Chakra color mode

    useEffect(() => {
        if (window.location.toString().includes('access_token')) {
            return;
        } else {
            navigate('/main/dashboard/overview');
        }
    }, [navigate, location]);
    return (
        <Flex w={'100dvw'} h={'100dvh'}>
            <Splash forceShow />
        </Flex>
    );
}

export default Token;
