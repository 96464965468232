import { AxiosInstance, AxiosResponse } from 'axios';
import { Supplier, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class SupplierService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createSupplier(
    supplier: Partial<Supplier>,
    invite?: boolean,
  ): Promise<Supplier> {
    const response: AxiosResponse<Supplier> = await this.api.post(
      `/suppliers?invite=${invite}`,
      supplier,
    );
    return response.data;
  }

  public async getSuppliers(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<Supplier>> {
    const response: AxiosResponse<PaginatedResults<Supplier>> =
      await this.api.get('/suppliers', {
        params: queryParams,
      });
    return response.data;
  }

  public async getSupplier(supplierId: string): Promise<Supplier> {
    const response: AxiosResponse<Supplier> = await this.api.get(
      `/suppliers/${supplierId}`,
    );
    return response.data;
  }

  public async updateSupplier(
    supplierId: string,
    updates: Partial<Supplier>,
  ): Promise<Supplier> {
    const response: AxiosResponse<Supplier> = await this.api.patch(
      `/suppliers/${supplierId}`,
      updates,
    );
    return response.data;
  }

  public async deleteSupplier(supplierId: string): Promise<void> {
    await this.api.delete(`/suppliers/${supplierId}`);
  }
}

const _supplierService = new SupplierService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _supplierService;
