import {
  Button,
  Flex,
  Hide,
  HStack,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Show,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import InputAutocompleteField from 'components/fields/InputAutocompleteField';
// Custom components
import InputField from 'components/fields/InputField';
import { AppTooltip } from 'components/tooltip';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClear } from 'react-icons/md';
import { Product } from 'services/@types';
import { useCreatePackage } from '../hooks/useCreatePackage';
import { AddNewProductSection } from './AddNewProductSection';

const tagsVariants = {
  pop: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      // stiffness: 260,
      // damping: 20,
      duration: 0.2,
    },
  },
};
export interface AddPackageModalProps {
  isOpen: boolean;
  onClose: () => void;
  editablePackage?: Partial<Product>;
}

export const AddPackageModal: React.FC<AddPackageModalProps> = ({
  isOpen,
  onClose,
  editablePackage,
}) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const { t } = useTranslation();

  const {
    newProductInputValue,
    selectedProducts,
    newProducts,
    showNewProductSection,
    productProps,
    displayingOptions,
    errorLabels,
    loading,
    setNewProductInputValue,
    handleOnSaveNewProduct,
    setShowNewProductSection,
    setProductProps,
    handleRemoveProduct,
    handleOnSelected,
    resetItems,
    handleSave,
    setErrorLabels,
  } = useCreatePackage(editablePackage, onClose, isOpen);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorLabels({ name: '', price: '', subItems: '' });
      setProductProps({ ...productProps, [e.target.id]: e.target.value });
    },
    [productProps, setErrorLabels, setProductProps],
  );

  const handleClose = useCallback(() => {
    onClose();
    setErrorLabels({ name: '', price: '', subItems: '' });
  }, [onClose, setErrorLabels]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      onCloseComplete={() => {
        resetItems();
      }}>
      <ModalOverlay />
      <ModalContent borderRadius={'3xl'} mx={2} px={0} pt={'30px'} maxW={720}>
        <ModalHeader p={0}>
          <HStack gap={2} align={{ sm: 'start', md: 'center' }} w="100%" px={5}>
            <VStack align="start" flex={1}>
              <Text
                color={textColor}
                fontSize={{ sm: 'xl', md: '2xl' }}
                fontWeight="700">
                {t('products.add_package_modal.title')}
              </Text>
            </VStack>
            <Hide below="md">
              <Button
                variant="h1cta"
                ms="auto"
                px={{ base: 4, md: 16 }}
                onClick={handleSave}
                isLoading={loading}>
                {t('products.add_package_modal.save')}
              </Button>
            </Hide>
            <ModalCloseButton right={0} position={'relative'} top={0} />
          </HStack>
        </ModalHeader>
        <Flex
          direction="column"
          align="center"
          position="relative"
          py={6}
          px={0}
          gap={4}>
          <Flex p={0} w="100%" px={5}>
            <Flex
              direction="row"
              w="100%"
              wrap="wrap"
              gap={{ base: 4, md: 16 }}
              justify={'space-between'}>
              <VStack gap={4} flex={1} align="start" minWidth={280}>
                <InputField
                  id="name"
                  w="100%"
                  value={productProps?.name}
                  placeholder={t('products.add_package_modal.name_placeholder')}
                  onChange={handleChange}
                  label={t('products.add_package_modal.name')}
                  errorLabel={errorLabels.name}
                />
              </VStack>
              <VStack gap={6} align="start" flex={1} minWidth={280}>
                {/* business id, categories, tag services, internal notes */}

                <InputField
                  id="price"
                  value={productProps?.price}
                  w="100%"
                  onChange={handleChange}
                  switchProps={{
                    label: t('products.add_package_modal.show_price'),
                    labelColor: 'brand.900',
                    id: 'show_price',
                    size: 'sm',
                    isChecked: false,
                    onChange: () => console.log('BLA'),
                  }}
                  errorLabel={errorLabels.price}
                  placeholder={t(
                    'products.add_package_modal.price_placeholder',
                  )}
                  label={t('products.add_package_modal.price')}
                />
              </VStack>
            </Flex>
          </Flex>
          <VStack align="start" w="100%" px={0}>
            <Text fontWeight={'bold'} color="brand.900" px={5}>
              {t('products.add_package_modal.package_content')}
            </Text>
            <HStack
              px={5}
              h="100%"
              gap={{ base: 4, md: 10 }}
              w="100%"
              // minW={352}
              align="end"
              position={'relative'}>
              <InputAutocompleteField
                flex={1}
                label={t('products.add_package_modal.add_item')}
                w="100%"
                maxW={320}
                errorLabel={errorLabels.subItems}
                id="name"
                value={newProductInputValue}
                onChange={(e) => setNewProductInputValue(e.target.value)}
                options={displayingOptions}
                onSelected={handleOnSelected}
                selectedOptions={selectedProducts}
              />
              <HStack
                gap={1}
                minW={352}
                maxW={360}
                transition={'opacity 0.3s'}
                opacity={displayingOptions.length === 0 ? 1 : 0}
                display={
                  !showNewProductSection && newProductInputValue.length > 0
                    ? 'flex'
                    : 'none'
                }
                h={50}
                bottom={0}>
                <Text>
                  {t('products.add_package_modal.product_does_not_exist')}{' '}
                </Text>
                <Text
                  fontWeight={500}
                  color="brand.900"
                  cursor={'pointer'}
                  onClick={() => {
                    setShowNewProductSection(true);
                  }}
                  textDecoration={'underline'}>
                  {t('products.add_package_modal.add_new_product')}
                </Text>
              </HStack>
            </HStack>
            <AddNewProductSection
              showNewProductSection={showNewProductSection}
              onClose={() => {
                setShowNewProductSection(false);
              }}
              onSave={handleOnSaveNewProduct}
            />
            <Flex wrap="wrap" gap={2} px={6}>
              <AnimatePresence>
                {[...newProducts, ...selectedProducts].map((prod) => (
                  <Button
                    as={motion.div}
                    animate={'pop'}
                    variants={tagsVariants}
                    initial={{ scale: 0.6, opacity: 0 }}
                    exit={{ scale: 0.6, opacity: 0 }}
                    transition="0.1s"
                    key={prod.value}
                    rightIcon={
                      <Icon
                        fontSize={'18px'}
                        cursor={'pointer'}
                        as={MdClear}
                        onClick={() => handleRemoveProduct(prod)}
                      />
                    }
                    variant="badgeTagBlue">
                    {prod.label}
                  </Button>
                ))}
              </AnimatePresence>
            </Flex>
          </VStack>
          <HStack w="100%" align="center" px={5}>
            <Text
              flexDirection={'row'}
              alignSelf={'center'}
              fontSize={{ sm: 'sm', md: 'md' }}>
              {t('products.add_product_modal.to_add_more')}{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#AA915D',
                }}
                onClick={() => handleSave()}>
                {t('products.add_package_modal.advanced_settings')}
              </span>
            </Text>
            <AppTooltip label="products.add_product_modal.to_add_more_tooltip" />
          </HStack>
          <Show below="md">
            <Button
              width="100%"
              maxW={320}
              variant="h1cta"
              px={{ base: 4, md: 16 }}
              onClick={handleSave}
              isLoading={loading}>
              {t('products.add_package_modal.save')}
            </Button>
          </Show>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
