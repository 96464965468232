import { AxiosInstance, AxiosResponse } from 'axios';
import { BusinessTag, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class BusinessTagService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createBusinessTag(business: BusinessTag): Promise<BusinessTag> {
    const response: AxiosResponse<BusinessTag> = await this.api.post(
      '/business-tags',
      business,
    );
    return response.data;
  }

  public async getBusinessTags(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<BusinessTag>> {
    const response: AxiosResponse<PaginatedResults<BusinessTag>> =
      await this.api.get('/business-tags', {
        params: queryParams,
      });
    return response.data;
  }

  public async getBusinessTag(businessId: string): Promise<BusinessTag> {
    const response: AxiosResponse<BusinessTag> = await this.api.get(
      `/business-tags/${businessId}`,
    );
    return response.data;
  }

  public async updateBusinessTag(
    businessId: string,
    updates: Partial<BusinessTag>,
  ): Promise<BusinessTag> {
    const response: AxiosResponse<BusinessTag> = await this.api.patch(
      `/business-tags/${businessId}`,
      updates,
    );
    return response.data;
  }

  public async deleteBusinessTag(businessId: string): Promise<void> {
    await this.api.delete(`/business-tags/${businessId}`);
  }
}

const _businessService = new BusinessTagService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _businessService;
