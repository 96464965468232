export enum GAEventAction {
  SupplierInviteSend = 'supplier_invite_send',
  SupplierInviteOpen = 'supplier_invite_open',
  SupplierInviteRegistered = 'supplier_invite_registered',
  SupplierInvitePaused = 'supplier_invite_paused',
}
export enum GAEventCategory {
  SupplierInvite = 'supplier_invite',
  SupplierInviteError = 'supplier_invite_error',
  SupplierInviteSuccess = 'supplier_invite_success',
}

export enum GAEventLabel {
  SupplierInviteSend = 'supplier_invite_send',
  SupplierInviteOpen = 'supplier_invite_open',
  SupplierInviteRegistered = 'supplier_invite_registered',
  SupplierInvitePaused = 'supplier_invite_paused',
}
