import { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import routes from 'routes';
import { fetchUser, setCredentials } from '../../contexts/redux/auth/authSlice';
import { RootState } from '../../contexts/redux/store';
// Chakra imports
import { Box, useColorModeValue } from '@chakra-ui/react';

// Layout components
import { SidebarContext } from 'contexts/SidebarContext';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SignUpStep } from 'views/auth/signUp/SignUpCentered';
import NoMatch from 'views/admin/main/others/404';
import { fetchBusinessTags } from 'contexts/redux/businessTags/businessTagsSlice';

import { UserWithTokens } from 'services/@types';
import { motion, AnimatePresence } from 'framer-motion';
import { fetchBusinessCategories } from 'contexts/redux/businessCategories/businessCategoriesSlice';
const DEFAULT_ROUTE = '/main/dashboard/overview';
// Custom Chakra theme
export default function Auth() {
    // states and functions
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    //   const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch<any>();
    const { user, isAuthenticated } = useSelector(
        (state: RootState) => state.auth,
    );
    useEffect(() => {
        if (user?.onboardingStep === SignUpStep.completed) {
            navigate(DEFAULT_ROUTE);
        } else if (user) {
            navigate('/auth/sign-up');
        }
    }, [user, navigate]);

    useEffect(() => {
        const _userWithTokens = localStorage.getItem('token');
        if (_userWithTokens) {
            const userWithTokens = JSON.parse(_userWithTokens) as UserWithTokens;
            if (userWithTokens.user?.id) {
                dispatch(fetchUser(userWithTokens.user.id));
            }
            if (userWithTokens.tokens) {
                dispatch(setCredentials({ tokens: userWithTokens.tokens, user: null }));
                dispatch(fetchBusinessTags({}));
                dispatch(fetchBusinessCategories({}));
            }
        }
    }, [dispatch, isAuthenticated]);

    const getRoute = () => {
        return window.location.pathname !== '/auth/full-screen-maps';
    };
    const getRoutes = (routes: RoutesType[]): any => {
        return routes.map((route, key) => {
            if (route.layout === '/auth') {
                const animatedComponent = (
                    <AnimatePresence>
                        <motion.div
                            key={key}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            {route.component}
                        </motion.div>
                    </AnimatePresence>
                );

                return (
                    <Route path={`${route.path}`} element={animatedComponent} key={key} />
                );
            }
            if (route.collapse) {
                return getRoutes(route.items);
            }
            return null;
        });
    };
    const authBg = useColorModeValue('#eee', 'brand.300');
    document.documentElement.dir = 'ltr';
    return (
        <Box sx={{ direction: i18n.dir() }}>
            <SidebarContext.Provider
                value={{
                    toggleSidebar,
                    setToggleSidebar,
                }}
            >
                <Box
                    bg={authBg}
                    float="right"
                    minHeight="100vh"
                    height="100%"
                    position="relative"
                    w="100%"
                    transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                    transitionDuration=".2s, .2s, .35s"
                    transitionProperty="top, bottom, width"
                    transitionTimingFunction="linear, linear, ease"
                >
                    <Box mx="auto" minH="100dvh">
                        <Routes>
                            {getRoute() && (
                                <>
                                    {getRoutes(routes)}
                                    <Route
                                        path="/"
                                        element={<Navigate to="/auth/sign-in" replace />}
                                    />
                                </>
                            )}
                            <Route path="*" element={<NoMatch />} />
                        </Routes>
                    </Box>
                </Box>
            </SidebarContext.Provider>
        </Box>
    );
}
