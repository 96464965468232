import { AxiosInstance, AxiosResponse } from 'axios';
import { MediaItem, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class MediaItemService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createMediaItem(business: MediaItem): Promise<MediaItem> {
    const response: AxiosResponse<MediaItem> = await this.api.post(
      '/media-items',
      business,
    );
    return response.data;
  }

  public async getMediaItems(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<MediaItem>> {
    const response: AxiosResponse<PaginatedResults<MediaItem>> =
      await this.api.get('/media-items', {
        params: queryParams,
      });
    return response.data;
  }

  public async getMediaItem(businessId: string): Promise<MediaItem> {
    const response: AxiosResponse<MediaItem> = await this.api.get(
      `/media-items/${businessId}`,
    );
    return response.data;
  }

  public async updateMediaItem(
    businessId: string,
    updates: Partial<MediaItem>,
  ): Promise<MediaItem> {
    const response: AxiosResponse<MediaItem> = await this.api.patch(
      `/media-items/${businessId}`,
      updates,
    );
    return response.data;
  }

  public async deleteMediaItem(businessId: string): Promise<void> {
    await this.api.delete(`/media-items/${businessId}`);
  }
}

const _mediaItemService = new MediaItemService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _mediaItemService;
