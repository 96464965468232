import { useState, useEffect } from 'react';

const useQueryParam = (param: string) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setValue(urlParams.get(param) || '');
  }, [param]);

  return value;
};

export default useQueryParam;
