import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthServices from 'services/auth.api';

export default function useChangePassword() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [inputValues, setInputValues] = useState<Record<string, string>>({});
    const toast = useToast();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputValues((prevState) => ({ ...prevState, [name]: value }));
    };

    const changePassword = useCallback(async () => {
        try {
            setLoading(true);
            if (inputValues.newPassword !== inputValues.newPasswordConfirmation) {
                toast({
                    title: t('toast.title.rejected'),
                    description: t('toast.description.password_mismatch'),
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
                setLoading(false);
                return;
            }
            try {
                const response = await AuthServices.changePassword({
                    oldPassword: inputValues.oldPassword,
                    newPassword: inputValues.newPassword,
                });
                if (response.status === 204) {
                    toast({
                        title: t('toast.title.success'),
                        description: t('toast.description.password_changed'),
                        status: 'success',
                        duration: 2000,
                        variant: 'main',
                    });
                }
            } catch (e: any) {
                toast({
                    title: e.response.data.status,
                    description: e.response.data.message,
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            }
        } catch (e) {
            const error = e as AxiosError;
            setError(error.message);
        }
        setLoading(false);
    }, [inputValues.newPassword, inputValues.newPasswordConfirmation, inputValues.oldPassword, toast, t]);

    return {
        inputValues,
        handleChange,
        changePassword,
        loading,
        error,
        setLoading,
    };
}
