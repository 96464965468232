import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { fetchCalendars } from 'contexts/redux/calendar/calendarsSlice';
import { updateViewedCalendars } from 'contexts/redux/calendarEvent/calendarEventSlice';
import { updateSelectedDate } from 'contexts/redux/dashboard/dashboardSlice';
import {
  setAddCalendarDialog,
  setCalendarsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import {
  updateUserPreferences,
  useMyUserPreferences,
} from 'contexts/redux/userPreferences/userPreferencesSlice';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useTranslation } from 'react-i18next';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { BsCircleFill } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Card from '../../../../components/card/Card';
import DropdownField from '../../../../components/fields/DropdownField';
import './custom.css';

require('moment/locale/he');

export const CalendarMedium = () => {
  const [calendarViewType, setCalendarViewType] = useState<'month' | 'week'>(
    'month',
  );
  const { t, i18n } = useTranslation();
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const myUser = useMyUser();
  const [currentDate, setCurrentDate] = useState(new Date());
  const { calendars } = useSelector((state: RootState) => state.calendars);
  const { activeCalendars } = useMyUserPreferences();
  const { calendarEvents } = useSelector(
    (state: RootState) => state.calendarEvent,
  );

  const [selectedDate, setSelectedDate] = useState(new Date());

  const displayedEvents = useMemo(() => {
    return calendarEvents.map((event) => ({
      ...event,
      title: event?.summary,
    }));
  }, [calendarEvents]);

  const handleNextStep = useCallback(() => {
    console.log('next step');
    if (calendarViewType === 'week') {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
    } else {
      setCurrentDate(
        new Date(currentDate.setMonth(currentDate.getMonth() + 1)),
      );
    }
  }, [calendarViewType, currentDate]);

  const handlePrevStep = useCallback(() => {
    console.log('prev step');
    if (calendarViewType === 'week') {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
    } else {
      setCurrentDate(
        new Date(currentDate.setMonth(currentDate.getMonth() - 1)),
      );
    }
  }, [calendarViewType, currentDate]);

  const getDayProp = useCallback(
    (date) => {
      // console.log('getDayProp => ', date.getTime(), selectedDate.getTime());
      return {
        style: {
          cursor: 'pointer',
          transition: 'background-color 0.2s',
          backgroundColor:
            selectedDate.getTime() === date.getTime()
              ? '#00000011'
              : 'transparent',
        },
      };
    },
    [selectedDate],
  );
  const handleOnCalendarSelected = useCallback(
    (selectedItem: { value: string }) => {
      console.log('##on selected -> ', activeCalendars, selectedItem);
      let newActiveCalendars = [...activeCalendars];

      const index = newActiveCalendars.indexOf(selectedItem.value);
      console.log('##index => ', index);
      if (index !== -1) {
        // Calendar is already active, remove it
        newActiveCalendars.splice(index, 1);
      } else {
        // Calendar is not active, add it
        newActiveCalendars.push(selectedItem.value);
      }

      console.log('##newActiveCalendars => ', newActiveCalendars);
      dispatch(
        updateUserPreferences({
          id: myUser?.id,
          updates: {
            activeCalendars: newActiveCalendars,
          },
        }),
      );
    },
    [activeCalendars, dispatch, myUser?.id],
  );

  useEffect(() => {
    dispatch(fetchCalendars({}));
  }, [dispatch]);

  useEffect(() => {
    if (activeCalendars && currentDate) {
      dispatch(
        updateViewedCalendars({
          calendars: activeCalendars,
          currentDate,
          calendarViewType,
        }),
      );
    }
  }, [activeCalendars, dispatch, currentDate, calendarViewType]);

  return (
    <Card
      flex={1}
      h="100%"
      minW={{ base: '100%', md: 440 }}
      borderRadius={{ base: 0, md: '30px' }}>
      <VStack h={'100%'} gap={2}>
        <HStack w="100%" key={'header'}>
          <HStack spacing={'12px'}>
            <IconButton
              size={'xs'}
              aria-label="left-month"
              icon={<Icon as={BiChevronLeft} h="24px" w="24px" />}
              transform={`${i18n.dir() === 'rtl' ? 'rotate(180deg)' : ''}`}
              onClick={handlePrevStep}
            />
            <Flex flex={1} justify="center" align="center">
              <Text
                minW={200}
                textAlign="center"
                fontSize={'2xl'}
                fontWeight="bold">
                {moment(currentDate).locale(i18n.language).format('MMMM YYYY')}
              </Text>
            </Flex>
            <IconButton
              size="xs"
              aria-label="right-month"
              icon={<Icon as={BiChevronRight} h="24px" w="24px" />}
              transform={`${i18n.dir() === 'rtl' ? 'rotate(180deg)' : ''}`}
              onClick={handleNextStep}
            />
          </HStack>
          <Spacer />
          <IconButton
            size="xs"
            aria-label="add-event"
            variant={'actionIconBlue'}
            icon={<Icon as={MdAdd} h="25px" w="25px" />}
            onClick={() => navigate('/main/managment/events')}
          />
        </HStack>
        <HStack w="100%" key="dropdowns">
          <DropdownField
            closeOnSelect
            placeholder={calendarViewType === 'month' ? 'Month' : 'Week'}
            onSelected={({ value }: { value: string }) => {
              setCalendarViewType(value as 'month' | 'week');
            }}
            options={[
              { value: 'month', label: 'Month' },
              { value: 'week', label: 'Week' },
            ]}
          />
          <DropdownField
            closeOnSelect
            hideLabel
            menuItemProps={{
              style: {
                fontSize: '14px',
              },
            }}
            mb={0}
            // minW={{ base: '100%', md: '310px' }}
            maxW={{ base: '100%', md: '310px' }}
            flex={1}
            options={calendars?.map((calendar: any) => ({
              label: calendar.isSystem
                ? t(`calendar.general_calendars.${calendar.name}`)
                : calendar.name || calendar.email,
              value: calendar.label || calendar.id,
              id: calendar.label || calendar.id,
              startElement: (
                <Icon
                  my={2}
                  me={'7px'}
                  as={BsCircleFill}
                  fontSize={8}
                  color={calendar.color}
                />
              ),
            }))}
            topItem={
              <VStack w="100%">
                <HStack
                  onClick={() => {
                    dispatch(setCalendarsDialog({}));
                  }}
                  w="100%"
                  justify={'end'}>
                  <Text color="primary.100" fontSize="sm" fontWeight={400}>
                    {t('calendar.all')}
                  </Text>
                  <Spacer />
                  <Text
                    _hover={{
                      backgroundColor: 'transparent',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      dispatch(setCalendarsDialog({}));
                    }}
                    color="brand.900"
                    fontWeight={500}
                    textDecoration={'underline'}
                    fontSize="sm">
                    {t('calendar.manage_calendars')}
                  </Text>
                </HStack>
                <HStack
                  w="100%"
                  _hover={{ backgroundColor: 'gray.100', cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setAddCalendarDialog({}));
                  }}>
                  <Text color="primary.100" fontSize="sm" fontWeight={400}>
                    {t('calendar.add_calendar')}
                  </Text>
                  <Spacer />
                  <IconButton
                    aria-label="add-calendar"
                    as={MdAdd}
                    size="sm"
                    variant="actionIconBlue"
                  />
                </HStack>
              </VStack>
            }
            placeholder={t('calendar.choose_calendar')}
            onSelected={handleOnCalendarSelected}
            name="calendars"
            selectedOptions={activeCalendars}
          />
        </HStack>
        <Calendar
          popup
          key={'calendar'}
          localizer={localizer}
          defaultDate={new Date()}
          date={currentDate}
          step={120}
          culture={i18n.language}
          view={calendarViewType}
          onNavigate={(date) => {
            console.log('onNavigate => ', date);
            // setCurrentDate(date);
          }}
          onView={(view) => console.log('onView => ', view)}
          toolbar={false}
          rtl={i18n.dir() === 'rtl'}
          onSelectSlot={(slot) => {
            console.log('on clicked => ', slot);
            setSelectedDate(slot.start);
            const newDate = new Date(slot.start).toDateString();
            dispatch(
              updateSelectedDate(
                newDate + ' 00:00:00 GMT+0000 (Coordinated Universal Time)',
              ),
            );
          }}
          onSelectEvent={(event) => console.log('onSelectEvent => ', event)}
          dayPropGetter={getDayProp}
          selectable={true}
          selected={{ start: currentDate }}
          events={displayedEvents}
          eventPropGetter={(event, start, end, isSelected) => {
            const style = {
              backgroundColor: event?.color || '#AA915D',
              color: 'white',
              fontSize: '12px',
              paddingTop: '0px',
              paddingBottom: '0px',
              borderRadius: '5px',
            };
            return { style };
          }}
          style={{ height: '100%', width: '100%', fontSize: '12px' }}
        />
      </VStack>
    </Card>
  );
};
