import { AxiosError } from 'axios';
import { RootState } from 'contexts/redux/store';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MediaItem } from 'services/@types';
import _mediaItemService from 'services/mediaItem.api';

export const useAlbumMediaItems = (albumId: string) => {
  const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);
  const [loading, setLoading] = useState(true);
  const loadingMediaItem = useSelector(
    (state: RootState) => state.mediaItem.loading,
  );
  const [error, setError] = useState<string | null>(null);

  const fetchMediaItems = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await _mediaItemService.getMediaItems({
        albumID: albumId,
      });

      setMediaItems(response.results as MediaItem[]);
      setLoading(false);
    } catch (error) {
      setError((error as AxiosError).message);
      setLoading(false);
    }
  }, [albumId]);

  useEffect(() => {
    setLoading(true);
    setError(null);
    fetchMediaItems();
  }, [albumId, fetchMediaItems, loadingMediaItem]);

  return { mediaItems, loading, error };
};
