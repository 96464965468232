// src/features/businesses/userSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { Business, PaginatedResults } from 'services/@types';
import BusinessService from 'services/business.api';
import { updateMyUser } from '../auth/authSlice';
import { closeSplashDialog, showSplashDialog } from '../dialog/dialogsSlice';
import store, { RootState } from '../store';
import ReactGA from 'react-ga';
export interface BusinessState {
  businesses: Business[];
  business: Business | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: BusinessState = {
  businesses: [],
  business: null,
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchBusinesses = createAsyncThunk(
  'businesses/fetchBusinesss',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await BusinessService.getBusinesses(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchBusiness = createAsyncThunk(
  'business/fetchBusiness',
  async (businessId: string, { rejectWithValue }) => {
    try {
      const response = await BusinessService.getBusiness(businessId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createBusiness = createAsyncThunk(
  'business/createBusiness',
  async (business: Business, { rejectWithValue }) => {
    try {
      store.dispatch(
        showSplashDialog({
          title: 'signup.onboarding.business_info.creating_business',
        }),
      );
      const {
        auth: {
          user: { email },
        },
      } = store.getState();
      const newBusiness = await BusinessService.createBusiness({
        ...business,
        email,
      });
      const inviteSupplierToken = localStorage.getItem('inviteSupplierToken');
      if (inviteSupplierToken) {
        newBusiness.inviteToken = inviteSupplierToken;
        ReactGA.event({
          category: 'Key Event',
          action: 'Button Click',
          label: 'Submit Button',
          value: 1,
        });
      }
      console.log('#Redux create business ->', newBusiness);
      await store.dispatch(
        updateMyUser({ updates: { businessID: newBusiness.id } }),
      );
      store.dispatch(closeSplashDialog());

      return newBusiness;
    } catch (error) {
      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);

export const updateBusiness = createAsyncThunk(
  'bussiness/updateBusiness',
  async (
    { businessId, updates }: { businessId: string; updates: Partial<Business> },
    { rejectWithValue },
  ) => {
    try {
      const response = await BusinessService.updateBusiness(
        businessId,
        updates,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteBusiness = createAsyncThunk(
  'business/deleteBusiness',
  async (businessId: string, { rejectWithValue }) => {
    try {
      await BusinessService.deleteBusiness(businessId);
      return businessId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    resetBusiness: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all businesses
      .addCase(fetchBusinesses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchBusinesses.fulfilled,
        (state, action: PayloadAction<PaginatedResults<Business>>) => {
          state.businesses = action.payload.results;
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      .addCase(
        fetchBusinesses.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      // Fetch a single user
      .addCase(fetchBusiness.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchBusiness.fulfilled,
        (state, action: PayloadAction<Business>) => {
          state.business = action.payload;
          state.loading = false;
        },
      )
      .addCase(fetchBusiness.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create a user
      .addCase(createBusiness.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createBusiness.fulfilled,
        (state, action: PayloadAction<Business>) => {
          state.business = action.payload;
          state.loading = false;
        },
      )
      .addCase(createBusiness.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update a user
      .addCase(updateBusiness.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateBusiness.fulfilled,
        (state, action: PayloadAction<Business>) => {
          const index = state.businesses.findIndex(
            (user) => user.id === action.payload.id,
          );
          if (index !== -1) {
            state.businesses[index] = action.payload;
          }
          state.business = action.payload;
          state.loading = false;
        },
      )
      .addCase(updateBusiness.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete a user
      .addCase(deleteBusiness.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteBusiness.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.businesses = state.businesses.filter(
            (user) => user.id !== action.payload,
          );
          state.loading = false;
        },
      )
      .addCase(deleteBusiness.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export function useMyBusiness() {
  const business = useSelector((state: RootState) => state.businesses.business);
  return business;
}

export const { resetBusiness } = userSlice.actions;

export default userSlice.reducer;
