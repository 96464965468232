import { AxiosInstance, AxiosResponse } from 'axios';
import { PaginatedResults, User } from './@types';
import { createAxiosInstance } from './axios.util';

class UserService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createUser(user: User): Promise<User> {
    const response: AxiosResponse<User> = await this.api.post('/users', user);
    return response.data;
  }

  public async getUsers(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<User>> {
    const response: AxiosResponse<PaginatedResults<User>> = await this.api.get(
      '/users',
      {
        params: queryParams,
      },
    );
    return response.data;
  }

  public async getUser(userId: string): Promise<User> {
    const response: AxiosResponse<User> = await this.api.get(
      `/users/${userId}`,
    );
    return response.data;
  }

  public async updateUser(
    userId: string,
    updates: Partial<User>,
  ): Promise<User> {
    const response: AxiosResponse<User> = await this.api.patch(
      `/users/${userId}`,
      updates,
    );
    return response.data;
  }

  public async deleteUser(userId: string): Promise<void> {
    await this.api.delete(`/users/${userId}`);
  }
  public async deleteMe(): Promise<void> {
    await this.api.delete(`/users/me`);
  }
}

const _userService = new UserService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _userService;
