import { useToast } from '@chakra-ui/react';
import {
  AppDialogProps,
  closeAskForQuoteDialog,
  setEventsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { createQuote } from 'contexts/redux/quote/quoteSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface UseQuoteResult {
  isDataAndTimeActive: boolean;
  handleOpenEventDialog: () => void;
  handleClose: () => void;
  isOpenned: boolean;
  updateIsDataAndTimeActive: () => void;
  quote: Record<string, any>;
  sendQuote: () => void;
  handleChange: (key: string, value: any) => void;
}
export default function useQuote(): UseQuoteResult {
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const { askForQuoteDialog, currentSupplier } = useSelector(
    (state: RootState) => ({
      askForQuoteDialog: state.dialogs.askForQuoteDialog,
      currentSupplier: state.suppliers.currentSupplier,
    }),
  );
  const dialog = askForQuoteDialog as AppDialogProps;
  const [quote, setQuote] = useState<Record<string, any>>({});
  const [isDataAndTimeActive, setIsDataAndTimeActive] =
    useState<boolean>(false);

  const updateIsDataAndTimeActive = () =>
    setIsDataAndTimeActive((prev) => !prev);

  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: any) => {
          setQuote((prev) => ({ ...prev, event: e }));
        },
      }),
    );
  }, [dispatch]);

  const handleChange = useCallback((key, value) => {
    setQuote((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleClose = useCallback(() => {
    setQuote({});
    dispatch(closeAskForQuoteDialog());
  }, [dispatch]);
  const sendQuote = useCallback(async () => {
    const res = await dispatch(
      createQuote({
        eventId: quote.event?.id,
        freeText: quote.freeText,
        supplierId: currentSupplier?.id,
      }),
    );
    if (res.payload) {
      toast({
        variant: 'main',
        title: t('dialogs.ask_for_quote.toast.quote_sent'),
      });
    }
    handleClose();
  }, [handleClose, dispatch, quote, currentSupplier?.id, t, toast]);
  return {
    isDataAndTimeActive,
    handleOpenEventDialog,
    handleClose,
    updateIsDataAndTimeActive,
    quote,
    isOpenned: !!dialog,
    sendQuote,
    handleChange,
  };
}
