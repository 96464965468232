import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';

interface ChooseEventBtnProps extends ButtonProps {
  onClick: () => void;
}

export default function ChooseEventBtn(props: ChooseEventBtnProps) {
  const { t } = useTranslation();
  const { onClick, disabled, ...rest } = props;
  return (
    <Button
      variant="h1BadgeBlue"
      disabled={disabled}
      onClick={onClick}
      gap="38px"
      {...rest}>
      {t('dialogs.ask_for_quote.chose_event')}
      <Icon as={FiPlus} w="26px" h="26px" />
    </Button>
  );
}
