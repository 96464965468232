import { GAEventAction, GAEventCategory, GAEventLabel } from 'types/ga-enums';
import * as ReactGA from 'react-ga';
export const sendEvent = (
  category: GAEventCategory,
  action: GAEventAction,
  label: GAEventLabel,
  value: number = 1,
) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};
