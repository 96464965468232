import { Flex } from '@chakra-ui/react';
import { CalendarMedium } from './components/CalendarMedium';
import EventsAndMeeting from './components/EventsAndMeeting';
import TaskList from './components/TaskList';

export default function Dashboard() {
  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      height="100%"
      pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" height="100%" w="100%">
        <Flex gap={5} height="100%" w="100%">
          <Flex
            gap={5}
            maxH={580}
            wrap={{ base: 'wrap', md: 'nowrap' }}
            w="100%">
            <TaskList />
            <CalendarMedium />
            <EventsAndMeeting />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
