import React, { useCallback } from 'react';

// Chakra imports
import {
  Button,
  Flex,
  Icon,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Card from 'components/card/Card';

// Custom components

// Assets

import { MdCheck } from 'react-icons/md';
import { SignUpStep } from '../SignUpCentered';
import Pack from './Pack';
import { OnboardingNavigationHeader } from './OnboardingNavigationHeader';

interface PlanPackObj {
  key: string;
  name: string;
  title: string;
  subtitle: string;
  price: string;
  annualPrice: string;
  discountedPrice: string;
  footer: string;
  features: string[];
}

const PlansTab: React.FC<{ nextClick: () => void; activeTab: SignUpStep }> = ({
  nextClick,
  activeTab,
}) => {
  //   const [selectedSeats, setSelectedSeats] = useState(1);
  //   const [selectedPlan, setSelectedPlan] = useState<PlanPackObj | null>(null);
  const { t } = useTranslation();

  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'brand.300');
  const cardBg = useColorModeValue('white', 'brand.800');
  const textColorDetails = useColorModeValue('gray.600', 'secondaryGray.600');
  const isMobile = useMediaQuery('max-width: 768px');
  const handlePlanPicked = useCallback(
    (plan: PlanPackObj) => {
      // setSelectedPlan(plan);
      nextClick();
      if (isMobile) {
        // scroll to 20% of the screen
        window.scrollTo(0, window.innerHeight * 0.2);
      }
    },
    [nextClick, isMobile],
  );

  const planPacks: PlanPackObj[] = t('signup.onboarding.plans.plan_packs', {
    returnObjects: true,
  });
  console.log('planPacks ->', planPacks);
  const pricingDiscoverFeatures: string[] = t(
    'signup.onboarding.plans.discover_features',
    { returnObjects: true },
  );

  return (
    <Flex justify={'center'} key="plans_tab">
      <Card
        pt={10}
        px={0}
        boxShadow={{ base: 'none', sm: '0px', md: 'xl', lg: 'xl' }}
        bg={{ base: cardBg, sm: 'transparent', md: cardBg, lg: cardBg }}
        w={{ sm: '330px', md: '420px', lg: '420px' }}
        display={activeTab === SignUpStep.pricing ? 'flex' : 'none'}>
        <OnboardingNavigationHeader />
        <Text
          color={textColor}
          fontSize="2xl"
          fontWeight="700"
          mb="20px"
          mx={10}>
          {t('signup.onboarding.plans.you_discovered_features')}
        </Text>

        <Flex direction="column" w="100%" px={10}>
          <Stack direction="column" spacing={0} gap={0}>
            {pricingDiscoverFeatures?.map((feature, key) => (
              <Flex
                key={key}
                align="start"
                w="full"
                py={2}
                columnGap={2}
                px={0}>
                <Icon as={MdCheck} color={'brand.400'} mt={1} />
                <Text fontSize="md" fontWeight="normal" color="#465D55">
                  {feature}
                </Text>
              </Flex>
            ))}
          </Stack>
          <Flex justify="space-between" mt="84px">
            <Button
              variant="h1cta"
              borderRadius="16px"
              w="full"
              onClick={nextClick}>
              {t('signup.onboarding.plans.choose_your_plan')}
            </Button>
          </Flex>
          <Text color={textColorDetails} fontSize="sm" mt="10px">
            {t('signup.onboarding.plans.already_have_plan')}{' '}
            <Link fontWeight="500" color="brand.900">
              {t('signup.onboarding.plans.redeem')}
            </Link>
          </Text>
        </Flex>
      </Card>
      <Card
        display={activeTab === SignUpStep.plans ? 'flex' : 'none'}
        background="transparent"
        boxShadow={{ base: 'none' }}
        bg={{ base: 'transparent' }}>
        <Flex
          direction="column"
          alignSelf="center"
          w="100%"
          justifySelf="center"
          overflow="hidden">
          <Flex
            direction="column"
            textAlign="center"
            justifyContent="center"
            align="center"
            mb="38px">
            <Stack
              direction={{
                sm: 'column',
                lg: 'row',
                md: 'column',
                xl: 'row',
              }}
              alignItems="flex-start"
              spacing="20px"
              mt="40px"
              mb="160px">
              <VStack
                alignSelf={'start'}
                w={{ base: 190, sm: '100%', md: 420, lg: 190 }}
                bg={{ base: '#ffffffB3', sm: 'transparent', md: '#ffffffB3' }}
                py={6}
                position="relative"
                alignItems="start"
                borderRadius="2xl"
                mt={{ base: 0, sm: 0, md: 20, lg: 20 }}>
                <OnboardingNavigationHeader hideCloseButton />
                <VStack px={6}>
                  <Text
                    fontSize={{ base: 'md' }}
                    color={textColor}
                    fontWeight="bold"
                    textAlign={'start'}>
                    {t('signup.onboarding.plans.what_you_need_arrived')}
                  </Text>
                  <Text textAlign={'start'}>
                    {t('signup.onboarding.plans.more_productivity')}
                  </Text>
                </VStack>
              </VStack>
              {planPacks.map((pack, index) => (
                <Pack
                  key={index + ''}
                  planKey={pack.key}
                  title={pack.name}
                  desc={pack.title}
                  highlighted={index === 1}
                  button="Get started"
                  footer={pack.footer}
                  onClick={() => handlePlanPicked(pack)}
                  price={pack.price}
                  annualPrice={pack.annualPrice}
                  discountedPrice={pack.discountedPrice}
                  details={pack.subtitle}
                  benefits={pack.features}
                />
              ))}
              <Flex w={190} />
            </Stack>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

export default PlansTab;
