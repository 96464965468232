import {
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import Dropzone from 'components/dropzone';
import InputField from 'components/fields/InputField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  setAddPhotoDialog,
  setAddVideoLinkModal,
} from 'contexts/redux/dialog/dialogsSlice';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PiLinkSimpleBold } from 'react-icons/pi';
import { useDispatch } from 'react-redux';

import { Album, MediaItem, UrlItem } from 'services/@types';
import { MediaItemComponent } from './MediaItemComponent';

import emptyGalleryImg from 'assets/svg/gallery/empty-gallery-img.svg';
import generalAlbumArrow from 'assets/svg/gallery/general-album-arrow.svg';

import { FaX } from 'react-icons/fa6';

export const MediaCollectionComponent: React.FC<{
  items: MediaItem[];
  album: Album;
}> = ({ items, album }) => {
  const { t, i18n } = useTranslation();
  const user = useMyUser();
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const dispatch = useDispatch<any>();
  const handleUploadCompleted = useCallback(
    (data: UrlItem) => {
      console.log('Upload completed -> ', data);
      dispatch(
        setAddPhotoDialog({
          item: {
            media: [data],
            albumID: album.id,
            privacy: 'public',
            type: 'image',
          },
        }),
      );
    },
    [dispatch, album],
  );
  return (
    <Flex flexDirection="column" justifyContent="center" py={2}>
      <Flex wrap={{ base: 'wrap' }} gap={4}>
        <VStack h={'160px'} justify="center" w={{ base: '100%', md: 160 }}>
          <Flex flex={1} w="100%">
            <Dropzone
              key={`upload`}
              uploadBarStyle="line"
              target="album"
              uploadIcon={<Icon as={PiLinkSimpleBold} color="brand.900" />}
              targetId={album?.id}
              goldenUploadLabel={t('gallery.album_collection.add_photo')}
              labelSize="14px"
              access="public"
              uploadKey={`media_${items?.length || 0}`}
              onUploadCompleted={handleUploadCompleted}
              dropzoneOptions={{
                accept: 'image/*,video/*',
                multiple: false,
              }}
            />
          </Flex>
          <Flex flex={1} w="100%">
            <Dropzone
              key={`upload`}
              uploadBarStyle="line"
              target="album"
              goldenUploadLabel={t('gallery.album_collection.add_video')}
              labelSize="14px"
              uploadIcon={<Icon as={PiLinkSimpleBold} color="brand.900" />}
              targetId={user?.id}
              access="public"
              onClick={() => {
                console.log('on clicked add video');
                dispatch(setAddVideoLinkModal({ item: { albumID: album.id } }));
              }}
              uploadKey={`media_link_${items?.length || 0}`}
            />
          </Flex>
        </VStack>
        <Image
          display={items?.length > 0 || !isDesktop ? 'none' : 'block'}
          src={generalAlbumArrow}
          w={{ base: 120, md: 160 }}
          mt={{ base: 10, md: 0 }}
          h={{ base: 120, md: 160 }}
          sx={{ transform: `scaleX(${i18n.dir() === 'rtl' ? -1 : 1})` }}
        />
        <Flex
          justify={{ base: 'center', md: 'start' }}
          w={{ base: '100%', md: 'fit-content' }}
          wrap="wrap"
          gap={4}
          display={items?.length > 0 ? 'none' : 'flex'}>
          <Flex>
            <VStack align={{ base: 'center', md: 'start' }} spacing={0}>
              <Text color="brand.900" fontWeight={'bold'} fontSize="md">
                {t('gallery.album_collection.showcase_your_brilliance')}
              </Text>
              <Text fontSize="sm">
                {t('gallery.album_collection.enhance_visibility')}
              </Text>
              <HStack flexDirection={{ base: 'column', md: 'row' }}>
                <Image
                  src={emptyGalleryImg}
                  w={110}
                  h={110}
                  borderRadius="2xl"
                />
                <VStack align="start">
                  <InputField isDisabled placeholder={t('tags')} />
                  <Spacer />
                  <HStack>
                    {['Modern', 'Bat mitzva'].map((tag) => (
                      <Button
                        variant={'outlineTag'}
                        fontSize="7px"
                        rightIcon={<FaX />}
                        borderRadius={'5px'}>
                        {tag}
                      </Button>
                    ))}
                  </HStack>
                </VStack>
              </HStack>
            </VStack>
          </Flex>
        </Flex>
        {items?.map((item) => (
          <Flex
            minW={140}
            //   minH={140}
            flex={1}
            maxH={240}
            maxW={160}
            sx={{ aspectRatio: 1 }}>
            <MediaItemComponent item={item} path={''} />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
