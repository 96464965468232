import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import OutlookIcon from 'assets/img/addCalendar/outlook.png';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import { RadioButtonItem } from 'components/fields/RadioItem';
import { VSeparator } from 'components/separator/Separator';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { createCalendar } from 'contexts/redux/calendar/calendarsSlice';
import {
  closeAddCalendarDialog,
  showSplashDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { createInvites } from 'contexts/redux/invite/inviteSlice';
import { RootState } from 'contexts/redux/store';
import { fetchUsers } from 'contexts/redux/user/userSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FcGoogle } from 'react-icons/fc';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { UrlItem, User } from 'services/@types';
import { useGoogleCalendarAuth } from '../hooks/useGoogleCalendarAuth';
import { useOutlookCalendarAuth } from '../hooks/useOutlookCalendarAuth';

export default function AddCalendarModal() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const myUser = useMyUser();
  const [sendingInvites, setSendingInvites] = useState(false);
  const [assigned, setAssigned] = useState<User[]>([]);
  const [currentAssigned, setCurrentAssigned] = useState<string>('');
  const [currentRadioButton, setCurrentRadioButton] = useState<string>('');
  const [editInput, setEditInput] = useState('');
  const { initiateAuth: initiateGoogleAuth } = useGoogleCalendarAuth();
  const toast = useToast();
  const { initiateAuth: initiateOutlookAuth } = useOutlookCalendarAuth();
  const { addCalendarDialog } = useSelector(
    (state: RootState) => state.dialogs,
  );
  const { loading } = useSelector((state: RootState) => state.calendars);
  const handleClose = useCallback(() => {
    dispatch(closeAddCalendarDialog());
  }, [dispatch]);

  const handleInitiateAuth = useCallback(
    (provider: 'google' | 'outlook') => {
      if (provider === 'google') {
        initiateGoogleAuth();
      } else if (provider === 'outlook') {
        initiateOutlookAuth();
      }
    },
    [initiateGoogleAuth, initiateOutlookAuth],
  );
  const handleCloseCompleted = useCallback(() => {
    setEditInput('');
  }, []);
  const isOpen = useMemo(() => !!addCalendarDialog, [addCalendarDialog]);

  const myBusiness = useMyBusiness();
  const [displayingAssigned, setDisplayingAssigned] = useState<
    {
      avatar: UrlItem | undefined;
      email: string;
      label: string;
      value: string;
    }[]
  >([]);

  useEffect(() => {
    const fetchSeats = async () => {
      const users = await dispatch(
        fetchUsers({
          name: currentAssigned,
          limit: 5,
        }),
      );

      if (users?.payload?.results) {
        setDisplayingAssigned(
          users.payload.results
            .filter((user: User) => !assigned.find((a) => a.id === user?.id))
            .filter((user: User) => user.id !== myUser?.id)
            .map((user: User) => ({
              avatar: user.media?.find(
                (media: UrlItem) => media.type === 'avatar',
              ),
              email: user.email,
              label: `${user.firstName} ${user.lastName}`,
              value: user?.id,
            })),
        );
      }
    };
    fetchSeats();
  }, [currentAssigned, dispatch, myBusiness?.id, assigned, myUser?.id]);

  const handleRadioButtonSelected = useCallback((value: string) => {
    console.log('value', value);
    setCurrentRadioButton(value);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      onCloseComplete={handleCloseCompleted}>
      <ModalOverlay />
      <ModalContent mx="16px" borderRadius="16px" p="24px" maxW={540}>
        <ModalBody p={0}>
          <Flex flexDirection="column" w="100%" gap="24px">
            <HStack justifyContent="space-between">
              <Text fontSize={'2xl'} fontWeight="bold">
                {t('calendar.add_calendar_modal.title')}
              </Text>
              <IconButton
                variant="action"
                size="sm"
                borderRadius={'xl'}
                bg="white"
                fontSize={20}
                icon={<FiX color="#939393" />}
                onClick={handleClose}
                aria-label={''}
              />
            </HStack>
            <VStack gap="0px">
              <InputField
                labelProps={{ ms: '15px', mb: '8px' }}
                w="100%"
                isDisabled={currentRadioButton !== 'create_calendar'}
                opacity={currentRadioButton === 'create_calendar' ? 1 : 0.5}
                transition={'all 0.3s'}
                id="create_calendar"
                radioButtonChecked={currentRadioButton === 'create_calendar'}
                showRadioButton
                onRadioButtonChecked={handleRadioButtonSelected}
                onChange={(e) => setEditInput(e.target.value)}
                value={editInput}
                label={t('calendar.add_calendar_modal.create_calendar_label')}
                placeholder={t(
                  'calendar.add_calendar_modal.create_calendar_placeholder',
                )}
                name="title"
              />

              <Flex flexDirection="column" w="100%">
                <VSeparator
                  bg="#939393"
                  h="1px"
                  my={5}
                  opacity="0.4"
                  w="100%"
                />
                <DropdownField
                  showRadioButton
                  transition={'all 0.3s'}
                  isDisabled
                  opacity={
                    currentRadioButton === 'choose_existing_calendars' ? 1 : 0.5
                  }
                  id="choose_existing_calendars"
                  radioButtonChecked={
                    currentRadioButton === 'choose_existing_calendars'
                  }
                  onRadioButtonChecked={handleRadioButtonSelected}
                  formLabelStyles={{ ms: '15px', mb: '8px' }}
                  w="100%"
                  label={t(
                    'calendar.add_calendar_modal.choose_existing_calendars',
                  )}
                  placeholder={t(
                    'calendar.add_calendar_modal.choose_existing_calendars_placeholder',
                  )}
                />
                <VSeparator
                  bg="#939393"
                  h="1px"
                  my={5}
                  opacity="0.4"
                  w="100%"
                />
                <VStack
                  transition={'all 0.3s'}
                  opacity={
                    currentRadioButton === 'add_external_calendar' ? 1 : 0.5
                  }>
                  <HStack
                    w="100%"
                    justify={'start'}
                    opacity={
                      currentRadioButton === 'add_external_calendar' ? 1 : 0.5
                    }>
                    <RadioButtonItem
                      w="fit-content"
                      value={'add_external_calendar'}
                      label={''}
                      checked={currentRadioButton === 'add_external_calendar'}
                      onChange={handleRadioButtonSelected}
                    />
                    <Text
                      variant="cardSectionTitle"
                      alignSelf="start"
                      ms="15px"
                      onClick={() =>
                        handleRadioButtonSelected('add_external_calendar')
                      }
                      mb="8px">
                      {t('calendar.add_calendar_modal.add_external_calendar')}
                    </Text>
                  </HStack>
                  <Button
                    isDisabled={currentRadioButton !== 'add_external_calendar'}
                    onClick={() => {
                      dispatch(showSplashDialog({}));
                      dispatch(closeAddCalendarDialog());
                      handleInitiateAuth('outlook');
                    }}
                    w="100%"
                    leftIcon={
                      <Image
                        src={OutlookIcon}
                        alt="Outlook"
                        h="32px"
                        w="32px"
                      />
                    }
                    variant="google"
                    border="1px solid #0000001A">
                    Outlook
                  </Button>
                  <Button
                    mt="16px"
                    isDisabled={currentRadioButton !== 'add_external_calendar'}
                    w="100%"
                    variant="google"
                    border="1px solid #0000001A"
                    leftIcon={<Icon as={FcGoogle} w="22px" h="22px" />}
                    onClick={() => {
                      dispatch(showSplashDialog({}));
                      dispatch(closeAddCalendarDialog());
                      handleInitiateAuth('google');
                    }}>
                    Google
                  </Button>
                </VStack>
              </Flex>

              <Button
                alignSelf="end"
                variant="h1cta"
                isLoading={loading || sendingInvites}
                onClick={async () => {
                  if (assigned?.length > 0) {
                    setSendingInvites(true);
                    try {
                      const { payload } = await dispatch(
                        createInvites(
                          assigned.map((u: User) => ({
                            businessID: myBusiness.id!,
                            email: u.email!,
                            recipientID: u.id!,
                            fullName: `${u.firstName} ${u.lastName}`,
                            tags: [],
                            type: 'calendar',
                          })),
                        ),
                      );
                      setSendingInvites(false);

                      if (payload?.response?.status === 409) {
                        return;
                      }
                      toast({
                        variant: payload?.id ? 'main' : 'error',
                        title: payload?.id
                          ? t('calendar.add_calendar_modal.toast.invite_sent')
                          : t(
                              'calendar.add_calendar_modal.toast.invite_failed',
                            ),
                      });
                    } catch (e) {
                      toast({
                        variant: 'error',
                        title: t(
                          'calendar.add_calendar_modal.toast.invite_failed',
                        ),
                      });
                    }
                  } else if (editInput !== '') {
                    dispatch(createCalendar({ name: editInput }));
                  }
                  dispatch(closeAddCalendarDialog());
                }}
                p="15px 72px"
                isDisabled={editInput === '' && assigned?.length === 0}>
                {assigned?.length > 0
                  ? t('calendar.add_calendar_modal.invite')
                  : t('calendar.add_calendar_modal.add')}
              </Button>
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
