// Chakra imports
import {
    Flex,
    Image,
    Link,
    Text, useColorModeValue
} from '@chakra-ui/react';

// Assets
import error from 'assets/img/others/error.png';
import { useTranslation } from 'react-i18next';
import bgSquaresSplash from '../../../../../assets/svg/splash/bg-squares-splash.svg';

function NoMatch() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const { t } = useTranslation();

  return (
    <Flex
      bgGradient="linear(to-r, brand.400, brand.600)"
      bgSize="cover"
      direction="column"
      justify={{ sm: 'center', base: 'flex-start' }}
      align="center"
      h="100vh"
    >
      <Flex
        w="full"
        h="full"
        align="center"
        justify="center"
        position={'absolute'}
        zIndex="1"
      >
        <Flex flexDirection="row-reverse" gap={0} h="full" w="full">
          <Image
            aria-label="brand-logo"
            src={bgSquaresSplash}
            alt="logo"
            w={'full'}
            h="full"
            objectFit={'cover'}
          />
        </Flex>
      </Flex>
      <Image
        zIndex="10"
        src={error}
        w="400px"
        maxW="90%"
        mt={{ base: '4vh', lg: '20vh' }}
        mb="10px"
      />
      <Text
        zIndex="10"
        color={textColor}
        fontSize={{ base: '40px', lg: '46px' }}
        fontWeight="700"
        mb="30px"
        textAlign={{ base: 'center', md: 'start' }}
      >
        {t('no_match.not_found')}
      </Text>
      <Flex
        align="center"
        direction={{ base: 'column', md: 'row' }}
        zIndex="10"
      >
        <Text
          color={textColor}
          fontWeight="500"
          fontSize={{ base: 'md', md: 'lg' }}
          me="4px"
        >
          {t('no_match.start_back')}
        </Text>
        <Link
          color={brandColor}
          fontSize={{ base: 'md', md: 'lg' }}
          fontWeight="500"
          href="/"
        >
          {t('no_match.home_page')}
        </Link>
      </Flex>
    </Flex>
  );
}

export default NoMatch;
