import AlertDialog from 'components/alertDialog';
import { RootState } from 'contexts/redux/store';
import { useSelector } from 'react-redux';

export default function AlertDialogsContainer() {
  const alertDialogs = useSelector(
    (state: RootState) => state.dialogs.alertDialog,
  );
  console.log('# alertDialogs ->', alertDialogs);
  return (
    <>
      {alertDialogs.map((_, index) => (
        <AlertDialog key={index} index={index} />
      ))}
    </>
  );
}
