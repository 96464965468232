import { Collapse, Flex, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheck } from 'react-icons/fi';
const fields = [
  'companyName',
  'companyPhone',
  'businessId',
  'contact',
  'email',
  'phone',
];
interface CreateEventPlannerProps {
  handleChange: (key: string, value: any) => void;
  event: Record<string, any>;
}
export default function CreateEventPlanner(props: CreateEventPlannerProps) {
  const { handleChange, event } = props;
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const eventPlanner = useMemo(
    () => event?.eventPlanner || {},
    [event?.eventPlanner],
  );
  return (
    <Stack gap={3}>
      <HStack
        onClick={() => setIsFormVisible(!isFormVisible)}
        cursor="pointer"
        w="fit-content">
        <Icon
          as={FiCheck}
          bg={isFormVisible ? 'brand.900' : 'white'}
          border="3px solid"
          borderColor="brand.900"
          w="20px"
          h="20px"
          transition="all 0.3s ease"
          color="white"
          borderRadius="10px"
        />
        <Text variant="eventSubTitle">{t('create_event.event_planner')}</Text>
      </HStack>
      <Collapse in={isFormVisible} animateOpacity>
        <Flex wrap="wrap" gap="40px" alignItems="end">
          {fields.map((field, key) => (
            <InputField
              key={key}
              label={t(`create_event.label.${field}`)}
              placeholder={t(`create_event.placeholder.${field}`)}
              w={{ base: '100%', lg: '318px' }}
              flex="1"
              name={field}
              value={eventPlanner[field] || ''}
              onChange={(e) =>
                handleChange('eventPlanner', {
                  ...eventPlanner,
                  [field]: e.target.value,
                })
              }
            />
          ))}
        </Flex>
      </Collapse>
    </Stack>
  );
}
