// Start your list

// Life has suddenly
// gotten simpler
import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const EmptyProductListPlaceholder: React.FC = () => {
  const { t } = useTranslation();
  return (
    <VStack
      px={'52px'}
      py="42px"
      spacing={0}
      align="start"
      justify="center"
      direction="column">
      <Text fontSize="2xl" fontWeight={'bold'} color="#000000CC">
        {t('products.empty_state_placeholder.title')}
      </Text>
      <Text fontSize="md" color="#000000cc" maxW={140}>
        {t('products.empty_state_placeholder.subtitle')}
      </Text>
    </VStack>
  );
};
