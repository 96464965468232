import { cssVar } from '@chakra-ui/system';

const arrowBg = cssVar('popper-arrow-bg');
export const tooltipStyles = {
  components: {
    Tooltip: {
      baseStyle: {},
      variants: {
        business: {
          background: '#FFFFFF',
          color: '#00000099',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '14.4px',
          paragraph: '12px',
          p: '8px 12px',
          borderRadius: '8px',
          [arrowBg.variable]: '#FFFFFF',
        },
        event: {
          background: '#B4D8CC',
          color: '#00000099',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '15.62px',
          borderRadius: '10px',
        },
      },
    },
  },
};
