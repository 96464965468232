import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Chakra imports
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
  useColorModeValue,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';

import Card from 'components/card/Card';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import { useTranslation } from 'react-i18next';
// Custom components
import Dropzone from 'components/dropzone';
// Assets

import analyticsIcon from 'assets/svg/icons/onboarding/analytics.svg';
import calendarIcon from 'assets/svg/icons/onboarding/calendar.svg';
import comunicationIcon from 'assets/svg/icons/onboarding/comunication.svg';
import contractIcon from 'assets/svg/icons/onboarding/contract.svg';
import digitalsignitureIcon from 'assets/svg/icons/onboarding/digitalsigniture.svg';
import eventsIcon from 'assets/svg/icons/onboarding/events.svg';
import financeIcon from 'assets/svg/icons/onboarding/finance.svg';
import handshakeIcon from 'assets/svg/icons/onboarding/handshake.svg';
import quotesIcon from 'assets/svg/icons/onboarding/quotes.svg';
import suppliersIcon from 'assets/svg/icons/onboarding/suppliers.svg';
import timelineIcon from 'assets/svg/icons/onboarding/timeline.svg';
import todoIcon from 'assets/svg/icons/onboarding/todo.svg';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  createBusiness,
  updateBusiness,
  useMyBusiness,
} from 'contexts/redux/business/businessSlice';
import { RootState } from 'contexts/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { Business, UrlItem } from 'services/@types';
import { SignUpStep } from '../SignUpCentered';
import { OnboardingNavigationHeader } from './OnboardingNavigationHeader';

const PersonalizationCategories = [
  {
    id: 1,
    title: 'calendar',
    icon: calendarIcon,
  },
  {
    id: 2,
    title: 'to_do_list',
    icon: todoIcon,
  },
  {
    id: 3,
    title: 'digital_signature',
    icon: digitalsignitureIcon,
  },
  {
    id: 4,
    title: 'timeline',
    icon: timelineIcon,
  },
  {
    id: 5,
    title: 'handshake',
    icon: handshakeIcon,
  },
  {
    id: 6,
    title: 'events',
    icon: eventsIcon,
  },
  {
    id: 7,
    title: 'contract',
    icon: contractIcon,
  },
  {
    id: 8,
    title: 'quotes',
    icon: quotesIcon,
  },
  {
    id: 9,
    title: 'finance',
    icon: financeIcon,
  },
  {
    id: 10,
    title: 'suppliers',
    icon: suppliersIcon,
  },
  {
    id: 11,
    title: 'communication',
    icon: comunicationIcon,
  },
  {
    id: 12,
    title: 'analytics',
    icon: analyticsIcon,
  },
];
const PersonalizeTab: React.FC<{
  nextClick: () => void;
  activeTab: SignUpStep;
}> = ({ nextClick, activeTab }) => {
  // Chakra color mode
  const { t } = useTranslation();
  const user = useMyUser();
  const { businessCategories } = useSelector(
    (state: RootState) => state.businessCategories,
  );
  const myBusiness = useMyBusiness();
  const dispatch = useDispatch<any>();
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorDetails = useColorModeValue('gray.600', 'secondaryGray.600');
  const isMobile = useMediaQuery('max-width: 768px');
  const [isUploadingStates, setIsUploadingStates] = useState({
    logo: false,
    profileImage: false,
  });
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const [inputValues, setInputsValues] = useState<
    Partial<{ businessName: string; media: UrlItem[] }>
  >({ media: [], businessName: '' });

  const cardBg = useColorModeValue('white', 'brand.800');

  const [selectedBusinessCategories, setSelectedBusinessCategories] = useState<
    string[]
  >([]);

  const showedOptions = useMemo(
    () =>
      selectedBusinessCategories
        .map((i) => {
          const cat = businessCategories.find((tag) => tag.id === i)?.name;
          if (cat) return t(`categories.${cat}`);
          return false;
        })
        .join(', '),
    [selectedBusinessCategories, businessCategories, t],
  );

  const personalizedMoreItems: { title: string; subtitle: string }[] = t(
    'signup.onboarding.personalization.additional_features',
    { returnObjects: true },
  );
  useEffect(() => {
    if (myBusiness) {
      setInputsValues({
        businessName: myBusiness.businessName,
        media: myBusiness.media,
      });
      setSelectedBusinessCategories(myBusiness.category);
    }
  }, [myBusiness]);

  const handleBusinessCategoriesSelected = useCallback(
    (selected: { label: string; id: string }) => {
      console.log('selected ->', selected);
      if (selectedBusinessCategories.includes(selected.id)) {
        setSelectedBusinessCategories(
          selectedBusinessCategories.filter(
            (category) => category !== selected.id,
          ),
        );
        return;
      }
      setSelectedBusinessCategories([
        ...selectedBusinessCategories,
        selected.id,
      ]);
    },
    [selectedBusinessCategories],
  );

  const handleBusinessNext = useCallback(async () => {
    // create business
    setValidationErrors({});
    if (!inputValues.businessName) {
      setValidationErrors(((prev: Record<string, string>) => ({
        ...prev,
        businessName: 'signup.onboarding.business_info.business_name_required',
      })) as any);
      return;
    }
    if (selectedBusinessCategories.length === 0) {
      setValidationErrors(((prev: Record<string, string>) => ({
        ...prev,
        businessCategory:
          'signup.onboarding.business_info.business_category_required',
      })) as any);

      return;
    }

    // convert tag names to tag ids
    const categoriesNames = selectedBusinessCategories.map(
      (i) => businessCategories.find((tag) => tag.id === i)?.name,
    );

    const newBusiness: Business = {
      businessName: inputValues.businessName,

      category: businessCategories
        .filter((tag) => categoriesNames.includes(tag.name))
        .map((tag) => tag.id),
      media: inputValues.media,
    };
    if (myBusiness) {
      dispatch(
        updateBusiness({
          businessId: myBusiness.id,
          updates: newBusiness,
        }),
      );
    } else {
      dispatch(createBusiness(newBusiness));
    }
    nextClick();
  }, [
    businessCategories,
    dispatch,
    inputValues.businessName,
    inputValues.media,
    myBusiness,
    nextClick,
    selectedBusinessCategories,
  ]);

  const handleUploadCompleted = useCallback(
    (url: UrlItem) => {
      setInputsValues((prev) => ({
        ...prev,
        media: [...prev?.media?.filter((i) => i.type !== url.type), url],
      }));
      setIsUploadingStates((prev) => ({
        ...prev,
        [url.type]: false,
      }));
    },
    [setInputsValues],
  );
  return (
    <Flex
      justifyContent="center"
      key="personalize_tab"
      display={
        [SignUpStep.personalization, SignUpStep.business].includes(activeTab)
          ? 'flex'
          : 'none'
      }>
      <Card
        pt={10}
        px={0}
        position={'relative'}
        w={{ sm: '100%', md: '414px' }}
        boxShadow={{ base: 'none', sm: '0px', md: 'xl', lg: 'xl' }}
        bg={{ base: cardBg, sm: 'transparent', md: cardBg, lg: cardBg }}
        display={activeTab === SignUpStep.business ? 'flex' : 'none'}>
        <OnboardingNavigationHeader />
        <Text
          color={textColor}
          fontSize="2xl"
          fontWeight="700"
          mb="20px"
          mx={10}>
          {t('signup.onboarding.business_info.tell_us_business')}
        </Text>
        <Text color={textColorDetails} fontSize="md" mb="20px" mx={10}>
          {t('signup.onboarding.business_info.personalizing_business')}
        </Text>
        <Flex direction="column" w="100%" px={10}>
          <Stack
            direction="column"
            spacing="20px"
            mb="20px"
            w="100%"
            display="flex">
            <InputField
              mb="0px"
              onChange={(e: any) => {
                setInputsValues({
                  ...inputValues,
                  businessName: e.target.value,
                });
              }}
              value={inputValues.businessName}
              id="businessName"
              errorLabel={validationErrors['businessName']}
              placeholder="eg. Team Brilliant"
              label={t('signup.onboarding.business_info.business_name')}
            />
            <Text color={textColorDetails} fontSize="sm">
              {t('signup.onboarding.business_info.logo_for_documents')}
            </Text>
            <Flex gap="20px" w="full">
              <Dropzone
                flex={2}
                uploadKey="logo"
                uploadLabel={t(
                  'signup.onboarding.business_info.add_logo_or_drag',
                )}
                target="user"
                initialProgress={
                  myBusiness?.media?.find((i) => i.type === 'logo') ? 100 : 0
                }
                targetId={user?.id}
                access="public"
                onStartUpload={() => {
                  console.log('start upload', isUploadingStates.logo);
                  setIsUploadingStates((prev) => ({
                    ...prev,
                    logo: true,
                  }));
                }}
                onUploadCompleted={handleUploadCompleted}
                dropzoneOptions={{
                  accept: 'image/*',
                  multiple: false,
                }}
                minH={70}
              />
              <Dropzone
                uploadKey="profileImage"
                target="user"
                initialProgress={
                  myBusiness?.media?.find((i) => i.type === 'profileImage')
                    ? 100
                    : 0
                }
                targetId={user?.id}
                access="public"
                onStartUpload={() =>
                  setIsUploadingStates((prev) => ({
                    ...prev,
                    profileImage: true,
                  }))
                }
                onUploadCompleted={handleUploadCompleted}
                uploadLabel={t('signup.onboarding.business_info.add_logo')}
                minH={70}
                flex={1}
                dropzoneOptions={{
                  accept: 'image/*',
                  multiple: false,
                }}
              />
            </Flex>
            <DropdownField
              error={validationErrors['businessCategory']}
              options={businessCategories.map((tag) => ({
                label: t(`categories.${tag.name}`),
                id: tag.id,
              }))}
              onSelected={handleBusinessCategoriesSelected}
              label={t('signup.onboarding.business_info.business_categories')}
              placeholder={t(
                'signup.onboarding.business_info.select_categories',
              )}
              // onChange={(selected: string[]) =>
              //   setSelectedBusinessCategories(selected)
              // }
              selectedOptions={selectedBusinessCategories}
              showedOptions={showedOptions}
              maxItems={3}
            />
          </Stack>

          <VStack justify="space-between">
            <Button
              variant="h1cta"
              fontSize="sm"
              borderRadius="16px"
              w="full"
              h="46px"
              ms="auto"
              isLoading={
                isUploadingStates.logo || isUploadingStates.profileImage
              }
              onClick={handleBusinessNext}>
              {t('signup.onboarding.business_info.continue')}
            </Button>
          </VStack>
        </Flex>
      </Card>
      <Card
        p="0px"
        w={{ sm: 'full', md: '899px' }}
        boxShadow={{ base: 'none', sm: '0px', md: 'xl', lg: 'xl' }}
        bg={{ base: cardBg, sm: 'transparent', md: cardBg, lg: cardBg }}
        overflow={'hidden'}
        display={activeTab === SignUpStep.personalization ? 'flex' : 'none'}>
        <OnboardingNavigationHeader />
        <Flex wrap={'wrap'}>
          <VStack
            align="start"
            p={'40px'}
            flex={1}
            maxW={{ base: '100%', lg: 360 }}>
            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="0px">
              {t('signup.onboarding.personalization.personalized_dashboard')}
            </Text>
            <Text color={textColorDetails} fontSize="md" mb="20px">
              {t('signup.onboarding.personalization.here_is_what_you_get')}
            </Text>
            <Flex
              direction="column"
              mt={{ base: '16px', md: '20px' }}
              alignSelf="center">
              <Grid
                mt={{ base: '24px', md: '70px' }}
                columnGap={{ base: '14px', md: '10px' }}
                rowGap={{ base: '20px', md: '24px' }}
                w="fit-content"
                templateColumns={{
                  base: 'repeat(3, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}>
                {PersonalizationCategories.map((category, key) => (
                  <GridItem key={key}>
                    <VStack>
                      <Image w={'20px'} h={'20px'} src={category.icon} />
                      <Text fontSize={'xs'}>
                        {t(
                          `signup.onboarding.personalization.categories.${category.title}`,
                        )}
                      </Text>
                    </VStack>
                  </GridItem>
                ))}
              </Grid>
            </Flex>
          </VStack>
          <VStack
            justify="space-between"
            align="center"
            gap={10}
            px={'20px'}
            py={'40px'}
            maxW={{ base: '100%', lg: 540 }}
            w="full"
            bg={'#F5F5F5'}>
            <VStack>
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="0px">
                {t('signup.onboarding.personalization.more_items_title')}
              </Text>
              <Text
                color={textColorDetails}
                fontSize="md"
                mb="20px"
                wordBreak="break-word"
                maxW={370}
                textAlign="center">
                {t('signup.onboarding.personalization.more_items_description')}
              </Text>
            </VStack>
            <Flex flex={1} flexDirection="column" gap={2}>
              {personalizedMoreItems?.map((item, index) => (
                <Flex
                  key={index}
                  align={{ base: 'start', md: 'center' }}
                  w="full"
                  py={3}
                  columnGap={2}
                  px={4}
                  borderRadius="2xl"
                  wrap={isMobile ? 'wrap' : 'nowrap'}
                  flexDirection={'row'}
                  bg={'white'}
                  border="1px solid"
                  borderColor="gray.200">
                  <Text fontSize="md" fontWeight="500" color="#465D55">
                    {item.title}
                  </Text>
                  <Text
                    color={'brand.400'}
                    fontSize="sm"
                    marginStart="0px"
                    fontWeight={'500'}>
                    {item.subtitle}
                  </Text>
                </Flex>
              ))}
            </Flex>
            <Button
              variant="h1cta"
              px={120}
              size="md"
              mt={20}
              onClick={nextClick}>
              {t('signup.onboarding.business_info.continue')}
            </Button>
          </VStack>
        </Flex>
      </Card>
    </Flex>
  );
};

export default PersonalizeTab;
