import { Flex, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputFieldPassword from 'components/fields/InputFieldPassword';
import { useTranslation } from 'react-i18next';
import useChangePassword from '../hooks/useChangePassword';
const PasswordFields = [
  {
    name: 'oldPassword',
    label: 'profile_settings.old_password',
    id: 'old_password',
  },
  {
    name: 'newPassword',
    label: 'profile_settings.new_password',
    id: 'new_password',
  },
  {
    name: 'newPasswordConfirmation',
    label: 'profile_settings.new_password_confirmation',
    id: 'new_password_confirmation',
  },
];
export default function PasswordChanger() {
  const { handleChange, loading, changePassword, inputValues } =
    useChangePassword();
  const { t } = useTranslation();
  return (
    <Card
      flexDirection="column"
      w="100%"
      p="0"
      ps={'20px'}
      pe={'30px'}
      maxW={{ base: '100%', md: '350px' }}
      borderRadius={{ base: 0, md: '30px' }}>
      <Flex flexDirection="column" justifyContent="center">
        <Flex p="31px 0 34px" flexDirection="column">
          <Text variant="cardTitle">
            {t('profile_settings.personal_settings')}
          </Text>
          <Text variant="cardSubTitle">
            {t('profile_settings.here_you_can_set_your_new_password')}
          </Text>
        </Flex>
        <Flex wrap="wrap" gap={5}>
          {PasswordFields.map((field, key) => (
            <InputFieldPassword
              w={{ base: '100%', md: '310px' }}
              h="50px"
              id={field.label}
              showButton
              key={key}
              label={t(field.label)}
              placeholder={t(field.label)}
              labelProps={{ ps: '10px', fontWeight: '500' }}
              name={field.name}
              value={inputValues[field.name] || ''}
              onChange={handleChange}
              variant="main"
              type="password"
              mb="0px"
            />
          ))}
        </Flex>
        <Button
          variant="h1cta"
          w="-webkit-fill-available"
          maxW="-webkit-fill-available"
          mt="33px"
          mb="47px"
          isLoading={loading}
          onClick={() => changePassword()}>
          {t('profile_settings.change_password')}
        </Button>
      </Flex>
    </Card>
  );
}
