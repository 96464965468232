import axios, { AxiosInstance } from 'axios';
import { clearCredentials } from 'contexts/redux/auth/authSlice';
import store from 'contexts/redux/store';
import { clearCookie } from 'utils/cookie.util';
export const createAxiosInstance = (baseURL: string): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  // Add an interceptor which detect 401 status code
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        try {
          store.dispatch(clearCredentials());
          clearCookie('auth');
        } catch (error) {
          console.error('Error while logging out', error);
        }
      }

      return Promise.reject(error);
    },
  );
  // Add an interceptor to attach the token to each request
  axiosInstance.interceptors.request.use((config) => {
    const _token = localStorage.getItem('token');
    console.debug('#Axios _token ->', _token);
    const token = _token ? JSON.parse(_token).tokens?.access?.token : null;
    if (token) {
      console.debug('#Axios -> token', token);
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  return axiosInstance;
};
