import { useToast } from '@chakra-ui/react';
import { setEventsDialog } from 'contexts/redux/dialog/dialogsSlice';
import { createMeeting } from 'contexts/redux/meeting/meetingSlice';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IEvent, IMeeting } from 'services/@types';
const requiredFields = ['name', 'location', 'startDate', 'endDate'];
interface UseCreateMeetingResult {
  errorList: string[];
  meeting: Partial<IMeeting>;
  handleChangeMeeting: (e: any) => void;
  handleCreateMeeting: () => void;
  handleChangeRemind: (e: any) => void;
  selectedRemind: string[];
  event: IEvent;
  handleOpenEventDialog: () => void;
}
export default function useCreateMeeting(): UseCreateMeetingResult {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [event, setEvent] = useState<IEvent>(null);
  const [meeting, setMeeting] = useState<Partial<IMeeting>>({
    startDate: new Date(),
    endDate: new Date(new Date().setHours(new Date().getHours() + 1)),
  });
  const [errorList, setErrorList] = useState<string[]>([]);
  const selectedRemind = useMemo(
    () => [`create_meeeting.remind_${meeting?.reminder}`] || [],
    [meeting?.reminder],
  );
  const handleChangeMeeting = useCallback(
    (e: any) => {
      if (errorList.includes(e.target.name)) {
        setErrorList((prev) => prev.filter((field) => field !== e.target.name));
      }
      if (e.target.name === 'startTime' || e.target.name === 'endTime') {
        const [hours, minutes] = e.target.value.split(':');
        const newDate = new Date(meeting.startDate);
        newDate.setHours(hours, minutes);
        setMeeting((prev) => ({
          ...prev,
          [e.target.name === 'startTime' ? 'startDate' : 'endDate']: newDate,
        }));
        return;
      }
      setMeeting((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    },
    [errorList, meeting.startDate],
  );
  const validateTask = useCallback(() => {
    let result = requiredFields.filter(
      (field) => !meeting || !meeting[field as keyof IMeeting],
    );

    if (result.length) {
      setErrorList(result);
      return true;
    }
    return false;
  }, [meeting]);

  const handleCreateMeeting = useCallback(async () => {
    const isError = validateTask();
    if (isError) {
      return;
    }

    let newMeetingData: any = { ...meeting };
    if (event) {
      newMeetingData.eventId = event.id;
    }
    const newMeeting = await dispatch(
      createMeeting(newMeetingData as IMeeting),
    );
    if (newMeeting?.error) {
      return;
    }
    toast({
      title: t('create_meeting.success'),
      variant: 'main',
    });
    setEvent(null);
    setMeeting(null);
    navigate('/');
  }, [meeting, validateTask, dispatch, navigate, t, toast, event]);
  const handleChangeRemind = useCallback(
    (e: string) => {
      const split = e.split('_');
      const event = {
        target: {
          value: split[split.length - 1],
          name: 'reminder',
        },
      };
      handleChangeMeeting(event);
    },
    [handleChangeMeeting],
  );
  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: any) => {
          if (errorList.includes('event')) {
            setErrorList((prev) => prev.filter((field) => field !== 'event'));
          }
          setEvent(e);
        },
      }),
    );
  }, [dispatch, errorList]);
  return {
    errorList,
    meeting,
    handleChangeMeeting,
    handleCreateMeeting,
    handleChangeRemind,
    selectedRemind,
    event,
    handleOpenEventDialog,
  };
}
