import {
  Flex,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { enGB, he } from 'date-fns/locale';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegCircle } from 'react-icons/fa';
import { FaCircleCheck } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { ITask } from 'services/@types';
import EventService from 'services/event.api';
import ImportantFlag from '../../../../assets/svg/priority/important-flag.svg';
interface TaskCardProps {
  task: ITask;
  onClick?: (Task: ITask) => void;
  [key: string]: any;
}
export default function TaskCard(props: TaskCardProps) {
  const { task, onClick, ...rest } = props;
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const data = useQuery({
    queryKey: [`getEvent-${task.event}`],
    queryFn: () => EventService.getEvent(task.event),
    enabled: !!task.event,
  });

  const month = useMemo(
    () =>
      task?.dateAndTime
        ? format(new Date(task?.dateAndTime), 'MMM', {
            locale: i18n.language.startsWith('he') ? he : enGB,
          })
        : null,
    [task?.dateAndTime, i18n.language],
  );
  const date = useMemo(
    () =>
      task?.dateAndTime ? format(new Date(task?.dateAndTime), 'dd') : null,
    [task?.dateAndTime],
  );

  return (
    <Flex
      pos="relative"
      w="100%"
      onClick={() => navigate(`/main/tasks/${task.id}`)}
      h="fit-content"
      {...rest}>
      <VStack
        width="100%"
        border="1px solid"
        borderColor={'#5FBF96'}
        borderRadius="16px"
        transition="all 0.3s ease"
        cursor="pointer"
        _hover={{ bg: '#F5F5F5' }}
        pb="20px"
        py={2}
        onClick={() => onClick && onClick(task)}>
        <Flex w="100%" gap={5} px={6} alignItems="center">
          <VStack textAlign="center" spacing={0} justifyContent="center">
            <Text variant="taskCardDate" fontSize="12px">
              {month}
            </Text>
            <Text variant="taskCardDate">{date}</Text>
          </VStack>
          <VStack alignItems="start" justifyContent="center" spacing={0}>
            <Text variant="eventCardTitle">{data?.data?.name}</Text>
            <Text variant="taskCardSubTitle">{task.name}</Text>
          </VStack>
          <Stack alignItems="end" flex="1">
            <HStack>
              {task?.priority === 'important' && (
                <Image src={ImportantFlag} alt="" w="17px" h="auto" />
              )}
              {task.status === 'completed' && (
                <Icon as={FaCircleCheck} color="brand.900" w="24px" h="24px" />
              )}

              {task.status === 'pending' && (
                <Icon as={FaRegCircle} color="brand.900" w="24px" h="24px" />
              )}
            </HStack>
          </Stack>
        </Flex>
      </VStack>
    </Flex>
  );
}
