// Chakra imports
import {
  Badge,
  Button,
  Flex,
  List,
  ListIcon,
  ListItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
// Assets
import { BsCircleFill } from 'react-icons/bs';
import { OnboardingNavigationHeader } from './OnboardingNavigationHeader';

export default function Pack(props: {
  title: string;
  desc: string;
  planKey: string;
  key: string;
  button: string;
  price: JSX.Element | string;
  annualPrice: JSX.Element | string;
  details: string;
  footer: string;
  onClick: () => void;
  discountedPrice: string;
  benefits: string[];
  highlighted?: boolean;
}) {
  const {
    key,
    title,
    planKey,
    desc,
    footer,
    annualPrice,
    discountedPrice,
    price,
    details,
    onClick,
    benefits,
    highlighted,
  } = props;
  const textColor = useColorModeValue('gray.800', 'brand.300');
  const { t } = useTranslation();
  console.log('key ->', key, 'planKey ->', planKey);
  return (
    <Card
      paddingBottom={0}
      paddingX={0}
      borderRadius="30px"
      transform={{
        sm: 'unset',
        md: 'unset',
        lg: highlighted ? 'translateY(-30px)' : 'unset',
      }}
      boxShadow={{ base: '2xl', md: '2xl', lg: '2xl', sm: 'none' }}
      pt={highlighted ? '30px' : '30px'}
      w={{ sm: '100%', md: '420px', lg: '220px' }}
      alignItems="center"
      justifyContent="flex-start"
      overflow="unset !important">
      <Badge
        display={highlighted ? 'block' : 'none'}
        // w="max-content"
        position="absolute"
        fontSize="sm"
        color="green.800"
        fontWeight={'500'}
        bg="brand.600"
        textTransform="unset"
        left="50%"
        paddingTop="6px"
        paddingBottom="6px"
        paddingLeft="26px"
        paddingRight="26px"
        borderRadius="2xl"
        transform="translate(-50%,-150%)">
        {t('signup.onboarding.plans.most_popular_plan')}
      </Badge>
      {planKey === 'business' && <OnboardingNavigationHeader hideBackButton />}

      <Text fontSize="30px" color={textColor} fontWeight="700">
        {title}
      </Text>
      <Text mb="30px" fontSize="md" color="gray.600" mx={2}>
        {desc}
      </Text>
      <Text
        fontSize="2xl"
        bg={'brand.300'}
        color="white"
        fontWeight={'normal'}
        borderRadius="2xl"
        w="100%"
        maxW={162}
        py={2}>
        ₪{price}
        {t('signup.onboarding.plans.price_per_month')}
      </Text>

      <Text fontSize="xs" color="gray.600" mt="6px" fontWeight={'500'}>
        ({details})
      </Text>
      <List justifyContent="flex-start" flex={1} mb={6} mt={1}>
        {benefits.map((benefit, index) => (
          <ListItem
            key={index}
            display="flex"
            textAlign="start"
            fontSize="md"
            fontWeight="500"
            color={textColor}
            alignItems="center"
            lineHeight="100%"
            mt="10px !important">
            <ListIcon
              w="6px"
              key={index}
              h="6px"
              as={BsCircleFill}
              my="auto"
              color={textColor}
            />
            {benefit}
          </ListItem>
        ))}{' '}
      </List>
      <Flex
        bg="#F5F5F5"
        w="100%"
        borderBottomLeftRadius={'30px'}
        borderBottomRightRadius={'30px'}
        flex={1}
        p={4}
        alignItems="center"
        gap={4}
        flexDirection="column"
        justifyContent={'center'}>
        <Text fontSize="md" color={textColor} maxW={130}>
          {footer}
        </Text>
        <Button
          variant="h1cta"
          fontSize="2xl"
          w="100%"
          maxW={162}
          minH={'50px'}
          onClick={onClick}
          fontWeight={'400'}
          size="lg">
          ₪{annualPrice}
        </Button>
        <Text
          fontSize="sm"
          color="gray.500"
          textDecorationLine={'line-through'}>
          ₪{discountedPrice}
        </Text>
      </Flex>
    </Card>
  );
}
