import { AxiosInstance, AxiosResponse } from 'axios';
import { PaginatedResults, ProductCategory } from './@types';
import { createAxiosInstance } from './axios.util';

class ProductCategoryService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createProductCategory(
    productRequest: Partial<ProductCategory>,
  ): Promise<ProductCategory> {
    const response: AxiosResponse<ProductCategory> = await this.api.post(
      '/productCategories',
      productRequest,
    );
    return response.data;
  }

  public async getProductCategory(
    productCategoryId: string,
  ): Promise<ProductCategory> {
    const response: AxiosResponse<ProductCategory> = await this.api.get(
      `/productCategories/${productCategoryId}`,
    );
    return response.data;
  }
  public async getProductCategories(
    businessID: string,
  ): Promise<PaginatedResults<ProductCategory>> {
    const response: AxiosResponse<PaginatedResults<ProductCategory>> =
      await this.api.get(`/productCategories?businessID=${businessID}`);
    return response.data;
  }

  public async updateProductCategory(
    productId: string,
    updates: Partial<ProductCategory>,
  ): Promise<ProductCategory> {
    const response: AxiosResponse<ProductCategory> = await this.api.patch(
      `/productCategories/${productId}`,
      updates,
    );
    return response.data;
  }

  public async deleteProductCategory(productId: string): Promise<void> {
    await this.api.delete(`/productCategories/${productId}`);
  }
}

const _productCategoryService = new ProductCategoryService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _productCategoryService;
