import { useCallback, useEffect, useState } from 'react';
import { Business, ContactPerson, Supplier } from 'services/@types';
import _businessService from 'services/business.api';

export const useBusinessContact = (
  supplier?: Supplier | Business,
): { businessContact?: ContactPerson; business?: Business } => {
  const [businessContact, setBusinessContact] = useState<ContactPerson>();
  const [business, setBusiness] = useState<Business>();

  const handleLookupBusiness = useCallback(
    async (businessId: string) => {
      const business = await _businessService.getBusiness(businessId);
      setBusiness(business);
    },
    [setBusiness],
  );

  useEffect(() => {
    setBusiness(null);
    console.log('supplier ->', supplier);
    if (supplier && 'contacts' in supplier) {
      if (supplier?.contacts?.length > 0) {
        setBusinessContact(supplier.contacts[0]);
      }

      if (supplier.businessID) {
        // get user from server...
        handleLookupBusiness(supplier.businessID);
      }
    }
  }, [supplier, setBusiness, handleLookupBusiness]);

  return { businessContact, business };
};
