import { AxiosError } from 'axios';
import { closeSplashDialog, showSplashDialog } from 'contexts/redux/dialog/dialogsSlice';
import useQueryParam from 'hooks/useQueryParam';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Invite, Supplier, User } from 'services/@types';
import _inviteService from 'services/invite.api';
import { GAEventAction, GAEventCategory, GAEventLabel } from 'types/ga-enums';
import { sendEvent } from 'utils/analytics.util';

export const useInvite = () => {
    const [invite, setInvite] = useState<Invite | null>(null);
    const [senderUser, setSenderUser] = useState<User>(null);
    const [supplier, setSupplier] = useState<Supplier>(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const token = useQueryParam('token');
    const dispatch = useDispatch<any>();
    const [, setSearchParams] = useSearchParams();

    const fetchInvite = useCallback(async () => {
        try {
            dispatch(showSplashDialog({ title: 'invite_seat.loading_invite' }));
            const { invite, sender, supplier, business } = await _inviteService.getInviteByToken(token)
            console.log('#Seat Invite ->', invite, business, sender);
            setInvite(invite);
            setSupplier(supplier);
            setSenderUser(sender);
            sendEvent(GAEventCategory.SupplierInvite, GAEventAction.SupplierInviteOpen, GAEventLabel.SupplierInviteOpen);
            localStorage.setItem('inviteSupplierToken', token);
        } catch (e) {
            setError((e as AxiosError).message);
        }
        setSearchParams({});


        setTimeout(() => {
            dispatch(closeSplashDialog());
            setTimeout(() => setLoading(false), 300);
        }, 1000);

    }, [dispatch, setSearchParams, token])

    useEffect(() => {
        if (!token || invite) return;
        fetchInvite();
    }, [fetchInvite, invite, token])


    return { invite, loading, error, senderUser, supplier };
}