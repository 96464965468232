import {
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import EventCard from 'components/eventsDialog/eventCard';
import { fetchUser } from 'contexts/redux/user/userSlice';
import {
  AppDialogProps,
  closeRequestQuoteDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { getEvent } from 'contexts/redux/event/eventSlice';
import { getQuotes } from 'contexts/redux/quote/quoteSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AuthImage from 'components/authImage/AuthImage';
import { MdCheck, MdClear } from 'react-icons/md';

export default function RequestQuoteDialog() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const { requestQuoteDialog, quotes, sender, event } = useSelector(
    (state: RootState) => ({
      requestQuoteDialog: state.dialogs.requestQuoteDialog,
      quotes: state.quote.qoutes,
      sender: state.users.user,
      event: state.event.event,
    }),
  );
  const dialog = requestQuoteDialog as AppDialogProps;
  const quote = useMemo(() => quotes[0], [quotes]);
  const handleClose = useCallback(() => {
    dispatch(closeRequestQuoteDialog());
    localStorage.removeItem('requestQuoteToken');
  }, [dispatch]);
  useEffect(() => {
    if (!!dialog) {
      const requestQuoteToken = localStorage.getItem('requestQuoteToken');
      if (!requestQuoteToken) {
        console.error('Request Quote Token not found');
      }
      if (requestQuoteToken) {
        const fetchQuote = async () => {
          const res = await dispatch(getQuotes({ requestQuoteToken }));
          if (res.payload?.results.length > 0) {
            const quote = res.payload.results[0];
            await dispatch(fetchUser(quote.senderId));
            await dispatch(getEvent(quote.eventId));
          }
        };
        fetchQuote();
      }
    }
  }, [dialog, dispatch]);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px" overflow="hidden">
        <ModalHeader>{t('dialogs.request_quote.title')}</ModalHeader>
        <ModalBody p={0}>
          <VStack w="100%" gap={2} alignItems="start" p={5}>
            <Text variant="cardSubTitle">From:</Text>
            {sender && (
              <HStack>
                <AuthImage
                  borderRadius={'50%'}
                  bg="red"
                  m={2}
                  h={50}
                  w={50}
                  path={
                    sender?.media?.find(
                      (m) => m.type === 'profileImage' || m.type === 'avatar',
                    )?.url
                  }
                />
                <VStack alignItems="start">
                  <Text variant="eventCardTitle">
                    {sender?.firstName} {sender?.lastName}
                  </Text>
                  <Text variant="eventCardSubTitle">{sender?.email}</Text>
                </VStack>
              </HStack>
            )}
            <Text variant="cardSubTitle">Event:</Text>
            {event && <EventCard event={event} />}
            <Text variant="cardSubTitle">Message:</Text>
            <Text variant="serviceTitle">{quote?.freeText}</Text>
          </VStack>
          <HStack
            justifyContent="space-between"
            w="100%"
            gap={4}
            p={5}
            bg={'orange.400'}>
            <Text variant="cardSubTitle" color="white">
              Approve this quote?
            </Text>
            <HStack gap={5}>
              <IconButton
                cursor="pointer"
                aria-label="approve-merge"
                color="green.300"
                p={1}
                borderRadius={'xl'}
                size="sm"
                onClick={dialog?.onConfirm}
                as={MdCheck}
              />
              <IconButton
                cursor="pointer"
                size="sm"
                borderRadius={'xl'}
                aria-label="clear"
                color={'red.400'}
                as={MdClear}
                p={1}
                onClick={handleClose}
              />
            </HStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
