// src/features/businessTags/userSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Business, BusinessTag, PaginatedResults } from 'services/@types';
import BusinessTagsService from 'services/businessTag.api';
import { updateMyUser } from '../auth/authSlice';
import { closeSplashDialog } from '../dialog/dialogsSlice';
import store from '../store';
export interface BusinessState {
  businessTags: BusinessTag[];
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: BusinessState = {
  businessTags: [],
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchBusinessTags = createAsyncThunk(
  'businessTags/fetchBusinessTags',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await BusinessTagsService.getBusinessTags(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchBusinessTag = createAsyncThunk(
  'businessTags/fetchBusinessTag',
  async (tagId: string, { rejectWithValue }) => {
    try {
      const response = await BusinessTagsService.getBusinessTag(tagId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createBusinessTag = createAsyncThunk(
  'businessTags/createBusinessTag',
  async (businessTag: BusinessTag, { rejectWithValue }) => {
    try {
      const newBusiness = await BusinessTagsService.createBusinessTag(
        businessTag,
      );
      console.log('#Redux create business ->', newBusiness);
      await store.dispatch(
        updateMyUser({ updates: { businessID: newBusiness.id } }),
      );
      store.dispatch(closeSplashDialog());

      return newBusiness;
    } catch (error) {
      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);

export const updateBusinessTag = createAsyncThunk(
  'businessTags/updateBusinessTag',
  async (
    { userId, updates }: { userId: string; updates: Partial<Business> },
    { rejectWithValue },
  ) => {
    try {
      const response = await BusinessTagsService.updateBusinessTag(
        userId,
        updates,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteBusinessTag = createAsyncThunk(
  'businessTags/deleteBusinessTag',
  async (tagid: string, { rejectWithValue }) => {
    try {
      await BusinessTagsService.deleteBusinessTag(tagid);
      return tagid;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
  },
  extraReducers: (builder) => {
    builder
      // Fetch all businesses
      .addCase(fetchBusinessTags.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchBusinessTags.fulfilled,
        (state, action: PayloadAction<PaginatedResults<BusinessTag>>) => {
          state.businessTags = action.payload.results;
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      .addCase(
        fetchBusinessTags.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      // Create a tag
      .addCase(createBusinessTag.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createBusinessTag.fulfilled,
        (state, action: PayloadAction<BusinessTag>) => {
          state.businessTags.push(action.payload);
          state.loading = false;
        },
      )
      .addCase(
        createBusinessTag.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      // Update a user
      .addCase(updateBusinessTag.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateBusinessTag.fulfilled,
        (state, action: PayloadAction<BusinessTag>) => {
          const index = state.businessTags.findIndex(
            (tag) => tag.id === action.payload.id,
          );
          if (index !== -1) {
            state.businessTags[index] = action.payload;
          }
          state.loading = false;
        },
      )
      .addCase(
        updateBusinessTag.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      // Delete a user
      .addCase(deleteBusinessTag.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteBusinessTag.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.businessTags = state.businessTags.filter(
            (tag) => tag.id !== action.payload,
          );
          state.loading = false;
        },
      )
      .addCase(
        deleteBusinessTag.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      );
  },
});

export default userSlice.reducer;
