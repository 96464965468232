import { Button, HStack, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { setAddAlbumDialog } from 'contexts/redux/dialog/dialogsSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
export const NewAlbumBanner = () => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  return (
    <Card
      w="100%"
      borderRadius={{ base: 0, md: '3xl' }}
      px={'29px'}
      py={'18px'}>
      <HStack justifyContent="space-between">
        <Text fontSize={{ sm: 'lg', md: '2xl' }} fontWeight={'bold'}>
          {t('gallery.new_album.title')}
        </Text>

        <Button
          variant="h1cta"
          px={10}
          onClick={() => {
            dispatch(setAddAlbumDialog({ item: {} }));
          }}>
          {t('gallery.new_album.create')}
        </Button>
      </HStack>
    </Card>
  );
};
