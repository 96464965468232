import { mode } from '@chakra-ui/theme-tools';
export const radioStyles = {
  components: {
    Radio: {
      baseStyle: {
        borderRadius: '10px',
        lineHeight: '100%',
        padding: '7px',
        paddingLeft: '12px',
        paddingRight: '12px',
      },
      variants: {
        outline: () => ({
          borderRadius: '16px',
        }),
        brand: (props: any) => ({
          control: {
            mr: 4,
            _checked: {
              color: 'brand.800',
              borderColor: 'brand.900',
              bg: mode('brand.900', 'brand.900')(props),
            },
          },
        }),
      },
    },
  },
};
