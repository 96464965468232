import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'contexts/redux/store';
import { Supplier } from 'services/@types';

export const useFilteredSuppliers: () => {
  filteredSuppliers: Supplier[];
  setFilteringCategories: React.Dispatch<React.SetStateAction<string[]>>;
  filteringCategories: string[];
  suppliers: Supplier[];
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
} = () => {
  const [filteredSuppliers, setFilteredSuppliers] = useState<Supplier[]>([]);
  const [filteringCategories, setFilteringCategories] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { suppliers } = useSelector((state: RootState) => state.suppliers);
  useEffect(() => {
    const filteredSuppliers = suppliers.filter((supplier) => {
      if (filteringCategories.length > 0) {
        if (searchQuery !== '') {
          return (
            filteringCategories.some((category) =>
              supplier.category.includes(category),
            ) && supplier.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }
        return filteringCategories.some((category) =>
          supplier.category.includes(category),
        );
      }

      return supplier.name.toLowerCase().includes(searchQuery.toLowerCase());
    });

    setFilteredSuppliers(filteredSuppliers);
  }, [filteringCategories, suppliers, searchQuery]);

  return {
    filteredSuppliers,
    setFilteringCategories,
    filteringCategories,
    suppliers,
    setSearchQuery,
  };
};
