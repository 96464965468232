import { Flex } from '@chakra-ui/react';
import { ColleagueRequests } from './colleagueRequests';
import DeletingCard from './deletingCard';
import TeamContainer from './team';

export default function TheOffice(props: { [x: string]: any }) {
  return (
    <Flex {...props} direction={{ base: 'column', xl: 'row' }}>
      <Flex wrap="wrap" gap={5}>
        <TeamContainer />
        <ColleagueRequests />
        <DeletingCard />
      </Flex>
    </Flex>
  );
}
