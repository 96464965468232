import { MutableRefObject, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToTopProps {
  wrapper: MutableRefObject<HTMLDivElement | null>;
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ wrapper }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (wrapper.current) {
      console.log('ScrollToTop -> useEffect -> pathname', pathname);
      wrapper.current.scrollTo(0, 0);
    }
  }, [pathname, wrapper]);

  return null;
};

export default ScrollToTop;
