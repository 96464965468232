import {
  closeSupplierFoundDialog,
  setSupplierFoundDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Business } from 'services/@types';
import _businessService from 'services/business.api';
export const useFindSupplier = (
  onSupplierAction: (supplier: Business) => void,
) => {
  const dispatch = useDispatch<any>();
  const [existingBusiness, setExistingBusiness] = useState<Business | null>(
    null,
  );
  const [loadingExistingSupplier, setLoading] = useState(false);

  const findBusinessBy = useCallback(async (email: string, phone: string) => {
    // find supplier by email or phone
    setLoading(true);
    let res: any;
    if (email) {
      res = await _businessService.getBusinesses({ email });
    } else {
      res = await _businessService.getBusinesses({ phone });
    }

    if (res.results.length) {
      dispatch(
        setSupplierFoundDialog({
          item: res.results[0],
          title: 'Supplier found',
          onConfirm: () => {
            dispatch(closeSupplierFoundDialog());
            onSupplierAction(res.results[0] as Business);
          },
          onClose: () => {
            dispatch(closeSupplierFoundDialog());
          },
        }),
      );
    }
    setLoading(false);
  }, [dispatch, onSupplierAction]);

  return {
    existingBusiness,
    setExistingBusiness,
    findBusinessBy,
    loadingExistingSupplier,
  };
};
