import { useToast } from '@chakra-ui/react';
import {
  createPackage,
  updatePackage,
  updateProduct,
} from 'contexts/redux/product/productSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from 'services/@types';

export const useCreatePackage = (
  editableProduct: Partial<Product>,
  handleClose: () => void,
  isOpen: boolean,
) => {
  const dispatch = useDispatch<any>();
  const [selectedProducts, setSelectedProducts] = useState<
    { value: string; label: string }[]
  >(
    editableProduct?.subItems?.map?.((sp) => ({
      value: sp,
      label: sp,
    })) || [],
  );
  const [newProducts, setNewProducts] = useState<
    { value: string; label: string; price: number }[]
  >([]);
  const [showNewProductSection, setShowNewProductSection] = useState(false);
  const [newProductInputValue, setNewProductInputValue] = useState('');
  const [productProps, setProductProps] = useState<Partial<Product>>({
    ...editableProduct,
  });
  const [errorLabels, setErrorLabels] = useState<{
    name: string;
    price: string;
    subItems: string;
  }>({ name: '', price: '', subItems: '' });
  const [displayingOptions, setDisplayingOptions] = useState([]);
  const { products } = useSelector((state: RootState) => state.product);
  const { loading } = useSelector((state: RootState) => state.product);
  const { t } = useTranslation();
  const toast = useToast();
  const validate = useCallback(() => {
    if (!productProps.name) {
      setErrorLabels((prev) => ({
        ...prev,
        name: t('products.errors.name'),
      }));
      return false;
    }
    if (selectedProducts.length === 0 && newProducts.length === 0) {
      setErrorLabels((prev) => ({
        ...prev,
        subItems: t('products.errors.subItems'),
      }));
      return false;
    }

    return true;
  }, [newProducts.length, productProps.name, selectedProducts.length, t]);
  const handleSave = useCallback(async () => {
    if (!validate()) {
      return;
    }
    if (editableProduct) {
      if (productProps.subItems) {
        dispatch(
          updatePackage({
            productId: productProps.id,
            updates: productProps,
            existingItems: selectedProducts.map((sp) => ({
              id: sp.value,
              name: sp.label,
            })),
            newSubItems: newProducts.map((np) => ({
              name: np.label,
              price: np.price,
            })),
          }),
        );
        toast({
          variant: 'main',
          title: t('products.toast.package_updated'),
        });
      } else {
        dispatch(
          updateProduct({
            productId: productProps.id,
            updates: productProps,
          }),
        );
        toast({
          variant: 'main',
          title: t('products.toast.product_updated'),
        });
      }
    } else {
      dispatch(
        createPackage({
          product: productProps,
          existingItems: selectedProducts.map((sp) => ({
            id: sp.value,
            name: sp.label,
          })),
          newSubItems: newProducts.map((np) => ({
            name: np.label,
            price: np.price,
          })),
        }),
      );
      setNewProducts([]);
      setSelectedProducts([]);

      toast({
        variant: 'main',
        title: t('products.toast.package_added'),
      });
    }
    handleClose();
  }, [
    dispatch,
    editableProduct,
    handleClose,
    newProducts,
    productProps,
    selectedProducts,
    t,
    toast,
    validate,
  ]);

  useEffect(() => {
    let filteredProducts = products
      .filter((p) => !p.subItems || p.subItems.length === 0)
      .filter((p) => !selectedProducts.find((sp) => sp.value === p.id));
    filteredProducts = filteredProducts.filter(
      (p) =>
        p.name.toLowerCase().includes(newProductInputValue.toLowerCase()) &&
        p.name !== newProductInputValue,
    );
    setDisplayingOptions(
      filteredProducts.map((product) => ({
        value: product.id,
        label: product.name,
      })),
    );
    console.log('filtered products ->', filteredProducts);
  }, [newProductInputValue, products, selectedProducts]);
  const handleOnSelected = useCallback(
    (value) => {
      setSelectedProducts((prev) => [
        ...prev,
        {
          value,
          label: products.find((prod) => prod.id === value)?.name!,
        },
      ]);
      setNewProductInputValue('');
    },
    [products],
  );
  const handleRemoveProduct = useCallback((product: { value: string }) => {
    setSelectedProducts((prev) =>
      prev.filter((prod) => prod.value !== product.value),
    );
    setNewProducts((prev) =>
      prev.filter((prod) => prod.value !== product.value),
    );
  }, []);

  const handleOnSaveNewProduct = useCallback((product: Partial<Product>) => {
    setNewProducts((prev) => [
      ...prev,
      { value: product.name, label: product.name, price: product.price },
    ]);
    setShowNewProductSection(false);
    setNewProductInputValue('');
  }, []);

  useEffect(() => {
    console.log('add product modal ->', editableProduct);
    if (editableProduct) {
      setProductProps(editableProduct);
    } else {
      setProductProps({});
    }
  }, [editableProduct, isOpen, handleClose, setProductProps]);

  useEffect(() => {
    if (!newProductInputValue || newProductInputValue === '') {
      setShowNewProductSection(false);
    }
  }, [newProductInputValue, setShowNewProductSection]);

  const resetItems = useCallback(() => {
    setSelectedProducts([]);
    setNewProducts([]);
    setProductProps({});
  }, []);
  return {
    selectedProducts,
    setSelectedProducts,
    setNewProducts,
    newProducts,
    handleOnSaveNewProduct,
    showNewProductSection,
    setShowNewProductSection,
    newProductInputValue,
    setNewProductInputValue,
    productProps,
    setProductProps,
    displayingOptions,
    setDisplayingOptions,
    handleSave,
    handleRemoveProduct,
    loading,
    errorLabels,
    resetItems,
    products,
    handleOnSelected,
    setErrorLabels,
    validate,
  };
};
