import { NavLink } from 'react-router-dom';
// Chakra imports
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Image,
  Show,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
// Custom components
import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/variants/Default';
// Assets
import Illustration from 'assets/img/auth/sing_in.jpeg';
import { useTranslation } from 'react-i18next';
import { FcGoogle } from 'react-icons/fc';
import _authService from 'services/auth.api';
import Logo from '../../../assets/svg/brilliant-logo.svg';
import InputField from '../../../components/fields/InputField';
import InputFieldPassword from '../../../components/fields/InputFieldPassword';
import { useSignIn } from './hooks/useSignIn';

function SignIn() {
  const { t } = useTranslation();
  // Chakra color mode
  const googleHover = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.300' },
  );
  const googleActive = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.200' },
  );

  const {
    handleChange,
    error,
    errorParam,
    loading,
    signIn,
    signInWithGoogle,
    inputValues,
  } = useSignIn();

  return (
    <DefaultAuth image={Illustration}>
      <Flex
        w={{ base: '100%', lg: '50%' }}
        h="100%"
        justifyContent="center"
        flexDirection="column"
        gap={10}
        align={{ base: 'center' }}
        // px={{ base: '20px', sm: '20px', md: '60px', lg: '120px', xl: '150px' }}
      >
        <Spacer />
        <VStack
          gap={0}
          align="flex-start"
          flex={1}
          maxW={320}
          justify="end"
          w="100%">
          <Show above="md">
            <Image
              src={Logo}
              mb={2}
              alt="logo"
              w="217px"
              onClick={() => {
                _authService.signOutWithGoogle();
              }}
            />
          </Show>
          <Text fontSize={'2xl'} fontWeight={'bold'}>
            {t('signin.title')}
          </Text>
          <Text color="primary.100" fontWeight="400" fontSize="md">
            {t('signin.description')}
          </Text>
        </VStack>
        <VStack
          w="100%"
          flex={0.5}
          maxW={330}
          align="start"
          justify={'end'}
          gap={1}>
          <Button
            variant="google"
            w="100%"
            onClick={signInWithGoogle}
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            {t('signin.sign_in_with_google')}
          </Button>
          <HStack w="100%" py={6}>
            <HSeparator bg="#00000066" flex={1} />
            <Text color="#00000066" px={'10px'} mx="24px">
              {t('signin.or')}
            </Text>
            <HSeparator bg="#00000066" flex={1} />
          </HStack>
          <FormControl
            gap={{ base: 1, lg: 4 }}
            display="flex"
            flexDirection={'column'}>
            <InputField
              isRequired={true}
              variant="auth"
              bg={'transparent'}
              label={t('signin.email') + '*'}
              type="email"
              placeholder="johndoe@asder.com"
              name="email"
              onChange={handleChange}
              value={inputValues?.email || ''}
            />

            <InputFieldPassword
              id="password-input"
              isRequired={true}
              label={t('signin.password') + '*'}
              w="100%"
              bg={'transparent'}
              type="password"
              showButton
              placeholder={t('signin.password_placeholder')}
              variant="auth"
              name="password"
              onChange={handleChange}
              value={inputValues?.password || ''}
            />

            <Flex
              justifyContent="space-between"
              mb={'30px'}
              align="center"
              mt={'12px'}>
              <FormControl display="flex" alignItems="center" flex={1}>
                <Checkbox id="remember-login" colorScheme="green" me="10px" />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  letterSpacing="-0.02rem"
                  fontWeight="normal"
                  color="primary.100"
                  fontSize="xs">
                  {t('signin.keep_signed_in')}
                </FormLabel>
              </FormControl>
              <NavLink to="#">
                <Text color="#AA915D" fontSize="xs" fontWeight="500" flex={1}>
                  {t('signin.forgot')}
                </Text>
              </NavLink>
            </Flex>
            <Button
              isLoading={loading}
              variant="h1cta"
              w="100%"
              onClick={() => signIn()}>
              {t('signin.signin')}
            </Button>
          </FormControl>
          <Text color="red.500" fontSize="sm" textAlign="center" w="100%">
            {t(error || errorParam)}
          </Text>

          <Text fontSize="xs" color="primary.100">
            {t('signin.not_registered')}
            <NavLink to="/auth/get-started">
              <Text
                color="#aa915d"
                as="span"
                ms="5px"
                fontWeight="700"
                textDecoration="underline">
                {t('signin.create_an_account')}
              </Text>
            </NavLink>
          </Text>
        </VStack>
        <Spacer />
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
