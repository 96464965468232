// Chakra imports
import {
  Flex,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Progress,
  Text,
  useColorModeValue,
  Icon,
  TextProps,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { MdCheckCircleOutline } from 'react-icons/md';
// Custom components

const passwordValidations = [
  {
    name: 'characters_8',
    message: 'At least 8 characters',
    pattern: /.{8,}/,
  },
  {
    name: 'capital_1',
    message: 'At least one capital letter',
    pattern: /[A-Z]/,
  },
  {
    name: 'lowercase_1',
    message: 'At least one lowercase letter',
    pattern: /[a-z]/,
  },
  {
    name: 'number_1',
    message: 'At least one number',
    pattern: /\d/,
  },
  {
    name: 'special_character',
    message: 'At least one special character',
    pattern: /[^A-Za-z0-9]/,
  },
];

export default function Default(props: {
  id?: string;
  label?: string;
  extra?: JSX.Element;
  placeholder?: string;
  labelProps?: TextProps;
  type?: string;
  showButton?: boolean;
  showValidations?: boolean;
  [x: string]: any;
}) {
  const {
    id,
    label,
    extra,
    placeholder,
    type,
    w,
    mb,
    showValidations,
    showButton,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('primary.100', 'white');
  const bgInput = useColorModeValue('white', 'brand.300');
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [_value, setValue] = useState('');

  const handleChange = useCallback(
    (e) => {
      rest.onChange && rest.onChange(e);
      passwordValidations.map((validation) => {
        if (!validation.pattern.test(e.target.value)) {
          console.log(validation.message);
        }
        return true;
      });
      setValue(e.target.value);
    },
    [rest],
  );
  return (
    <Flex direction="column" mb={mb ? mb : '0px'} w={w}>
      <FormLabel
        display="flex"
        htmlFor={id}
        fontSize="sm"
        color={textColorPrimary}
        fontWeight="medium"
        _hover={{ cursor: 'pointer' }}>
        {label}
        <Text fontSize="sm" fontWeight="400" ms="2px">
          {extra}
        </Text>
      </FormLabel>
      <InputGroup size="md">
        {i18n.dir() === 'rtl' && (
          <InputLeftElement
            width="4.5rem"
            h="50px"
            sx={{ paddingEnd: '4px' }}
            // bg="blue"
            display={showButton ? 'flex' : 'none'}>
            <HStack align="center" alignSelf={'center'}>
              <IconButton
                isRound
                aria-label="password-check"
                icon={<Icon as={MdCheckCircleOutline} />}
                colorScheme={'green'}
                size={'xs'}
                display={
                  passwordValidations.every((validation) =>
                    validation.pattern.test(_value),
                  )
                    ? 'flex'
                    : 'none'
                }
              />
              <IconButton
                icon={
                  <Icon as={!show ? BsEye : BsEyeSlash} w="18px" h="18px" />
                }
                size={'xs'}
                onClick={handleClick}
                aria-label={'show-passowrd'}
              />
            </HStack>
          </InputLeftElement>
        )}
        <Input
          type={show ? 'text' : type}
          id={id}
          color="primary.100"
          fontWeight="500"
          bg={bgInput}
          variant="auth"
          _active={{
            borderColor: 'brand.600',
          }}
          _focus={{
            borderColor: 'brand.600',
          }}
          placeholder={placeholder}
          _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
          h="50px"
          maxH="50px"
          {...rest} // order is important #1
          onChange={handleChange} // order is important #2
        />
        {i18n.dir() === 'ltr' && (
          <InputRightElement
            width="4.5rem"
            display={showButton ? 'flex' : 'none'}>
            <HStack>
              <IconButton
                aria-label="password-check"
                icon={<Icon as={MdCheckCircleOutline} />}
                size={'xs'}
                mt={2}
                p={0}
                fontSize={16}
                borderRadius="full"
                colorScheme={'green'}
                display={
                  passwordValidations.every((validation) =>
                    validation.pattern.test(_value),
                  )
                    ? 'flex'
                    : 'none'
                }
              />
              <IconButton
                bg="transparent"
                icon={
                  <Icon as={!show ? BsEye : BsEyeSlash} w="18px" h="18px" />
                }
                size={'xs'}
                top={1}
                onClick={handleClick}
                aria-label={'show-passowrd'}
              />
            </HStack>
          </InputRightElement>
        )}
      </InputGroup>
      <Progress
        w={'100%'}
        isAnimated
        display={showValidations ? 'flex' : 'none'}
        animation={'1s ease'}
        value={(_value.length / 8) * 100}
        colorScheme={
          passwordValidations.every((validation) =>
            validation.pattern.test(_value),
          )
            ? 'green'
            : 'yellow'
        }
        mt={2}
        h={'2px'}
        borderRadius="full"></Progress>
      <Flex
        gap={2}
        mt={2}
        wrap="wrap"
        display={showValidations ? 'flex' : 'none'}>
        {passwordValidations
          .sort(
            (a, b) =>
              (b.pattern.test(_value) ? 1 : -1) -
              (a.pattern.test(_value) ? 1 : -1),
          )
          .map((validation) => (
            <Text
              fontSize="xs"
              //   color="red.600"
              color={validation.pattern.test(_value) ? 'green.600' : 'red.600'}
              key={validation.name}
              display={_value.length > 5 ? 'flex' : 'none'}>
              {t(`signup.password_requirements.${validation.name}`)}
            </Text>
          ))}
      </Flex>
    </Flex>
  );
}
