import { RootState } from 'contexts/redux/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Product } from 'services/@types';

export const useFilteredProducts: () => {
  filteredProducts: Product[];
  setFilteringCategories: React.Dispatch<React.SetStateAction<string[]>>;
  filteringCategories: string[];
  products: Product[];
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
} = () => {
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [filteringCategories, setFilteringCategories] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { products } = useSelector((state: RootState) => state.product);
  useEffect(() => {
    const filteredProducts = products.filter((product) => {
      if (filteringCategories.length > 0) {
        if (!filteringCategories.includes(product.category || '')) {
          return false;
        }
      }

      return product.name.toLowerCase().includes(searchQuery.toLowerCase());
    });

    setFilteredProducts(filteredProducts);
  }, [filteringCategories, products, searchQuery]);

  return {
    setSearchQuery,
    filteredProducts,
    setFilteringCategories,
    filteringCategories,
    products,
  };
};
