import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import _inviteService from 'services/invite.api';

interface Invite {
  id: string;
  type: 'calendar' | 'project' | 'team' | 'event';
  status: 'pending' | 'accepted' | 'rejected';
  senderID: string;
  recipientID: string;
  // Add other invite properties as needed
}

interface UseGetInviteOptions {
  enabled?: boolean;
}

const getInviteQueryKey = (inviteId: string): QueryKey => ['invite', inviteId];

const getInviteQueryOptions = (inviteId: string) => ({
  queryKey: getInviteQueryKey(inviteId),
  queryFn: () => _inviteService.getInvite(inviteId),
  staleTime: 5 * 60 * 1000, // 5 minutes
  gcTime: 15 * 60 * 1000, // 15 minutes
  retry: (failureCount: number, error: any) => {
    return failureCount < 3 && error.response?.status !== 404;
  },
});

const useGetInvite = (
  inviteId: string,
  options: UseGetInviteOptions = {},
): UseQueryResult<Invite, Error> => {
  return useQuery({
    ...getInviteQueryOptions(inviteId),
    enabled: options.enabled !== false && !!inviteId,
  });
};

useGetInvite.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
  inviteId: string,
) => {
  await queryClient.prefetchQuery(getInviteQueryOptions(inviteId));
};

useGetInvite.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  inviteId: string,
) => {
  queryClient.invalidateQueries({ queryKey: getInviteQueryKey(inviteId) });
};

export default useGetInvite;
