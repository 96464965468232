import { Flex, Text } from '@chakra-ui/layout';
import { Button, Hide } from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import Dropzone from 'components/dropzone';
import EditBtn from 'components/editBtn';
import { useTranslation } from 'react-i18next';
import { UrlItem } from 'services/@types';
interface ProfileHeaderProps {
  isSaveBtnVisible: boolean;
  loading: boolean;
  updateUser: () => void;
  setLoading: (loading: boolean) => void;
  userId: string;
  userMedia: any;
  fullName: string;
  handleChange: (event: any) => void;
  setIsSaveBtnVisible?: (isSaveBtnVisible: boolean) => void;
}
export default function ProfileHeader(props: ProfileHeaderProps) {
  const {
    isSaveBtnVisible,
    loading,
    updateUser,
    setLoading,
    userId,
    userMedia,
    handleChange,
    setIsSaveBtnVisible,
    fullName,
  } = props;
  const { t } = useTranslation();
  return (
    <Flex p="31px 6px 0" justifyContent="space-between">
      <Flex gap={4}>
        <Flex justify="center" align="center" role="group">
          {isSaveBtnVisible && (
            <Dropzone
              disabled={
                !isSaveBtnVisible &&
                !!userMedia?.find((i: any) => i.type === 'avatar')?.url
              }
              uploadKey="avatar"
              target="user"
              targetId={userId}
              access="public"
              dropzoneOptions={{
                accept: 'image/*',
                multiple: false,
              }}
              uploadLabel={t('profile_settings.add_avatar')}
              currentImage={
                userMedia?.find((i: UrlItem) => i.type === 'avatar')?.url
              }
              onUploadCompleted={(urlItem: UrlItem) => {
                const newMedia = [
                  ...userMedia.filter((i: UrlItem) => i.type !== 'avatar'),
                  urlItem,
                ];
                handleChange({
                  target: {
                    name: 'media',
                    value: newMedia,
                  },
                } as any);
                setLoading(false);
                setIsSaveBtnVisible(true);
              }}
              onUploadError={() => setLoading(false)}
              onStartUpload={() => {
                if (!isSaveBtnVisible) {
                  setIsSaveBtnVisible(true);
                }
                setLoading(true);
              }}
              h={75}
              w={75}
              minW={75}
              minH={75}
              borderRadius="100%"
              currentImageProps={{ borderRadius: '100%', h: 75, w: 75 }}
            />
          )}
          {!isSaveBtnVisible && (
            <AuthImage
              isAvatar
              path={userMedia?.find((i: UrlItem) => i.type === 'avatar')?.url}
              name={fullName}
              h={75}
              w={75}
              minW={75}
              minH={75}
              borderRadius="100%"
              id={userId}
            />
          )}
        </Flex>
        <Flex flexDirection="column" justifyContent="center">
          <Text variant="cardTitle">
            {t('profile_settings.personal_settings')}
          </Text>
          <Text variant="cardSubTitle">
            {t('profile_settings.here_you_can_change_your_account_information')}
          </Text>
        </Flex>
      </Flex>
      <Flex justify="end">
        <Hide below="md">
          {isSaveBtnVisible && (
            <Button
              variant="h1cta"
              onClick={() => {
                updateUser();
              }}
              isLoading={loading}
              maxW="150px">
              {t('profile_settings.save_changes')}
            </Button>
          )}
        </Hide>

        {!isSaveBtnVisible && (
          <EditBtn onClick={() => setIsSaveBtnVisible(true)} />
        )}
      </Flex>
    </Flex>
  );
}
