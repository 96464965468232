import React, { useCallback, useEffect, useState } from 'react';

// Chakra imports
import {
  Button,
  Flex,
  HStack,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import InputFieldPassword from 'components/fields/InputFieldPassword';
import { useTranslation } from 'react-i18next';
import { SignUpUser } from 'services/@types';
import { SignUpStep } from '../SignUpCentered';

import {
  setSignupError,
  signUp,
  updateMyUser,
} from 'contexts/redux/auth/authSlice';
import { RootState } from 'contexts/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingNavigationHeader } from './OnboardingNavigationHeader';
// Custom components
// Assets

const SignUpTab: React.FC<{ nextClick: () => void; activeTab: SignUpStep }> = ({
  nextClick,
  activeTab,
}) => {
  // Chakra color mode
  const { t } = useTranslation();
  const cardBg = useColorModeValue('white', 'brand.800');
  const user = useSelector((state: RootState) => state.auth.user);
  const loading = useSelector((state: RootState) => state.auth.signingUp);
  const signupError = useSelector((state: RootState) => state.auth.error);

  const textColor = useColorModeValue('navy.700', 'white');
  const [signupInputValues, setSignupInputValues] = useState<
    Partial<SignUpUser>
  >({});
  const textColorDetails = useColorModeValue('gray.600', 'secondaryGray.600');
  const dispatch = useDispatch<any>();
  useEffect(() => {
    console.log('#Signup tab user ->', user);
    if (user && user.googleID) {
      setSignupInputValues({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      });
    }
  }, [user]);
  const handleNext = useCallback(async () => {
    // check email valid with regex
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signupInputValues.email)) {
      dispatch(setSignupError(t('signup.onboarding.signup.invalid_email')));
      return;
    }

    try {
      if (user?.googleID) {
        await dispatch(
          updateMyUser({
            updates: {
              firstName: signupInputValues?.firstName,
              lastName: signupInputValues?.lastName,
            },
          }),
        );
      } else {
        await dispatch(
          signUp({
            ...signupInputValues,
            onboardingStep: 'business_info',
            firebaseUID: '123',
          } as SignUpUser),
        );
      }
      nextClick();
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, nextClick, signupInputValues, t, user?.googleID]);

  return (
    <Flex
      display={activeTab === SignUpStep.signup ? 'flex' : 'none'}
      justify="center"
      key="signup_tab">
      <Card
        pt={10}
        px={0}
        boxShadow={{ base: 'none', sm: '0px', md: 'xl', lg: 'xl' }}
        bg={{ base: cardBg, sm: 'transparent', md: cardBg, lg: cardBg }}
        w={{ base: '100%', md: '414px' }}>
        <OnboardingNavigationHeader />
        <Text color={textColor} fontSize="2xl" fontWeight="700" mx={10}>
          {t('signup.onboarding.signup.create_your_account')}
        </Text>
        <Text color={textColorDetails} fontSize="md" mb="20px" mx={10}>
          {t('signup.onboarding.signup.you_will_have_event')}
        </Text>
        <Flex direction="column" w="100%" px={10}>
          <Stack direction="column" spacing="20px">
            <SimpleGrid columns={{ base: 1, md: 1 }} gap="20px">
              <HStack>
                <InputField
                  id="firstName"
                  value={signupInputValues?.firstName}
                  onChange={(e: any) => {
                    setSignupInputValues({
                      ...signupInputValues,
                      firstName: e.target.value,
                    });
                  }}
                  placeholder={t(
                    'signup.onboarding.signup.first_name_placeholder',
                  )}
                  label={t('signup.onboarding.signup.first_name')}
                />
                <InputField
                  value={signupInputValues.lastName}
                  id="lastName"
                  onChange={(e: any) => {
                    setSignupInputValues({
                      ...signupInputValues,
                      lastName: e.target.value,
                    });
                  }}
                  placeholder={t(
                    'signup.onboarding.signup.last_name_placeholder',
                  )}
                  label={t('signup.onboarding.signup.last_name')}
                />
              </HStack>
              <InputField
                mb="0px"
                id="Email"
                isDisabled={!!user?.googleID}
                value={signupInputValues.email}
                onChange={(e: any) => {
                  setSignupInputValues({
                    ...signupInputValues,
                    email: e.target.value,
                  });
                }}
                placeholder={t('signup.onboarding.signup.email_placeholder')}
                label={t('signup.onboarding.signup.email')}
              />

              <Flex display={user?.googleID ? 'none' : 'flex'}>
                <InputFieldPassword
                  mb="0px"
                  w="100%"
                  id="Password"
                  showButton
                  onChange={(e: any) => {
                    setSignupInputValues({
                      ...signupInputValues,
                      password: e.target.value,
                    });
                  }}
                  showValidations={true}
                  placeholder={t('signup.password_placeholder')}
                  type="password"
                  label={t('signup.onboarding.signup.password')}
                />
              </Flex>
            </SimpleGrid>
          </Stack>
          <Text
            color={'red.400'}
            fontSize="md"
            fontWeight={'semibold'}
            mt="20px">
            {t(signupError)}
          </Text>
          <Flex justify="center" mt="24px">
            <Button
              variant="h1cta"
              size="sm"
              w="100%"
              isLoading={loading}
              h="46px"
              onClick={handleNext}>
              {t('signup.onboarding.signup.get_started')}
            </Button>
          </Flex>
          <Text color={textColorDetails} fontSize="xs" mt="10px">
            {t('signup.onboarding.signup.agree_terms')}{' '}
            <Link textDecorationLine={'underline'}>
              {t('signup.onboarding.signup.terms_of_use')}
            </Link>{' '}
            &{' '}
            <Link textDecorationLine={'underline'}>
              {t('signup.onboarding.signup.privacy_policy')}
            </Link>{' '}
            {t('signup.onboarding.signup.more_info_about')}{' '}
            <Link textDecorationLine={'underline'}>
              {t('signup.onboarding.signup.accessibility')}
            </Link>
          </Text>
        </Flex>
      </Card>
    </Flex>
  );
};

export default SignUpTab;
