import {
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import Dropzone from 'components/dropzone';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { closeAddVideoLinkModal } from 'contexts/redux/dialog/dialogsSlice';
import {
  createMediaItem,
  updateMediaItem,
} from 'contexts/redux/mediaItem/mediaItemSlice';
import { RootState } from 'contexts/redux/store';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsImage } from 'react-icons/bs';
import { MdClear } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { MediaItem, UrlItem } from 'services/@types';
import { isValidVideoUrl } from 'utils/isValidVideoUrl';
interface AddVideoLinkModalProps {}
export const AddVideoLinkModal: React.FC<AddVideoLinkModalProps> = () => {
  const { onClose } = useDisclosure();
  const { t } = useTranslation();
  const dialog = useSelector(
    (state: RootState) => state.dialogs.addVideoLinkDialog,
  );
  const urlItem = dialog?.item as MediaItem;
  console.log('dialog ->', dialog);
  console.log('urlItem ->', urlItem);
  const dispatch = useDispatch<any>();
  const [tagInputValue, setTagInputValue] = React.useState('');
  const [errorLabels, setErrorLabels] = React.useState<{
    url: string;
    media: string;
  }>({ url: '', media: '' });
  const [mediaItemProps, setMediaItemProps] = React.useState<
    Partial<MediaItem>
  >({});

  const handleAddTag = useCallback(
    (tag: string) => {
      if (mediaItemProps.tags?.includes(tag)) return;
      if (mediaItemProps.tags?.length >= 30) return;
      setMediaItemProps({
        ...mediaItemProps,
        tags: [...(mediaItemProps?.tags || []), tag],
      });
      setTagInputValue('');
    },
    [mediaItemProps],
  );
  const handleDeleteTag = useCallback(
    (tag: string) => {
      setMediaItemProps({
        ...mediaItemProps,
        tags: mediaItemProps.tags.filter((t) => t !== tag),
      });
    },
    [mediaItemProps],
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'url' && isValidVideoUrl(value)) {
      setErrorLabels((prev) => ({
        ...prev,
        url: '',
      }));
    }
    setMediaItemProps((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleSaveClicked = useCallback(() => {
    if (!mediaItemProps?.url) {
      setErrorLabels((prev) => ({
        ...prev,
        url: t('gallery.new_album.error.add_video_link'),
      }));
      return;
    }
    if (!isValidVideoUrl(mediaItemProps?.url)) {
      setErrorLabels((prev) => ({
        ...prev,
        url: t('gallery.new_album.error.invalid_video_link'),
      }));
      return;
    }
    if (!mediaItemProps.media) {
      setErrorLabels((prev) => ({
        ...prev,
        media: t('gallery.new_album.error.add_video_thumbnail'),
      }));
      return;
    }
    if (mediaItemProps.id) {
      dispatch(
        updateMediaItem({
          mediaItemId: mediaItemProps.id,
          updates: mediaItemProps as MediaItem,
        }),
      );
    } else {
      dispatch(
        createMediaItem({
          ...mediaItemProps,
          privacy: 'public',
          type: 'video',
        } as MediaItem),
      );
    }
    dispatch(closeAddVideoLinkModal());
  }, [dispatch, mediaItemProps, t]);

  useEffect(() => {
    console.log('dialog?.item ->', dialog?.item);
    setMediaItemProps(dialog?.item || {});
  }, [dialog?.item]);

  const handleCancelClicked = useCallback(() => {
    dispatch(closeAddVideoLinkModal());
  }, [dispatch]);
  const tagsVariants = {
    pop: {
      scale: 1,
      opacity: 1,
      transition: {
        type: 'spring',
        // stiffness: 260,
        // damping: 20,
        duration: 0.2,
      },
    },
  };
  return (
    <Modal isOpen={!!dialog?.item} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent p={6} borderRadius="3xl" mx={4} maxW={526}>
        <ModalHeader p={0}>
          <HStack w="100%">
            <Text>{t('gallery.dialogs.upload_link.title')}</Text>
            <Spacer />

            <ModalCloseButton
              top={0}
              right={0}
              position={'relative'}
              onClick={handleCancelClicked}
            />
          </HStack>
        </ModalHeader>
        <Flex w="100%" mt={8}>
          <VStack align="start" gap={4} w="100%">
            <HStack
              align="start"
              wrap={{ base: 'wrap', md: 'nowrap' }}
              gap={6}
              w="100%">
              <VStack
                maxW={{ base: '100%', md: 160 }}
                w="100%"
                gap={4}
                alignItems={'start'}
                flexDirection={{ base: 'row', md: 'column' }}>
                <Flex
                  h={160}
                  minW={160}
                  w={160}
                  flexDirection={{ base: 'row', md: 'column' }}>
                  <Dropzone
                    currentImage={mediaItemProps?.media?.[0]?.url}
                    h={160}
                    w={160}
                    flex={1}
                    key={`upload`}
                    labelSize="14px"
                    uploadBarStyle="line"
                    uploadLabel={t(
                      'gallery.dialogs.upload_photo.add_video_thumbnail',
                    )}
                    uploadIcon={<Icon as={BsImage} color="gray.500" />}
                    target="album"
                    targetId={mediaItemProps?.albumID}
                    access="public"
                    uploadKey={`thumbnailImage`}
                    onUploadCompleted={(data: UrlItem) => {
                      handleChange({
                        target: {
                          name: 'media',
                          value: [data],
                        },
                      } as any);
                      setErrorLabels((prev) => ({
                        ...prev,
                        media: '',
                      }));
                    }}
                    dropzoneOptions={{
                      accept: 'image/*,video/*',
                      multiple: false,
                    }}
                  />
                  <Text
                    fontSize="sm"
                    color="red.500"
                    display={errorLabels.media ? 'block' : 'none'}>
                    {errorLabels.media}
                  </Text>
                </Flex>
                <Text fontSize={'md'} color="primary.100">
                  {t('gallery.dialogs.upload_photo.description')}
                </Text>
              </VStack>
              <VStack
                alignSelf={'start'}
                w="100%"
                gap={10}
                maxW={{ base: '100%', md: 280 }}>
                <VStack
                  align="start"
                  justify={'start'}
                  w="100%"
                  maxW={{ base: '100%', md: 280 }}>
                  <InputField
                    name="url"
                    value={mediaItemProps.url}
                    w="100%"
                    maxLength={120}
                    onChange={handleChange}
                    placeholder={t(
                      'gallery.new_album.placeholder.add_video_link',
                    )}
                    errorLabel={errorLabels.url}
                  />
                  <InputField
                    name="name"
                    value={mediaItemProps.name}
                    w="100%"
                    maxLength={120}
                    onChange={handleChange}
                    placeholder={t('gallery.new_album.placeholder.add_title')}
                  />
                  <InputField
                    name="alternativeTitle"
                    value={mediaItemProps.alternativeTitle}
                    w="100%"
                    maxLength={120}
                    tooltipLabel={t('gallery.alternative_text_tooltip')}
                    onChange={handleChange}
                    placeholder={t(
                      'gallery.new_album.placeholder.add_alternative_text',
                    )}
                  />
                  <TextField
                    name="description"
                    value={mediaItemProps.description}
                    w="100%"
                    tooltipLabel={t('gallery.description_text_tooltip')}
                    maxLength={500}
                    onChange={handleChange}
                    placeholder={t(
                      'gallery.new_album.placeholder.add_description',
                    )}
                  />

                  <InputField
                    value={tagInputValue}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && tagInputValue) {
                        e.preventDefault();
                        handleAddTag(tagInputValue);
                      }
                    }}
                    w="100%"
                    maxLength={50}
                    onChange={(e) => setTagInputValue(e.target.value)}
                    placeholder={t('tags')}
                    onAdd={handleAddTag}
                  />
                  <HStack wrap="wrap" gap={1} spacing={1} maxW={280}>
                    <AnimatePresence>
                      {mediaItemProps?.tags?.map((tag) => (
                        <Button
                          as={motion.div}
                          variant={'outlineTag'}
                          key={tag}
                          animate={'pop'}
                          variants={tagsVariants}
                          initial={{ scale: 0.6, opacity: 0 }}
                          exit={{ scale: 0.6, opacity: 0 }}
                          transition="0.1s"
                          maxW={280}
                          display={'flex'}
                          rightIcon={
                            <Icon
                              as={MdClear}
                              fontSize="md"
                              onClick={() => {
                                handleDeleteTag(tag);
                              }}
                            />
                          }>
                          <Text
                            textAlign={'start'}
                            whiteSpace="normal"
                            maxW={220}
                            wordBreak="break-word">
                            {tag}
                          </Text>
                        </Button>
                      ))}
                    </AnimatePresence>
                  </HStack>
                </VStack>
                <Button
                  alignSelf={'flex-end'}
                  variant="h1cta"
                  disabled={!mediaItemProps.name || !mediaItemProps.url}
                  onClick={handleSaveClicked}
                  px={14}>
                  {t('gallery.dialogs.upload_photo.save')}
                </Button>
              </VStack>
            </HStack>
          </VStack>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
