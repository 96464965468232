import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import './assets/css/App.css';
import store from './contexts/redux/store';
import AuthLayout from './layouts/auth';
import MainLayout from './layouts/main';

import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AlertDialogsContainer from 'components/alertDialogsContainer';
import AppLoader from 'components/appLoader';
import DeletedExternalEventsDialog from 'components/deletedExternalEventsDialog';
import EventsDialog from 'components/eventsDialog';
import ProtectedRoute from 'components/ProtectedRoute';
import RequestQuoteDialog from 'components/requestQuoteDialog';
import Splash from 'components/splash';
import useUpdateFFTheme from 'helpers/hooks/useUpdateFFTheme';
import * as ReactGA from 'react-ga';
import NoMatch from 'views/admin/main/others/404';
import { ViewPhotoModal } from 'views/main/gallery/components/ViewPhotoModal';
import AddCalendarModal from 'views/main/managment/calendar/components/AddCalendarModal';
import { CalendarsModal } from 'views/main/managment/calendar/components/CalendarsModal';
import Token from 'views/token';
import DeletedExternalMeetingsDialog from 'components/deletedExternalMeetingsDialog';
import { InterceptorComponent } from 'components/interceptor/InterceptorComponent';
import ShareMyCalendarModal from 'views/main/managment/calendar/components/ShareMyCalendarModal';
const environment = process.env.NODE_ENV || 'local'; // 'development', 'production', 'staging', or 'local'
// Chakra imports
Sentry.init({
  dsn: 'https://61551ad1adc6571284abe87d86ca1361@o4507521378287616.ingest.de.sentry.io/4507521416036432',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/app.brillevent\.com/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: environment,
});

ReactGA.initialize('G-VG0G6FQB6E');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 2, // Cache for 2 minutes
      staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes
    } as { cacheTime: number; staleTime: number },
  },
});

export default function Main() {
  const { theme, setTheme } = useUpdateFFTheme();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <InterceptorComponent />
          <Routes>
            <Route path="token/*" element={<Token />} />
            <Route element={<ProtectedRoute />}>
              <Route
                path="main/*"
                element={<MainLayout theme={theme} setTheme={setTheme} />}
              />
              <Route
                path="/"
                element={<Navigate to="/main/dashboard/overview" replace />}
              />
            </Route>
            <Route path="auth/*" element={<AuthLayout />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
          <CalendarsModal />
          <AddCalendarModal />
          <ShareMyCalendarModal />
          <ViewPhotoModal />
          <EventsDialog />
          <Splash />
          <AppLoader />
          <RequestQuoteDialog />
          <DeletedExternalEventsDialog />
          <DeletedExternalMeetingsDialog />
          <AlertDialogsContainer />
        </ChakraProvider>
      </Provider>
    </QueryClientProvider>
  );
}
