import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoWarning } from 'react-icons/io5';
import { MdClear } from 'react-icons/md';

import useEventFound from '../hooks/useEventFound';
interface EventFoundProps {
  event: Record<string, any>;
}
export default function EventFound(props: EventFoundProps) {
  const { event } = props;
  const { t } = useTranslation();
  const { clearEventFound, foundedEvent, handleOpenEventFoundDialog } =
    useEventFound(event);

  return (
    <Modal isOpen={!!foundedEvent} onClose={clearEventFound} isCentered>
      <ModalOverlay />
      <ModalContent
        bg={'orange.400'}
        maxW="248px"
        py={4}
        px={5}
        borderRadius="12px">
        <Flex alignSelf="center">
          <Flex align="center" flexDirection="column" gap={2}>
            <Icon as={IoWarning} color="white" fontSize={'22px'} />
            <VStack align={{ sm: 'center', md: 'start' }} spacing={0}>
              <Text color={'white'} textAlign="center">
                {t('create_event.found_similar_event')}
              </Text>
            </VStack>
            <Spacer />
            <HStack gap={2} alignSelf="center">
              <IconButton
                cursor="pointer"
                size="sm"
                borderRadius={'xl'}
                aria-label="clear"
                color={'red.400'}
                as={MdClear}
                p={1}
                onClick={() => clearEventFound()}
              />
              <Button
                h="32px"
                color="green.300"
                onClick={() => handleOpenEventFoundDialog()}>
                {t('create_event.show_me')}
              </Button>
            </HStack>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
