import { Flex, Progress } from '@chakra-ui/react';
import { RootState } from 'contexts/redux/store';
import { useUserInfoLoader } from 'hooks/useUserInfoLoader';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const AppLoader: React.FC = () => {
  console.debug('#app loader...');
  const businessTagsLoading = useSelector(
    (state: RootState) => state?.businessTags?.loading,
  );
  const businessCategoriesLoading = useSelector(
    (state: RootState) => state?.businessCategories?.loading,
  );
  const { loaders: userInfoLoaders } = useUserInfoLoader();

  const isLoading = useMemo(() => {
    return [
      businessTagsLoading,
      businessCategoriesLoading,
      ...Object.values(userInfoLoaders || {}),
    ].some((l) => l);
  }, [businessCategoriesLoading, businessTagsLoading, userInfoLoaders]);

  return (
    <Flex
      position="absolute"
      w="100%"
      zIndex={100}
      top={isLoading ? '0' : '-100%'}
      color="white"
      transform={`translateY(${isLoading ? '0' : '-100'}%)`}
      transition={'all 0.5s ease'}>
      <Progress w="100%" h={'2px'} colorScheme={'orange'} isIndeterminate />
    </Flex>
  );
};

export default AppLoader;
