// chakra imports
import { Flex, Stack } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';

// FUNCTIONS

function SidebarContent(props: {
  routes: RoutesType[];
  hovered?: boolean;
  mini?: boolean;
}) {
  const { routes, mini, hovered } = props;
  // SIDEBAR
  return (
    <Flex
      direction="column"
      height="100%"
      py={8}
      gap={8}
      borderRadius="30px"
      w="100%">
      <Brand mini={mini} hovered={hovered} />
      <HSeparator w="100%" />
      <Stack
        className="bla"
        direction="column"
        mb="auto"
        mt="15px"
        // w="100%"
        overflow={'scroll'}
        sx={{ scrollbarWidth: 'none', pb: 100 }}>
        <Flex w="100%" px={6}>
          <Links mini={mini} hovered={hovered} routes={routes} />
        </Flex>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
