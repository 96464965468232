import {
  Icon,
  IconButton,
  IconButtonProps,
  MenuButton,
  MenuButtonProps,
} from '@chakra-ui/react';
import { FiMoreHorizontal } from 'react-icons/fi';

interface ThreeDotsBtnProps extends MenuButtonProps, Partial<IconButtonProps> {
  onClick: () => void;
  ref?: any;
}

export default function ThreeDotsBtn(props: ThreeDotsBtnProps) {
  const { onClick, ref, ...rest } = props;
  return (
    <MenuButton
      as={IconButton}
      variant="actionIconBlue"
      icon={<Icon as={FiMoreHorizontal} w="20px" h="20px" color="white" />}
      onClick={onClick}
      ref={ref}
      {...rest}
    />
  );
}
