const colors = [
  '#93C3B3',
  '#F6BD60',
  '#EDD7BF',
  '#F5CAC3',
  '#F28482',
  '#685D60',
  '#465D55',
  '#3891A6',
  '#8885AC',
  '#60A0CB',
  '#CCC1B1',
  '#89857B',
];

// if name is not provided or invalid, return the first color, because this color we chould use if we don't have any name
export const getAvatarBgColor = (name?: string): string => {
  if (!name || typeof name !== 'string' || !name.trim()) {
    return colors[0];
  }

  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const score = Math.abs(hash % colors.length);
  return colors[score];
};
