import { Flex, Text, VStack } from '@chakra-ui/react';
import RequestComponent from 'components/actions/RequestComponent';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
import { CustomCardProps } from 'theme/theme';
type ColleagueRequestsProps = CustomCardProps;
export const ColleagueRequests: React.FC<ColleagueRequestsProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Flex flex={1} maxW={{ base: '100%', sm: '100%', md: '100%', lg: 550 }}>
      <Card
        {...props}
        p="34px"
        maxH={{ base: '100%', sm: '100%', md: 550 }}
        overflow="auto"
        gap={6}>
        <VStack align="start" spacing={0}>
          <Text fontSize="2xl" fontWeight="700">
            {t('the_office.colleague_requests.title')}
          </Text>
          <Text>{t('the_office.colleague_requests.description')}</Text>
        </VStack>
        {Array.from({ length: 23 }).map((_, index) => (
          <RequestComponent
            key={index}
            mb="26px"
            name="Public Transport"
            date="22 September 2022"
          />
        ))}
      </Card>
    </Flex>
  );
};
