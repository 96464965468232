import { useMyUser } from 'contexts/redux/auth/authSlice';
import { fetchCalendars } from 'contexts/redux/calendar/calendarsSlice';
import {
  closeAlertDialog,
  openAlertDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import useUpdateInvite from 'mutations/invite';
import useGetInvite from 'queries/invite';
import useGetUser from 'queries/user';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InviteType } from 'services/@types';

// Define the possible invite types
interface InviteConfig {
  title: string;
  message: string;
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: (navigate: ReturnType<typeof useNavigate>) => void;
}

const useInviteInterceptor = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const myUser = useMyUser();

  const inviteId =
    searchParams.get('inviteId') || localStorage.getItem('inviteId');

  const { data: invite } = useGetInvite(inviteId);
  const { data: fromUser } = useGetUser(invite?.senderID);
  const { mutateAsync: updateInvite, data: updatedInvite } = useUpdateInvite();
  const getInviteConfig = (
    type: InviteType,
    fromUserName: string,
  ): InviteConfig => {
    switch (type) {
      case 'calendar':
        return {
          title: `Calendar Invite from ${fromUserName}`,
          message:
            'You have been invited to join a calendar. Would you like to accept?',
          confirmLabel: 'Join Calendar',
          cancelLabel: 'Decline',
          onConfirm: (navigate) => navigate('/main/dashboard/calendar'),
        };
      default:
        throw new Error('Unknown invite type');
    }
  };

  const handleInvite = useCallback(
    (inviteType: InviteType) => {
      const fromUserName = `${fromUser?.firstName} ${fromUser?.lastName}`;
      const config = getInviteConfig(inviteType, fromUserName);

      dispatch(
        openAlertDialog({
          ...config,
          onClose: () => {
            clearLocalStorage();
            dispatch(closeAlertDialog());
            updateInvite({
              id: inviteId as string,
              updates: { status: 'rejected' },
            });
          },
          onConfirm: async () => {
            config.onConfirm(navigate);
            clearLocalStorage();
            await updateInvite({
              id: inviteId as string,
              updates: { status: 'accepted' },
            });
            dispatch(closeAlertDialog());
            dispatch(fetchCalendars({}));
          },
        }),
      );
    },
    [
      dispatch,
      fromUser?.firstName,
      fromUser?.lastName,
      inviteId,
      navigate,
      updateInvite,
    ],
  );

  const clearLocalStorage = () => {
    localStorage.removeItem('inviteId');
  };

  useEffect(() => {
    if (inviteId) {
      localStorage.setItem('inviteId', inviteId);
    }

    if (invite && invite.status === 'pending') {
      console.log('Intercepted invite:', invite);
      setSearchParams({}, { replace: true });

      if (invite.type) {
        handleInvite(invite.type as InviteType);
      }
    } else if (invite) {
      dispatch(
        openAlertDialog({
          title: 'Oops!',
          content: 'This invite was already accepted or rejected.',
          onConfirm: () => {
            setSearchParams({}, { replace: true });
            dispatch(closeAlertDialog());
            clearLocalStorage();
          },
          onClose: () => {
            setSearchParams({}, { replace: true });
            dispatch(closeAlertDialog());
            clearLocalStorage();
          },
        }),
      );
    }
  }, [
    handleInvite,
    invite,
    inviteId,
    searchParams,
    setSearchParams,
    myUser,
    dispatch,
  ]);

  return { inviteId };
};

export default useInviteInterceptor;
