import { AxiosError } from 'axios';
import { closeSplashDialog, showSplashDialog } from 'contexts/redux/dialog/dialogsSlice';
import useQueryParam from 'hooks/useQueryParam';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Business, Invite, User } from 'services/@types';
import _inviteService from 'services/invite.api';

export const useSeatInvite = () => {
    const [seatInvite, setSeatInvite] = useState<Invite | null>(null);
    const [senderUser, setSenderUser] = useState<User>(null);
    const [business, setBusiness] = useState<Business>(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const token = useQueryParam('token');
    const dispatch = useDispatch<any>();
    const [searchParams, setSearchParams] = useSearchParams();

    const fetchSeatInvite = useCallback(async () => {
        try {
            dispatch(showSplashDialog({ title: 'invite_seat.loading_invite' }));
            const { invite, business, sender } = await _inviteService.getInviteByToken(token)
            console.log('#Seat Invite ->', invite, business, sender);
            setSeatInvite(invite);
            setSenderUser(sender);
            setBusiness(business);
        } catch (e) {
            setError((e as AxiosError).message);
        }
        setSearchParams({});
        setLoading(false);
        setTimeout(() => {
            dispatch(closeSplashDialog());
        }, 1000);
    }, [dispatch, setSearchParams, token])

    useEffect(() => {
        if (!token || seatInvite) return;
        fetchSeatInvite();
    }, [fetchSeatInvite, seatInvite, token])


    return { seatInvite, loading, error, senderUser, business };
}