import { Avatar, AvatarGroup } from '@chakra-ui/avatar';
import AuthImage from 'components/authImage/AuthImage';
import { getUserMedia } from 'contexts/redux/user/userSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function Avatars(props: { users: string[] }) {
  const { users } = props;
  const [firstAvatar, setFirstAvatar] = useState<null | {
    url: string;
    type: string;
  }>(null);
  const dispatch = useDispatch<any>();
  useEffect(() => {
    if (users?.length > 0) {
      const getAvatar = async () => {
        try {
          const response = await dispatch(getUserMedia(users[0]));

          if (response.payload?.length > 0) {
            setFirstAvatar(response.payload[0]);
          }
        } catch (e) {
          console.error(e);
        }
      };
      getAvatar();
    }
  }, [users, dispatch]);
  if (!firstAvatar) return null;
  return (
    <AvatarGroup max={1} variant="group">
      {users?.map((_, index) => {
        return index === 0 ? (
          <AuthImage
            key={index}
            w="24px"
            h="24px"
            borderRadius="50%"
            path={firstAvatar.url}
            isAvatar
            name=""
          />
        ) : (
          <Avatar key={index} w="24px" h="24px" />
        );
      })}
    </AvatarGroup>
  );
}
