import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IQoute } from 'services/@types';
import QouteService from 'services/quote.api';

export interface ProductState {
  qoutes: IQoute[];
  currentQoute: IQoute | null;
  qoute: IQoute | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: ProductState = {
  qoutes: [],
  qoute: null,
  loading: false,
  error: null,
  currentQoute: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const createQuote = createAsyncThunk(
  'qoutes/createQuote',
  async (params: IQoute, { rejectWithValue }) => {
    try {
      const response = await QouteService.createQuote(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getQuotes = createAsyncThunk(
  'qoutes/getQuotes',
  async (
    params: { page?: number; limit?: number; requestQuoteToken?: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await QouteService.getQuotes(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const qouteSlice = createSlice({
  name: 'qoutes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createQuote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createQuote.fulfilled, (state, action) => {
      state.loading = false;
      state.qoute = action.payload;
    });
    builder.addCase(createQuote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getQuotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getQuotes.fulfilled, (state, action) => {
      state.loading = false;
      state.qoutes = action.payload.results as IQoute[];
      state.pagination = {
        page: action.payload.page,
        limit: action.payload.limit,
        totalPages: action.payload.totalPages,
        totalResults: action.payload.totalResults,
      };
    });
    builder.addCase(getQuotes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default qouteSlice.reducer;
