import React, { useState, useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';
import { updateMyUser } from 'contexts/redux/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function useUpdateUser(userData: any) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isSaveBtnVisible, setIsSaveBtnVisible] = useState(false);
  const [error, setError] = useState('');
  const [inputValues, setInputValues] =
    useState<Record<string, string>>(userData);
  const dispatch = useDispatch<any>();
  const toast = useToast();
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setInputValues((prevState) => ({ ...prevState, [name]: value }));
  };
  useEffect(() => {
    setInputValues(userData);
  }, [userData]);

  const updateUser = useCallback(async () => {
    try {
      setLoading(true);
      const res: any = {};
      Object.keys(inputValues).map((key) => {
        if (userData[key] !== inputValues[key]) {
          res[key] = inputValues[key];
        }
      });
      if (Object.keys(res).length === 0) {
        setIsSaveBtnVisible(false);
        setLoading(false);
        return;
      }
      const response = await dispatch(updateMyUser({ updates: res }));

      if (!response.error) {
        toast({
          title: t('toast.title.success'),
          description: t('toast.description.user_updated'),
          status: 'success',
          duration: 2000,
          variant: 'main',
        });
        setIsSaveBtnVisible(false);
      } else {
        setInputValues(userData);
        setError(response.error.message);
        setIsSaveBtnVisible(false);
        toast({
          title: response.error.message,
          description: response.payload.response.data.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (e) {
      const error = e as AxiosError;
      setError(error.message);
    }
    setLoading(false);
  }, [inputValues, dispatch, userData, toast, t]);

  return {
    inputValues,
    handleChange,
    updateUser,
    loading,
    error,
    isSaveBtnVisible,
    setLoading,
    setIsSaveBtnVisible,
  };
}
