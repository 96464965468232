import { getAvatarBgColor } from 'utils/getAvatarBgColor';

export const AvatarStyles = {
  components: {
    Avatar: {
      variants: {
        main: (props: any) => ({
          container: {
            bg: getAvatarBgColor(props.id),
            color: 'white',
          },
        }),
        group: () => ({
          group: {
            gap: '4px',
          },
          excessLabel: {
            color: 'white',
            w: '24px',
            h: '24px',
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '20px',
            fontFamily: 'Inter',
            bg: '#93C3B3',
            zIndex: 3,
          },
        }),
      },
    },
  },
};
