import { AxiosInstance, AxiosResponse } from 'axios';
import { Business, Calendar, Supplier, User } from './@types';
import { createAxiosInstance } from './axios.util';

class CalendarService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createCalendar(calendar: Partial<Calendar>): Promise<Calendar> {
    const response: AxiosResponse<Calendar> = await this.api.post(
      '/calendars',
      calendar,
    );
    return response.data;
  }

  public async getCalendars(
    queryParams?: Record<string, any>,
  ): Promise<Calendar[]> {
    const response: AxiosResponse<Calendar[]> = await this.api.get(
      '/calendars',
      {
        params: queryParams,
      },
    );
    return response.data;
  }

  public async getCalendar(calendarId: string): Promise<Calendar> {
    const response: AxiosResponse<Calendar> = await this.api.get(
      `/calendars/${calendarId}`,
    );
    return response.data;
  }

  public async getCalendarByToken(token: string): Promise<{
    calendar: Calendar;
    sender: User;
    business?: Business;
    supplier?: Supplier;
  }> {
    const response: AxiosResponse<{
      calendar: Calendar;
      sender: User;
      business?: Business;
      supplier?: Supplier;
    }> = await this.api.get(`/calendars/token?token=${token}`);
    return response.data;
  }

  public async updateCalendar(
    calendarId: string,
    updates: Partial<Calendar>,
  ): Promise<Calendar> {
    const response: AxiosResponse<Calendar> = await this.api.patch(
      `/calendars/${calendarId}`,
      updates,
    );
    return response.data;
  }

  public async deleteCalendar(calendarId: string): Promise<void> {
    await this.api.delete(`/calendars/${calendarId}`);
  }
}

const _calendarService = new CalendarService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _calendarService;
