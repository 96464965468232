// Chakra imports
import {
    Box,
    Flex,
    Text
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import Footer from 'components/footer/FooterAuthCentered';
import PropTypes from 'prop-types';

function AuthCentered(props: {
  children: JSX.Element;
  title?: string;
  description?: string;
  image?: string;
  cardTop?: { [x: string]: string | number };
  cardBottom?: { [x: string]: string | number };
  [x: string]: any;
}) {
  // eslint-disable-next-line
  const {
    children,
    title,
    description,
    cardTop,
    cardBottom,
    cardBackground,
  } = props;

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      mx={{ base: '10px', sm: '2px', md: '20px' }}
      minH="100vh"
    >
      {/* <FixedPlugin /> */}
      <Box
        position="absolute"
        minH={{ base: '50vh', md: '50vh', sm: '155px' }}
        maxH={{ base: '50vh', md: '50vh' }}
        w={{ md: 'calc(100vw)' }}
        maxW={{ md: 'calc(100vw)' }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        top="0"
        bgGradient={{
          base: 'linear(to-b, brand.400, brand.600)',
          sm: 'linear(to-r, brand.400, brand.600)',
          md: 'linear(to-l, brand.400, brand.600)',
          lg: 'linear(to-l, brand.400, brand.600)',
          xl: 'linear(to-l, brand.400, brand.600)',
        }}
        mx={{ md: 'auto' }}
      />
      {/* <Navbar /> */}
      <Card
        w={{ base: '100%', md: 'max-content' }}
        h="max-content"
        mx="auto"
        maxW="100%"
        mt={cardTop}
        mb={cardBottom}
        boxShadow={cardBackground === 'transparent' ? 'none' : 'xl'}
        bg={cardBackground}
        p={{ base: '10px', sm: '2px', md: '50px' }}
        pt={{ base: '10px', sm: '2px', md: '0px' }}
      >
        {title && description ? (
          <Flex
            direction="column"
            textAlign="center"
            justifyContent="center"
            align="center"
            mt="105px"
            mb="30px"
          >
            <Text fontSize="4xl" color="white" fontWeight="bold">
              {title}
            </Text>
            <Text
              fontSize="md"
              color="white"
              fontWeight="normal"
              mt={{ base: '10px', sm: '4px', md: '20px' }}
              mb="26px"
              w={{ base: '90%', sm: '60%', lg: '40%', xl: '333px' }}
            >
              {description}
            </Text>
          </Flex>
        ) : null}
        {children}
      </Card>
      <Footer />
    </Flex>
  );
}
// PROPS

AuthCentered.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.any,
};

export default AuthCentered;
