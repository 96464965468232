import {
  Button,
  Flex,
  Hide,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Show,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
// Custom components
import InputField from 'components/fields/InputField';
import { AppTooltip } from 'components/tooltip';
import {
  createProduct,
  updateProduct,
} from 'contexts/redux/product/productSlice';
import { RootState } from 'contexts/redux/store';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Business, Product } from 'services/@types';

export interface AddProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  editableProduct?: Partial<Product>;
  onFoundExistingProduct: (product: Business) => void;
}

export const AddProductModal: React.FC<AddProductModalProps> = ({
  isOpen,
  onClose,
  editableProduct,
  onFoundExistingProduct,
}) => {
  const dispatch = useDispatch<any>();
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const toast = useToast();
  const { t } = useTranslation();
  const [productProps, setProductProps] = useState<Partial<Product>>({});
  const [errorLabels, setErrorLabels] = useState<{
    name: string;
    price: string;
  }>({ name: '', price: '' });
  const { businessCategories } = useSelector(
    (state: RootState) => state.businessCategories,
  );
  const { loading } = useSelector((state: RootState) => state.product);
  const handleProductAction = useCallback((business: Business) => {
    setProductProps({
      businessID: business.id,
      name: business.businessName,
    });
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorLabels({ name: '', price: '' });
      setProductProps({ ...productProps, [e.target.id]: e.target.value });
    },
    [productProps],
  );

  const handleClose = useCallback(() => {
    onClose();
    setErrorLabels({ name: '', price: '' });
  }, [onClose]);

  useEffect(() => {
    console.log('add product modal ->', editableProduct);
    if (editableProduct) {
      setProductProps(editableProduct);
    } else {
      setProductProps({});
    }
  }, [editableProduct, isOpen, handleClose]);

  const validate = useCallback(() => {
    if (!productProps.name) {
      setErrorLabels((prev) => ({
        ...prev,
        name: t('products.errors.name'),
      }));
      return false;
    }

    return true;
  }, [productProps.name, t]);

  const handleSave = useCallback(() => {
    if (!validate()) {
      return;
    }
    if (editableProduct) {
      dispatch(
        updateProduct({
          productId: productProps.id,
          updates: productProps,
        }),
      );
      toast({
        variant: 'main',
        title: t('products.toast.product_updated'),
      });
    } else {
      dispatch(createProduct(productProps));

      toast({
        variant: 'main',
        title: t('products.toast.product_added'),
      });
    }
    handleClose();
  }, [
    dispatch,
    editableProduct,
    handleClose,
    productProps,
    t,
    toast,
    validate,
  ]);
  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent
        borderRadius={'3xl'}
        mx={2}
        px={5}
        pb={5}
        pt={'30px'}
        maxW={'720px'}>
        <ModalHeader px={1} py={0}>
          <HStack gap={2} align={{ sm: 'start', md: 'center' }} h="100%">
            <VStack align="start" flex={1} w="100%">
              <Text
                color={textColor}
                fontSize={{ sm: 'xl', md: '2xl' }}
                fontWeight="700">
                {t('products.add_product_modal.title')}
              </Text>
            </VStack>
            <Hide below="md">
              <Button
                variant="h1cta"
                ms="auto"
                px={{ base: 4, md: 16 }}
                onClick={handleSave}
                isLoading={loading}>
                {t('products.add_product_modal.save')}
              </Button>
            </Hide>
            <ModalCloseButton right={0} position={'relative'} top={0} />
          </HStack>
        </ModalHeader>
        <Flex
          direction="column"
          align="center"
          position="relative"
          p={0}
          py={6}
          gap={4}>
          <Flex p={0} w="100%">
            <Flex
              direction="row"
              w="100%"
              gap={4}
              wrap="wrap"
              justify={'space-between'}>
              <VStack gap={4} flex={1} align="start" minWidth={280} maxW={310}>
                <InputField
                  id="name"
                  w="100%"
                  value={productProps?.name}
                  placeholder={t('products.add_product_modal.name_placeholder')}
                  onChange={handleChange}
                  label={t('products.add_product_modal.name')}
                  errorLabel={errorLabels.name}
                />
              </VStack>
              <VStack gap={6} align="start" flex={1} minWidth={280} maxW={310}>
                {/* business id, categories, tag services, internal notes */}

                <InputField
                  id="price"
                  value={productProps?.price}
                  w="100%"
                  switchProps={{
                    label: t('products.add_product_modal.show_price'),
                    labelColor: 'brand.900',
                    id: 'show_price',
                    size: 'sm',
                    isChecked: false,
                    onChange: () => console.log('BLA'),
                  }}
                  onChange={handleChange}
                  errorLabel={errorLabels.price}
                  placeholder={t(
                    'products.add_product_modal.price_placeholder',
                  )}
                  label={t('products.add_product_modal.price')}
                />
              </VStack>
            </Flex>
          </Flex>
          <HStack w="100%" align="center">
            <Text
              flexDirection={'row'}
              alignSelf={'center'}
              fontSize={{ sm: 'sm', md: 'md' }}>
              {t('products.add_product_modal.to_add_more')}{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#AA915D',
                }}
                onClick={() => handleSave()}>
                {t('products.add_product_modal.advanced_settings')}
              </span>
            </Text>
            <AppTooltip label="products.add_product_modal.to_add_more_tooltip" />
          </HStack>
          <Show below="md">
            <Button
              width="100%"
              maxW={320}
              variant="h1cta"
              px={{ base: 4, md: 16 }}
              onClick={handleSave}
              isLoading={loading}>
              {t('products.add_product_modal.save')}
            </Button>
          </Show>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
