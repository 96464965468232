import { Box, Button, HStack, Image, Text, VStack } from '@chakra-ui/react';
import calendarIcon from 'assets/svg/sidebar/calendar.svg';
import React from 'react';
import { Invite } from 'services/@types';

interface InviteCardProps {
  invite: Invite;
  onAccept: () => void;
  onDecline: () => void;
}

const InviteItem: React.FC<InviteCardProps> = ({
  invite,
  onAccept,
  onDecline,
}) => {
  return (
    <Box
      p={4}
      bg="white"
      sx={{ backgroundColor: '#93C3B31A' }}
      borderRadius="12px">
      <VStack align="stretch" spacing={4}>
        <HStack>
          <Image
            alignSelf={'flex-start'}
            w={5}
            h={5}
            src={calendarIcon}
            filter="brightness(0.8) contrast(1.2) sepia(0.6) hue-rotate(20deg)"
          />
          <Text fontSize="sm" color="gray.700">
            {invite?.fullName} is asking to view your calendar availability
          </Text>
        </HStack>
        <HStack justify="space-between">
          <Button variant="h1cta" onClick={onAccept} flex={1}>
            Accept
          </Button>
          <Button variant="h2outlined" onClick={onDecline} flex={1}>
            Decline
          </Button>
        </HStack>
        <Text fontSize="md" color="gray.500" alignSelf="flex-end">
          15 Apr
        </Text>
      </VStack>
    </Box>
  );
};

export default InviteItem;
