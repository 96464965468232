// src/features/uploads/userSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { PaginatedResults, Upload } from 'services/@types';
import UploadService from 'services/upload.api';
import { RootState } from '../store';
export interface UploadState {
  uploads: Upload[];
  upload: Upload | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: UploadState = {
  uploads: [],
  upload: null,
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchUploads = createAsyncThunk(
  'uploads/fetchUploads',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await UploadService.getUploads(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateUpload = createAsyncThunk(
  'uploads/updateUpload',
  async (
    {
      uploadId,
      target,
      access,
      targetId,
      fileId,
      updates,
    }: {
      uploadId: string;
      target: string;
      access: string;
      targetId: string;
      fileId: string;
      updates?: Partial<Upload>;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await UploadService.updateUpload(
        target,
        targetId,
        access,
        fileId,
        updates,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteUpload = createAsyncThunk(
  'upload/deleteUpload',
  async (
    {
      target,
      targetId,
      access,
      fileId,
      uploadId,
      filePath,
    }: {
      target?: string;
      targetId?: string;
      access?: string;
      fileId?: string;
      uploadId?: string;
      filePath?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      if (filePath) {
        await UploadService.deleteUpload(parseFilePath(filePath));
        return uploadId;
      }
      await UploadService.deleteUpload({ target, targetId, access, fileId });
      return uploadId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUploads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchUploads.fulfilled,
        (state, action: PayloadAction<PaginatedResults<Upload>>) => {
          state.uploads = action.payload.results;
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      // Update a upload
      .addCase(updateUpload.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateUpload.fulfilled,
        (state, action: PayloadAction<Upload>) => {
          const index = state.uploads.findIndex(
            (user) => user.filePath === action.payload.filePath,
          );
          if (index !== -1) {
            state.uploads[index] = action.payload;
          }
          state.upload = action.payload;
          state.loading = false;
        },
      )
      .addCase(updateUpload.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete a upload
      .addCase(deleteUpload.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteUpload.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.uploads = state.uploads.filter(
            (upload) => upload.filePath !== action.payload,
          );
          state.loading = false;
        },
      )
      .addCase(deleteUpload.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export function useMyUpload() {
  const upload = useSelector((state: RootState) => state.uploads.upload);
  return upload;
}

export default userSlice.reducer;

export const parseFilePath = (
  filePath: string,
): { target: string; targetId: string; access: string; fileId: string } => {
  const [target, targetId, access, fileId] = filePath.split('/');
  return { target, targetId, access, fileId };
};
