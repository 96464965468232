// src/features/items/userSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { Album, PaginatedResults } from 'services/@types';
import AlbumService from 'services/album.api';
import {
  closeAddAlbumDialog,
  closeAlertDialog,
  closeSplashDialog,
  showSplashDialog,
} from '../dialog/dialogsSlice';
import store, { RootState } from '../store';
export interface AlbumState {
  items: Album[];
  upload: Album | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: AlbumState = {
  items: [],
  upload: null,
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const createAlbum = createAsyncThunk(
  'album/createAlbum',
  async (album: Album, { rejectWithValue }) => {
    try {
      store.dispatch(
        showSplashDialog({
          title: 'signup.gallery.album_info.creating_album',
        }),
      );
      const newAlbum = await AlbumService.createAlbum({
        ...album,
      });
      console.log('#Redux create album ->', newAlbum);
      store.dispatch(closeSplashDialog());

      return newAlbum;
    } catch (error) {
      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);

export const fetchAlbums = createAsyncThunk(
  'items/fetchAlbums',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await AlbumService.getAlbums(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateAlbum = createAsyncThunk(
  'items/updateAlbum',
  async (
    {
      albumId,
      updates,
    }: {
      albumId: string;
      updates?: Partial<Album>;
    },
    { rejectWithValue },
  ) => {
    try {
      delete updates?.target;
      delete updates?.targetId;
      delete updates?.updatedAt;
      delete updates?.createdAt;
      delete updates?.id;
      const response = await AlbumService.updateAlbum(albumId, updates);
      store.dispatch(closeAddAlbumDialog());
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteAlbum = createAsyncThunk(
  'upload/deleteAlbum',
  async (
    {
      albumId,
    }: {
      albumId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      await AlbumService.deleteAlbum(albumId);
      store.dispatch(closeAlertDialog());
      return albumId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const albumSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAlbum.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAlbum.fulfilled, (state, action: PayloadAction<Album>) => {
        // state.items.unshift(action.payload);
        // add it to second position:
        state.items.splice(1, 0, action.payload);

        state.upload = action.payload;
        state.loading = false;
      })
      .addCase(createAlbum.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAlbums.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchAlbums.fulfilled,
        (state, action: PayloadAction<PaginatedResults<Album>>) => {
          state.items = action.payload.results;
          // transfer general_album to pos 0:
          const generalAlbum = state.items.find(
            (album) => album.name === 'general_album',
          );
          if (generalAlbum) {
            state.items = state.items.filter(
              (album) => album.name !== 'general_album',
            );
            state.items.unshift(generalAlbum);
          }
          //
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      // Update a upload
      .addCase(updateAlbum.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAlbum.fulfilled, (state, action: PayloadAction<Album>) => {
        const index = state.items.findIndex(
          (user) => user.id === action.payload.id,
        );
        if (index !== -1) {
          state.items[index] = action.payload;
        }
        state.upload = action.payload;
        state.loading = false;
      })
      .addCase(updateAlbum.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete a upload
      .addCase(deleteAlbum.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteAlbum.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.items = state.items.filter(
            (upload) => upload.id !== action.payload,
          );
          state.loading = false;
        },
      )
      .addCase(deleteAlbum.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export function useMyAlbums() {
  const albums = useSelector((state: RootState) => state.album.items);
  return albums;
}

export default albumSlice.reducer;
