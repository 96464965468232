import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ITask } from 'services/@types';
import TaskService from 'services/task.api';

export interface TaskState {
  tasks: ITask[];
  currentTask: ITask | null;
  task: ITask | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: TaskState = {
  tasks: [],
  task: null,
  loading: false,
  error: null,
  currentTask: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const createTask = createAsyncThunk(
  'tasks/createTask',
  async (params: ITask, { rejectWithValue }) => {
    try {
      const response = await TaskService.createTask(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTasks = createAsyncThunk(
  'tasks/getTasks',
  async (params: { page?: number; limit?: number }, { rejectWithValue }) => {
    try {
      const response = await TaskService.getTasks(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTask = createAsyncThunk(
  'tasks/getTask',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await TaskService.getTask(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateTask = createAsyncThunk(
  'tasks/updateTask',
  async (
    params: { id: string; updates: Partial<ITask> },
    { rejectWithValue },
  ) => {
    try {
      const response = await TaskService.updateTask(params.id, params.updates);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteTask = createAsyncThunk(
  'tasks/deleteTask',
  async (id: string, { rejectWithValue }) => {
    try {
      await TaskService.deleteTask(id);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createTask.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTask.fulfilled, (state, action) => {
      state.loading = false;
      state.task = action.payload;
      state.tasks.push(action.payload);
    });
    builder.addCase(createTask.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(getTasks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTasks.fulfilled, (state, action) => {
      state.loading = false;
      state.tasks = action.payload.results;
      state.pagination = action.payload;
    });
    builder.addCase(getTasks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(getTask.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTask.fulfilled, (state, action) => {
      state.loading = false;
      state.currentTask = action.payload;
    });
    builder.addCase(getTask.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(updateTask.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTask.fulfilled, (state, action) => {
      state.loading = false;
      state.tasks = state.tasks.map((task: ITask) =>
        task.id === action.payload.id ? action.payload : task,
      );
      state.task = action.payload;
    });
    builder.addCase(updateTask.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(deleteTask.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTask.fulfilled, (state, action) => {
      state.loading = false;
      state.tasks = state.tasks.filter(
        (task: ITask) => task.id !== action.payload,
      );
    });
    builder.addCase(deleteTask.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default taskSlice.reducer;
