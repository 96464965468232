import { HStack, Image, Select } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../assets/svg/icons/languagePicker/index.svg';

export interface SelectedLanguage {
  label: string;
  value: string;
}

const AvailableLanguages: SelectedLanguage[] = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'עברית',
    value: 'he',
  },
];

export const LanguagePicker: React.FC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const { t, i18n } = useTranslation();
  const [displayedOptions, setDisplayedOptions] = useState<SelectedLanguage[]>([
    { label: 'language', value: 'language' },
  ]);
  useEffect(() => {
    const language = localStorage.getItem('language') || 'en';
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
  }, [i18n]);

  const handleLanguageChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value === 'language') return;
      console.log('handleLanguageChange -> event', event.target.value);
      const value = event.target.value;
      setDisplayedOptions([{ label: 'language', value: 'language' }]);
      setSelectedLanguage(value);
      i18n.changeLanguage(value);
      localStorage.setItem('language', value);
    },
    [i18n],
  );

  const handleClick = useCallback(() => {
    setDisplayedOptions(AvailableLanguages);
  }, []);

  return (
    <HStack align="center">
      <Select
        icon={<Image src={Icon} alt="" />}
        onClick={handleClick}
        value={selectedLanguage}
        onBlur={() => {
          setDisplayedOptions([{ label: 'language', value: 'language' }]);
        }}
        onChange={handleLanguageChange}
        variant="languagePicker"
        background={selectedLanguage === 'he' ? '#00000080' : '#0000004D'}
        textAlign="center"
        sx={{
          option: {
            textAlign: 'center',
            padding: '10px',
          },
        }}>
        {displayedOptions.map((lang) => (
          <option key={lang.value} value={lang.value}>
            {lang.value === 'language' ? t(`header.${lang.label}`) : lang.label}
          </option>
        ))}
      </Select>
    </HStack>
  );
};
