// Chakra imports
import {
  Flex,
  FormLabel,
  Icon,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';
// Custom components
import SwitchField from 'components/fields/SwitchField';

export default function Default(props: {
  id?: string;
  label?: string;
  extra?: string;
  iconRight?: any;
  tooltipLabel?: string;
  tooltipTopLabel?: string;
  switchProps?: {
    id: string;
    label: string;
    isChecked: boolean;
    onChange: () => void;
  };
  iconLeft?: any;
  placeholder?: string;
  [x: string]: any;
}) {
  const {
    mb,
    id,
    tooltipLabel,
    tooltipTopLabel,
    label,
    extra,
    placeholder,
    iconLeft,
    switchProps,
    iconRight,
    labelProps,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('primary.100', 'white'); // use the same like on input field
  const borderPrimary = useColorModeValue('brand.600', 'brand.600');
  const {
    isOpen: isOpenTooltip,
    onOpen: onOpenTooltip,
    onClose: onCloseTooltip,
    onToggle: onToggleTooltip,
  } = useDisclosure();
  return (
    <Flex direction="column" mb={mb || '0px'} {...rest}>
      <FormLabel
        display="flex"
        htmlFor={id}
        fontSize="sm"
        mb={1}
        color={textColorPrimary}
        fontWeight="500"
        _hover={{ cursor: 'pointer' }}
        {...labelProps}>
        {label}
        <Text fontSize="sm" fontWeight="normal" ms="2px">
          {extra}
        </Text>
        {tooltipTopLabel && (
          <Tooltip
            variant="business"
            hasArrow
            isOpen={isOpenTooltip}
            label={tooltipTopLabel}
            placement="top">
            <span>
              <Icon
                onMouseEnter={onOpenTooltip}
                onMouseLeave={onCloseTooltip}
                onClick={onToggleTooltip}
                as={MdInfoOutline}
                color="brand.400"
                fontSize="24px"
                cursor="pointer"
                ms="4px"
                mt="-2px"
                aria-label={''}
              />
            </span>
          </Tooltip>
        )}
        {switchProps && (
          <Flex>
            <SwitchField {...switchProps} />
          </Flex>
        )}
      </FormLabel>
      <InputGroup>
        <Textarea
          id={id}
          placeholder={placeholder}
          h="44px"
          maxH="44px"
          variant={'main'}
          color={textColorPrimary}
          // bg={bgPrimary}
          border="1px solid"
          borderRadius="16px"
          borderColor={'secondaryGray.100'}
          _placeholder={{ color: 'secondaryGray.500' }}
          _focus={{ borderColor: borderPrimary }}
          {...rest}
        />
        {iconRight && (
          <InputRightElement
            pointerEvents="none"
            children={iconRight}
            color="secondaryGray.500"
          />
        )}
        {iconLeft && (
          <InputRightElement
            pointerEvents="none"
            children={iconLeft}
            color="secondaryGray.500"
          />
        )}
        {tooltipLabel && (
          <InputRightElement>
            <Tooltip
              variant="business"
              hasArrow
              isOpen={isOpenTooltip}
              label={tooltipLabel}
              placement="top">
              <span>
                <Icon
                  onMouseEnter={onOpenTooltip}
                  onMouseLeave={onCloseTooltip}
                  onClick={onToggleTooltip}
                  as={MdInfoOutline}
                  color="brand.400"
                  fontSize="24px"
                  cursor="pointer"
                  ms="2px"
                  aria-label={''}
                />
              </span>
            </Tooltip>
          </InputRightElement>
        )}
      </InputGroup>
    </Flex>
  );
}
