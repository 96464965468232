/*eslint-disable*/

import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { LanguagePicker } from 'components/languagePicker/LanguagePicker';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const textColor = useColorModeValue('gray.400', 'white');
  const { t } = useTranslation();
  return (
    <Flex
      w={{ base: '100%', xl: '1170px' }}
      maxW={{ base: '90%', xl: '1170px' }}
      zIndex="1.5"
      flexDirection={{
        base: 'column',
        xl: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent="space-between"
      px={{ base: '0px', xl: '0px' }}
      pb="30px"
      mx="auto"
    >
      <Text
        color={textColor}
        textAlign={{
          base: 'center',
          xl: 'start',
        }}
        mb={{ base: '20px', xl: '0px' }}
      >
        {' '}
        &copy; {new Date().getFullYear()}{' '}
        <Text as="span" fontWeight="500" ms="4px">
          {t('footer.all_rights_reserved')}
        </Text>
      </Text>

      <LanguagePicker />

      <List display="flex">
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link
            fontWeight="500"
            color={textColor}
            href="mailto:info@brillevent.com"
          >
            {t('footer.support')}
          </Link>
        </ListItem>

        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link
            fontWeight="500"
            color={textColor}
            href="https://brillevent.com/privacy"
          >
            {t('footer.terms_of_service')}
          </Link>
        </ListItem>
        <ListItem>
          <Link
            fontWeight="500"
            color={textColor}
            href="https://brillevent.com/about"
          >
            {t('footer.about')}
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
