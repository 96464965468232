import {
  Flex,
  HStack,
  Icon,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import { FiPlus } from 'react-icons/fi';
import { useCallback, useMemo } from 'react';
import ThreeDotsBtn from 'components/buttons/ThreeDotsBtn';
const defFields: string[] = ['phone', 'address', 'idNumber', 'email'];
const corporateFields: string[] = [
  'companyName',
  'companyAddress',
  'contactPerson',
  'jobTitle',
  'companyEmail',
  'companyPhone',
];
const full_name: string[] = ['firstName', 'lastName'];
const otherRoles: string[] = ['event_planner', 'event_owner'];
const weddingRoles: string[] = [
  'bride',
  'groom',
  'event_planner',
  'event_owner',
];
interface CreateEventContactDetailsProps {
  handleChange: (key: string, value: any, errorKey?: string) => void;
  event: Record<string, any>;
  errorList: string[];
  index: number;
}
export default function CreateEventContactDetails(
  props: CreateEventContactDetailsProps,
) {
  const { handleChange, event, errorList, index } = props;
  const { t } = useTranslation();
  const { onClose, isOpen, onToggle } = useDisclosure();
  const contactDetails = useMemo(
    () => event?.contactDetails[index] || {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [event?.contactDetails[index]],
  );
  const isCorporate = useMemo(() => event?.type === 'corporate', [event?.type]);
  const fields = useMemo(
    () => (isCorporate ? corporateFields : defFields),
    [isCorporate],
  );
  const eventRoles: string[] = useMemo(
    () => (event?.type === 'wedding' ? weddingRoles : otherRoles),
    [event?.type],
  );
  const currentEventRole = useMemo(
    () => contactDetails?.roll || '',
    [contactDetails?.roll],
  );
  const selectedEventRole = useMemo(
    () => (currentEventRole ? [`event_roles.${currentEventRole}`] : []),
    [currentEventRole],
  );
  const handleChangeEventRole = useCallback(
    (selected: string) => {
      const formattedSelected = selected.replace('event_roles.', '');
      const res = event?.contactDetails || [];
      if (selectedEventRole.includes(selected)) {
        res[index] = { ...res[index], roll: '' };
        handleChange('contactDetails', res);
        return;
      }
      res[index] = {
        ...res[index],
        roll: formattedSelected,
      };
      handleChange('contactDetails', res, 'contactDetails.roll');
    },
    [selectedEventRole, handleChange, event?.contactDetails, index],
  );
  console.log('contactDetails: ', contactDetails);
  return (
    <Stack gap={3}>
      {index === 0 && (
        <HStack justify="space-between">
          <Text variant="eventSubTitle">
            {t('create_event.contact_details')}
          </Text>
          {event?.contactDetails?.length === 1 && (
            <Icon
              as={FiPlus}
              bg="brand.400"
              w="32px"
              h="32px"
              cursor="pointer"
              color="white"
              borderRadius="8px"
              onClick={() => {
                handleChange('contactDetails', [...event?.contactDetails, {}]);
              }}
            />
          )}
          {event?.contactDetails?.length > 1 && (
            <Menu isOpen={isOpen} onClose={onClose}>
              <ThreeDotsBtn onClick={onToggle} />
              <MenuList borderRadius="16px">
                <MenuItem
                  py="8px"
                  justifyContent="center"
                  onClick={() => {
                    const res = event?.contactDetails || [];
                    // remove first contact
                    res.splice(0, 1);
                    handleChange('contactDetails', res);
                  }}>
                  <Text variant="underlineRed">
                    {t('create_event.remove_first_contact')}
                  </Text>
                </MenuItem>
                <MenuItem
                  py="8px"
                  justifyContent="center"
                  onClick={() => {
                    const res = event?.contactDetails || [];
                    // remove second contact
                    res.splice(1, 1);
                    handleChange('contactDetails', res);
                  }}>
                  <Text variant="underlineRed">
                    {t('create_event.remove_second_contact')}
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </HStack>
      )}
      <Flex wrap="wrap" gap="40px" alignItems="end">
        {!isCorporate && (
          <Flex gap="18px" w={{ base: '100%', lg: '318px' }}>
            {full_name.map((field, key) => (
              <InputField
                key={key}
                flex="1"
                label={t(`create_event.label.${field}`)}
                placeholder={t(`create_event.placeholder.${field}`)}
                name={field}
                value={contactDetails[field] || ''}
                onChange={(e: any) => {
                  const res = event?.contactDetails || [];
                  res[index] = {
                    ...res[index],
                    [field]: e.target.value,
                  };
                  handleChange(
                    'contactDetails',
                    res,
                    `contactDetails.${field}`,
                  );
                }}
                borderColor={
                  errorList.includes(`contactDetails.${field}`)
                    ? 'error.100'
                    : 'gray.200'
                }
              />
            ))}
          </Flex>
        )}
        {fields.map((field, key) => (
          <InputField
            key={key}
            label={t(`create_event.label.${field}`)}
            placeholder={t(`create_event.placeholder.${field}`)}
            name={field}
            w={{ base: '100%', lg: '318px' }}
            flex="1"
            value={contactDetails[field] || ''}
            onChange={(e: any) => {
              const res = event?.contactDetails || [];
              res[index] = {
                ...res[index],
                [field]: e.target.value,
              };
              handleChange('contactDetails', res, `contactDetails.${field}`);
            }}
            borderColor={
              errorList.includes(`contactDetails.${field}`)
                ? 'error.100'
                : 'gray.200'
            }
          />
        ))}
        {!isCorporate && (
          <DropdownField
            options={eventRoles.map((role) => `event_roles.${role}`)}
            onSelected={handleChangeEventRole}
            selectedOptions={selectedEventRole}
            placeholder={t('create_event.placeholder.roll_in_event')}
            w={{ base: '100%', lg: '318px' }}
            menuButton={{
              borderColor: errorList.includes('contactDetails.roll')
                ? 'error.100'
                : 'gray.200',
            }}
            zIndex={11}
            showedOptions={t(selectedEventRole[0])}
            closeOnSelect
          />
        )}
      </Flex>
    </Stack>
  );
}
