// src/features/suppliers/userSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { PaginatedResults, Supplier } from 'services/@types';
import SupplierService from 'services/supplier.api';
import { closeSplashDialog, showSplashDialog } from '../dialog/dialogsSlice';
import store, { RootState } from '../store';
export interface SupplierState {
  suppliers: Supplier[];
  currentSupplier: Supplier | null;
  supplier: Supplier | null;
  loading: boolean;
  error: string | null;

  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: SupplierState = {
  suppliers: [],
  supplier: null,
  loading: false,
  error: null,
  currentSupplier: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchSuppliers = createAsyncThunk(
  'suppliers/fetchSuppliers',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await SupplierService.getSuppliers(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchSupplier = createAsyncThunk(
  'supplier/fetchSupplier',
  async (supplierId: string, { rejectWithValue }) => {
    try {
      const response = await SupplierService.getSupplier(supplierId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createSupplier = createAsyncThunk(
  'supplier/createSupplier',
  async (
    { invite, supplier }: { invite: boolean; supplier: Partial<Supplier> },
    { rejectWithValue },
  ) => {
    try {
      store.dispatch(
        showSplashDialog({
          title: 'signup.onboarding.supplier_info.creating_supplier',
        }),
      );
      const newSupplier = await SupplierService.createSupplier(
        {
          ...supplier,
        },
        invite,
      );
      console.log('#Redux create supplier ->', newSupplier);
      store.dispatch(closeSplashDialog());

      return newSupplier;
    } catch (error) {
      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);

export const updateSupplier = createAsyncThunk(
  'bussiness/updateSupplier',
  async (
    { supplierId, updates }: { supplierId: string; updates: Partial<Supplier> },
    { rejectWithValue },
  ) => {
    try {
      delete updates.id;
      delete updates.createdAt;
      const response = await SupplierService.updateSupplier(
        supplierId,
        updates,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateSupplierIsFavorite = createAsyncThunk(
  'supplier/updateSupplierIsFavorite',
  async (
    { supplierId, isFavorite }: { supplierId: string; isFavorite: boolean },
    { rejectWithValue },
  ) => {
    try {
      const response = await SupplierService.updateSupplier(supplierId, {
        isFavorite,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteSupplier = createAsyncThunk(
  'supplier/deleteSupplier',
  async (supplierId: string, { rejectWithValue }) => {
    try {
      await SupplierService.deleteSupplier(supplierId);
      return supplierId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
    setCurrentSupplier: (state, action: PayloadAction<Supplier | null>) => {
      state.currentSupplier = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all suppliers
      .addCase(fetchSuppliers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchSuppliers.fulfilled,
        (state, action: PayloadAction<PaginatedResults<Supplier>>) => {
          state.suppliers = action.payload.results;
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      .addCase(fetchSuppliers.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch a single user
      .addCase(fetchSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchSupplier.fulfilled,
        (state, action: PayloadAction<Supplier>) => {
          state.supplier = action.payload;
          state.loading = false;
        },
      )
      .addCase(fetchSupplier.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create a user
      .addCase(createSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createSupplier.fulfilled,
        (state, action: PayloadAction<Supplier>) => {
          state.supplier = action.payload;
          state.loading = false;
          state.suppliers.push(action.payload);
        },
      )
      .addCase(createSupplier.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update a user
      .addCase(updateSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateSupplier.fulfilled,
        (state, action: PayloadAction<Supplier>) => {
          const index = state.suppliers.findIndex(
            (user) => user.id === action.payload.id,
          );
          if (index !== -1) {
            state.suppliers[index] = action.payload;
          }
          state.supplier = action.payload;
          state.loading = false;
        },
      )
      .addCase(updateSupplier.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete a user
      .addCase(deleteSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteSupplier.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.suppliers = state.suppliers.filter(
            (user) => user.id !== action.payload,
          );
          state.loading = false;
        },
      )
      .addCase(deleteSupplier.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateSupplierIsFavorite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateSupplierIsFavorite.fulfilled,
        (state, action: PayloadAction<Supplier>) => {
          const index = state.suppliers.findIndex(
            (user) => user.id === action.payload.id,
          );
          if (index !== -1) {
            state.suppliers[index].isFavorite = action.payload.isFavorite;
            state.currentSupplier = action.payload;
          }
          state.loading = false;
        },
      )
      .addCase(
        updateSupplierIsFavorite.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      );
  },
});

export function useMySupplier() {
  const supplier = useSelector((state: RootState) => state.suppliers.supplier);
  return supplier;
}

export const { setCurrentSupplier } = userSlice.actions;

export default userSlice.reducer;
