// src/features/integrations/userSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IIntegration, PaginatedResults } from 'services/@types';
import IIntegrationService from 'services/integration.api';
export interface IntegrationState {
  integrations: IIntegration[];
  loading: boolean;
  loadingId: string | null;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: IntegrationState = {
  integrations: [],
  loadingId: null,
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchIntegrations = createAsyncThunk(
  'integrations/fetchIntegrations',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await IIntegrationService.getIntegrations(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchIntegration = createAsyncThunk(
  'integrations/fetchIIntegration',
  async (integrationId: string, { rejectWithValue }) => {
    try {
      const response = await IIntegrationService.getIIntegration(integrationId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateIntegration = createAsyncThunk(
  'integrations/updateIIntegration',
  async (
    { userId, updates }: { userId: string; updates: Partial<IIntegration> },
    { rejectWithValue },
  ) => {
    try {
      const response = await IIntegrationService.updateIIntegration(
        userId,
        updates,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteIIntegration = createAsyncThunk(
  'integrations/deleteIIntegration',
  async (integrationid: string, { rejectWithValue }) => {
    try {
      await IIntegrationService.deleteIIntegration(integrationid);
      return integrationid;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
  },
  extraReducers: (builder) => {
    builder
      // Fetch all businesses
      .addCase(fetchIntegrations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchIntegrations.fulfilled,
        (state, action: PayloadAction<PaginatedResults<IIntegration>>) => {
          state.integrations = action.payload.results;
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      .addCase(
        fetchIntegrations.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      .addCase(updateIntegration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateIntegration.fulfilled,
        (state, action: PayloadAction<IIntegration>) => {
          const index = state.integrations.findIndex(
            (integration) => integration.id === action.payload.id,
          );
          if (index !== -1) {
            state.integrations[index] = action.payload;
          }
          state.loading = false;
        },
      )
      .addCase(
        updateIntegration.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      // Delete a user
      .addCase(deleteIIntegration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteIIntegration.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.integrations = state.integrations.filter(
            (integration) => integration.id !== action.payload,
          );
          state.loading = false;
        },
      )
      .addCase(
        deleteIIntegration.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      );
  },
});

export default userSlice.reducer;
