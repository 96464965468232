// Others
// import OthersError from 'views/admin/main/others/404';

// Auth Imports
// import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered';
// import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault';
// import LockCentered from 'views/auth/lock/LockCentered';
// import LockDefault from 'views/auth/lock/LockDefault';
// import VerificationCentered from 'views/auth/verification/VerificationCentered';
// import VerificationDefault from 'views/auth/verification/VerificationDefault';

import GetStarted from 'views/auth/getStarted';
import SignIn from 'views/auth/signIn/index';
import SignUp from 'views/auth/signUp/SignUpCentered';

// icons
import calendarIcon from 'assets/svg/sidebar/calendar.svg';
import dashboardsIcon from 'assets/svg/sidebar/dashboards.svg';
import financeIcon from 'assets/svg/sidebar/finance.svg';
import managmentIcon from 'assets/svg/sidebar/managment.svg';
import operationsIcon from 'assets/svg/sidebar/operations.svg';
import settingsIcon from 'assets/svg/sidebar/settings.svg';

import { Image } from '@chakra-ui/react';
import InviteSeat from 'views/auth/inviteSeat';
import InviteSupplier from 'views/auth/inviteSupplier';
import QuoteReqest from 'views/auth/quoteRequest';
import BusinesSettings from 'views/main/businessSettings';
import Dashboard from 'views/main/dashboard';
import Gallery from 'views/main/gallery';
import CalendarFull from 'views/main/managment/calendar';
import Events from 'views/main/managment/events';
import CreateMeeting from 'views/main/managment/meeting/createMeeting';
import Products from 'views/main/managment/products';
import Suppliers from 'views/main/managment/suppliers';
import ProfileSettings from 'views/main/profileSettings';
import CreateTask from 'views/main/tasks/createTask';

const routes = [
  // --- Main routes ---
  {
    isSidebarVisible: false,
    name: 'Authentication',
    path: '/auth',
    collapse: true,
    items: [
      {
        isSidebarVisible: false,
        name: 'Sign In',
        path: '/sign-in',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-in/',
            component: <SignIn />,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Get Started',
        path: '/get-started',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/get-started/',
            component: <GetStarted />,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Sign Up',
        path: '/sign-up',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-up/',
            component: <SignUp />,
          },
          {
            isSidebarVisible: true,
            name: 'route_name.suppliers',
            layout: '/main',
            path: '/managment/suppliers',
            component: <Suppliers />,
          },
          {
            isSidebarVisible: true,
            name: 'route_name.clients',
            layout: '/main',
            path: '/managment/clients',
            component: <></>,
          },
          {
            isSidebarVisible: true,
            name: 'route_name.products',
            layout: '/main',
            path: '/managment/products',
            component: <Products />,
          },
          {
            isSidebarVisible: true,
            name: 'route_name.calendar',
            layout: '/main',
            path: '/managment/calendar',
            component: <></>,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Invited Seat',
        path: '/invite-seat',
        collapse: true,
        items: [
          {
            name: 'Landing',
            layout: '/auth',
            path: '/invite-seat/',
            component: <InviteSeat />,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Invited Supplier',
        path: '/invite-supplier',
        collapse: true,
        items: [
          {
            name: 'Landing',
            layout: '/auth',
            path: '/invite-supplier/',
            component: <InviteSupplier />,
          },
        ],
      },
      {
        isSidebarVisible: false,
        name: 'Quote Request',
        path: '/quote-request',
        collapse: true,
        items: [
          {
            name: 'Landing',
            layout: '/auth',
            path: '/quote-request/',
            component: <QuoteReqest />,
          },
        ],
      },
    ],
  },
  {
    isSidebarVisible: true,
    name: 'route_name.dashboard',
    path: '/dashboard',
    icon: <Image src={dashboardsIcon} alt="" />,
    collapse: true,
    items: [
      {
        isSidebarVisible: true,
        name: 'route_name.overview',
        layout: '/main',
        path: '/dashboard/overview',
        component: <Dashboard />,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.key_metrics',
        layout: '/main',
        path: '/dashboard/key_metrics',
        component: <></>,
      },
    ],
  },
  {
    isSidebarVisible: true,
    name: 'route_name.calendar',
    path: '/dashboard/calendar',
    icon: <Image src={calendarIcon} alt="" />,
    collapse: false,
    layout: '/main',
    component: <CalendarFull />,
  },
  {
    isSidebarVisible: true,
    name: 'route_name.managment',
    path: '/managment',
    icon: <Image src={managmentIcon} alt="" />,
    collapse: true,
    items: [
      {
        isSidebarVisible: true,
        name: 'route_name.events',
        layout: '/main',
        path: '/managment/events',
        component: <Events />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.events',
        layout: '/main',
        path: '/managment/events/:id',
        component: <Events />,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.suppliers',
        layout: '/main',
        path: '/managment/suppliers',
        component: <Suppliers />,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.clients',
        layout: '/main',
        path: '/managment/clients',
        component: <></>,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.products',
        layout: '/main',
        path: '/managment/products',
        component: <></>,
      },
    ],
  },
  {
    isSidebarVisible: true,
    name: 'route_name.finance',
    path: '/finance',
    icon: <Image src={financeIcon} alt="" />,
    collapse: true,
    items: [
      {
        isSidebarVisible: true,
        name: 'route_name.payments',
        layout: '/main',
        path: '/finance/payments',
        component: <></>,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.expenses',
        layout: '/main',
        path: '/finance/expenses',
        component: <></>,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.quotations',
        layout: '/main',
        path: '/finance/quotations',
        component: <></>,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.contracts',
        layout: '/main',
        path: '/finance/contracts',
        component: <></>,
      },
    ],
  },
  {
    isSidebarVisible: true,
    name: 'route_name.operations',
    path: '/operations',
    icon: <Image src={operationsIcon} alt="" />,
    collapse: true,
    items: [
      {
        isSidebarVisible: true,
        name: 'route_name.files',
        layout: '/main',
        path: '/operations/files',
        component: <></>,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.archive',
        layout: '/main',
        path: '/operations/archive',
        component: <></>,
      },
    ],
  },
  // --- Account settings ---
  {
    isSidebarVisible: true,
    name: 'route_name.settings',
    path: '/settings',
    icon: <Image src={settingsIcon} alt="" />,
    collapse: true,
    items: [
      {
        isSidebarVisible: true,
        name: 'route_name.business_settings',
        layout: '/main',
        path: '/settings/business',
        component: <BusinesSettings />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.profile_settings',
        layout: '/main',
        path: '/settings/profile',
        component: <ProfileSettings />,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.gallery',
        layout: '/main',
        path: '/settings/gallery',
        component: <Gallery />,
      },

      {
        isSidebarVisible: true,
        name: 'route_name.billing',
        layout: '/main',
        path: '/settings/billing',
        component: <></>,
      },
      {
        isSidebarVisible: true,
        name: 'route_name.notifications',
        layout: '/main',
        path: '/settings/notifications',
        component: <></>,
      },
    ],
  },

  // --- Tasks ---
  {
    isSidebarVisible: false,
    name: 'route_name.tasks',
    path: '/tasks',
    icon: <Image src={settingsIcon} alt="" />,
    collapse: true,
    items: [
      {
        isSidebarVisible: false,
        name: 'route_name.tasks',
        layout: '/main',
        path: '/tasks',
        component: <CreateTask />,
      },
      {
        isSidebarVisible: false,
        name: 'route_name.tasks',
        layout: '/main',
        path: '/tasks/:id',
        component: <CreateTask />,
      },
    ],
  },
  {
    isSidebarVisible: false,
    name: 'route_name.meetings',
    path: '/meetings',
    icon: <Image src={settingsIcon} alt="" />,
    collapse: true,
    items: [
      {
        isSidebarVisible: false,
        name: 'route_name.meetings',
        layout: '/main',
        path: '/meetings',
        component: <CreateMeeting />,
      },
    ],
  },

  // --- NFTs ---
  // {
  //   name: 'NFTs',
  //   path: '/nfts',
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   collapse: true,
  //   items: [
  //     {
  //       name: 'Marketplace',
  //       layout: '/admin',
  //       path: '/nfts/marketplace',
  //       component: <NFTMarketplace />,
  //       secondary: true,
  //     },
  //     {
  //       name: 'Collection',
  //       layout: '/admin',
  //       path: '/nfts/collection',
  //       component: <NFTCollection />,
  //       secondary: true,
  //     },
  //     {
  //       name: 'NFT Page',
  //       layout: '/admin',
  //       path: '/nfts/page',
  //       component: <NFTPage />,
  //       secondary: true,
  //     },
  //     {
  //       name: 'Profile',
  //       layout: '/admin',
  //       path: '/nfts/profile',
  //       component: <NFTProfile />,
  //       secondary: true,
  //     },
  //   ],
  // },
  // --- Main pages ---
  // {
  //   name: 'Main Pages',
  //   path: '/main',
  //   icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
  //   collapse: true,
  //   items: [
  //     {
  //       name: 'Account',
  //       path: '/main/account',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Billing',
  //           layout: '/admin',
  //           path: '/main/account/billing',
  //           exact: false,
  //           component: <AccountBilling />,
  //         },
  //         {
  //           name: 'Application',
  //           layout: '/admin',
  //           path: '/main/account/application',
  //           exact: false,
  //           component: <AccountApplications />,
  //         },
  //         {
  //           name: 'Invoice',
  //           layout: '/admin',
  //           path: '/main/account/invoice',
  //           exact: false,
  //           component: <AccountInvoice />,
  //         },
  //         {
  //           name: 'Settings',
  //           layout: '/admin',
  //           path: '/main/account/settings',
  //           exact: false,
  //           component: <AccountSettings />,
  //         },
  //         {
  //           name: 'All Courses',
  //           layout: '/admin',
  //           path: '/main/account/all-courses',
  //           exact: false,
  //           component: <AccountAllCourses />,
  //         },
  //         {
  //           name: 'Course Page',
  //           layout: '/admin',
  //           path: '/main/account/course-page',
  //           exact: false,
  //           component: <AccountCoursePage />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Ecommerce',
  //       path: '/main/ecommerce',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'New Product',
  //           layout: '/admin',
  //           path: '/main/ecommerce/new-prodcut',
  //           exact: false,
  //           component: <EcommerceNewProduct />,
  //         },
  //         {
  //           name: 'Product Overview',
  //           layout: '/admin',
  //           path: '/main/ecommerce/product-overview',
  //           exact: false,
  //           component: <EcommerceProductOverview />,
  //         },
  //         {
  //           name: 'Product Settings',
  //           layout: '/admin',
  //           path: '/main/ecommerce/settings',
  //           exact: false,
  //           component: <EcommerceProductSettings />,
  //         },
  //         {
  //           name: 'Product Page',
  //           layout: '/admin',
  //           path: '/main/ecommerce/page-example',
  //           exact: false,
  //           component: <EcommerceProductPage />,
  //         },
  //         {
  //           name: 'Order List',
  //           layout: '/admin',
  //           path: '/main/ecommerce/order-list',
  //           exact: false,
  //           component: <EcommerceOrderList />,
  //         },
  //         {
  //           name: 'Order Details',
  //           layout: '/admin',
  //           path: '/main/ecommerce/order-details',
  //           exact: false,
  //           component: <EcommerceOrderDetails />,
  //         },
  //         {
  //           name: 'Referrals',
  //           layout: '/admin',
  //           path: '/main/ecommerce/referrals',
  //           exact: false,
  //           component: <EcommerceReferrals />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Users',
  //       path: '/main/users',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'New User',
  //           layout: '/admin',
  //           path: '/main/users/new-user',
  //           exact: false,
  //           component: <UserNew />,
  //         },
  //         {
  //           name: 'Users Overview',
  //           layout: '/admin',
  //           path: '/main/users/users-overview',
  //           exact: false,
  //           component: <UsersOverview />,
  //         },
  //         {
  //           name: 'Users Reports',
  //           layout: '/admin',
  //           path: '/main/users/users-reports',
  //           exact: false,
  //           component: <UsersReports />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Applications',
  //       path: '/main/applications',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Kanban',
  //           layout: '/admin',
  //           path: '/main/applications/kanban',
  //           exact: false,
  //           component: <ApplicationsKanban />,
  //         },
  //         {
  //           name: 'Data Tables',
  //           layout: '/admin',
  //           path: '/main/applications/data-tables',
  //           exact: false,
  //           component: <ApplicationsDataTables />,
  //         },
  //         {
  //           name: 'Calendar',
  //           layout: '/admin',
  //           path: '/main/applications/calendar',
  //           exact: false,
  //           component: <ApplicationsCalendar />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Profile',
  //       path: '/main/profile',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Profile Overview',
  //           layout: '/admin',
  //           path: '/main/profile/overview',
  //           exact: false,
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: 'Profile Settings',
  //           layout: '/admin',
  //           path: '/main/profile/settings',
  //           exact: false,
  //           component: <ProfileSettings />,
  //         },
  //         {
  //           name: 'News Feed',
  //           layout: '/admin',
  //           path: '/main/profile/newsfeed',
  //           exact: false,
  //           component: <ProfileNewsfeed />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Others',
  //       path: '/main/others',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Notifications',
  //           layout: '/admin',
  //           path: '/main/others/notifications',
  //           exact: false,
  //           component: <OthersNotifications />,
  //         },
  //         {
  //           name: 'Pricing',
  //           layout: '/auth',
  //           path: '/main/others/pricing',
  //           exact: false,
  //           component: <OthersPricing />,
  //         },
  //         // {
  //         //   name: '404',
  //         //   layout: '/admin',
  //         //   path: '/main/others/404',
  //         //   exact: false,
  //         //   component: <OthersError />,
  //         // },
  //         {
  //           name: 'Messages',
  //           layout: '/admin',
  //           path: '/main/others/messages',
  //           exact: false,
  //           component: <Messages />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // --- Authentication ---
  // {
  //   name: 'Authentication',
  //   path: '/auth',
  //   icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
  //   collapse: true,
  //   items: [
  //     // --- Sign In ---
  //     {
  //       name: 'Sign In',
  //       path: '/sign-in',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Default',
  //           layout: '/auth',
  //           path: '/sign-in/default',
  //           component: <SignInDefault />,
  //         },
  //         {
  //           name: 'Centered',
  //           layout: '/auth',
  //           path: '/sign-in/centered',
  //           component: <SignInCentered />,
  //         },
  //       ],
  //     },
  //     // --- Sign Up ---
  //     {
  //       name: 'Sign Up',
  //       path: '/sign-up',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Default',
  //           layout: '/auth',
  //           path: '/sign-up/default',
  //           component: <SignUpDefault />,
  //         },
  //         // {
  //         //   name: 'Centered',
  //         //   layout: '/auth',
  //         //   path: '/sign-up/centered',
  //         //   component: <SignUpCentered />,
  //         // },
  //       ],
  //     },
  //     // --- Verification ---
  //     {
  //       name: 'Verification',
  //       path: '/verification',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Default',
  //           layout: '/auth',
  //           path: '/verification/default',
  //           component: <VerificationDefault />,
  //         },
  //         {
  //           name: 'Centered',
  //           layout: '/auth',
  //           path: '/verification/centered',
  //           component: <VerificationCentered />,
  //         },
  //       ],
  //     },
  //     // --- Lock ---
  //     {
  //       name: 'Lock',
  //       path: '/lock',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Default',
  //           layout: '/auth',
  //           path: '/lock/default',
  //           component: <LockDefault />,
  //         },
  //         {
  //           name: 'Centered',
  //           layout: '/auth',
  //           path: '/lock/centered',
  //           component: <LockCentered />,
  //         },
  //       ],
  //     },
  //     // --- Forgot Password ---
  //     {
  //       name: 'Forgot Password',
  //       path: '/forgot-password',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Default',
  //           layout: '/auth',
  //           path: '/forgot-password/default',
  //           component: <ForgotPasswordDefault />,
  //         },
  //         {
  //           name: 'Centered',
  //           layout: '/auth',
  //           path: '/forgot-password/centered',
  //           component: <ForgotPasswordCentered />,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default routes;
