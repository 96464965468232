import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import AuthServices from '../../../services/auth.api';
import { clearCredentials } from '../../../contexts/redux/auth/authSlice';
import {
  closeSplashDialog,
  showSplashDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { clearCookie } from 'utils/cookie.util';
import { resetBusiness } from 'contexts/redux/business/businessSlice';
export const useSignout = () => {
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(false);
  const signOut = useCallback(async () => {
    setLoading(true);
    dispatch(showSplashDialog({ title: 'dialogs.splash.signning_out' }));
    clearCookie('auth');
    setTimeout(
      async () => {
        try {
          await AuthServices.logout();
        } catch (e) {
          console.error('Error logging out: ', e);
        }
        dispatch(closeSplashDialog());
        dispatch(clearCredentials());
        setLoading(false);
        dispatch(resetBusiness());
      },
      process.env.NODE_ENV === 'production' ? 2000 : 500,
    );
  }, [dispatch]);

  return { signOut, loading };
};
