import {
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Menu,
  MenuItem,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import ThreeDotsBtn from 'components/buttons/ThreeDotsBtn';
// Custom components
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { createInvite } from 'contexts/redux/invite/inviteSlice';
import { RootState } from 'contexts/redux/store';
import {
  createSupplier,
  setCurrentSupplier,
  updateSupplier,
} from 'contexts/redux/supplier/supplierSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Business, ContactPerson, Supplier } from 'services/@types';
import { isValidEmail } from 'utils/isValidEmail';
import { useFindSupplier } from '../hooks/useFindBusiness';
import { AddContactPerson } from './AddContactPerson';

export interface AddSupplierModalProps {
  isOpen: boolean;
  onClose: () => void;
  editableSupplier?: Partial<Supplier>;
  onFoundExistingSupplier: (supplier: Business) => void;
}

export const AddSupplierModal: React.FC<AddSupplierModalProps> = ({
  isOpen,
  onClose,
  editableSupplier,
  onFoundExistingSupplier,
}) => {
  const dispatch = useDispatch<any>();
  const myBusiness = useMyBusiness();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const {
    isOpen: isContactMenuOpen,
    onOpen: onContactMenuOpen,
    onClose: onContactMenuClose,
  } = useDisclosure();

  const toast = useToast();
  const { t } = useTranslation();
  const [supplierProps, setSupplierProps] = useState<Partial<Supplier>>({});
  const [errorLabels, setErrorLabels] = useState<{
    name: string;
    email: string;
    category: string;
  }>({ name: '', email: '', category: '' });
  const [inviteCheckbox, setInviteCheckbox] = useState(false);
  const { businessCategories } = useSelector(
    (state: RootState) => state.businessCategories,
  );
  const { loading } = useSelector((state: RootState) => state.suppliers);
  const handleSupplierAction = useCallback((business: Business) => {
    setSupplierProps({
      businessID: business.id,
      phone: business.phone,
      name: business.businessName,
      email: business.email,
      address: business.address,
      vatId: business.businessVATId,
      category: business.category,
    });
  }, []);
  const { findBusinessBy, loadingExistingSupplier } =
    useFindSupplier(handleSupplierAction);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorLabels({ name: '', email: '', category: '' });
      setSupplierProps({ ...supplierProps, [e.target.id]: e.target.value });
    },
    [supplierProps],
  );
  const handleChangeBankDetails = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorLabels({ name: '', email: '', category: '' });
      setSupplierProps({
        ...supplierProps,
        bankDetails: {
          ...supplierProps.bankDetails,
          [e.target.id]: e.target.value,
        },
      });
    },
    [supplierProps],
  );

  const handleClose = useCallback(() => {
    onClose();
    setErrorLabels({ name: '', email: '', category: '' });
  }, [onClose]);

  const handleContactChange = useCallback(
    (contactDetails: ContactPerson, index: number) => {
      if (Object.keys(contactDetails).length === 0) {
        console.log('empty contact...');
        return;
      }
      setSupplierProps((prevProps) => {
        const newContacts = prevProps.contacts ? [...prevProps.contacts] : [];
        newContacts[index] = contactDetails;
        console.log('new contacts ->', newContacts);
        return { ...prevProps, contacts: newContacts };
      });
    },
    [],
  );

  useEffect(() => {
    console.log('add supplier modal ->', editableSupplier);
    if (editableSupplier) {
      setSupplierProps(editableSupplier);
    } else {
      setSupplierProps({});
    }
  }, [editableSupplier, isOpen, handleClose]);

  const validate = useCallback(() => {
    if (!supplierProps.name) {
      setErrorLabels((prev) => ({
        ...prev,
        name: t('suppliers.errors.name'),
      }));
      return false;
    }
    if (!supplierProps.email) {
      setErrorLabels((prev) => ({
        ...prev,
        email: t('suppliers.errors.email'),
      }));
      return false;
    }
    if (!isValidEmail(supplierProps.email)) {
      setErrorLabels((prev) => ({
        ...prev,
        email: t('suppliers.errors.invalid_email'),
      }));
      return false;
    }
    if (!supplierProps.category || supplierProps.category.length === 0) {
      setErrorLabels((prev) => ({
        ...prev,
        category: t('suppliers.errors.category'),
      }));
      return false;
    }
    return true;
  }, [supplierProps?.name, supplierProps?.email, supplierProps?.category, t]);

  const onAddContact = useCallback(() => {
    if (supplierProps?.contacts?.length === 2) {
      console.debug('max contacts reached');
      return;
    }
    if (!supplierProps.contacts || supplierProps.contacts?.length === 0) {
      setSupplierProps((prev) => ({ ...prev, contacts: [{}] }));
    }
    setSupplierProps((prev) => ({
      ...prev,
      contacts: [
        ...prev.contacts,
        {
          name: '',
          email: '',
          phone: '',
        },
      ],
    }));
    console.log('contacts ->', supplierProps.contacts);
  }, [supplierProps]);
  const onRemoveContactB = useCallback(() => {
    console.log('remove contact');
    if (supplierProps.contacts?.length === 2) {
      supplierProps.contacts?.pop();
    }
  }, [supplierProps.contacts]);
  console.log('add supplier modal contacts ->', supplierProps.contacts);
  const handleSave = useCallback(async () => {
    if (!validate()) {
      return;
    }
    if (editableSupplier) {
      const res = await dispatch(
        updateSupplier({
          supplierId: supplierProps.id,
          updates: supplierProps,
        }),
      );
      if (res?.payload) {
        dispatch(setCurrentSupplier(res.payload));
      }
      toast({
        variant: 'main',
        title: t('suppliers.toast.supplier_updated'),
      });
    } else {
      dispatch(
        createSupplier({ invite: inviteCheckbox, supplier: supplierProps }),
      );
      if (inviteCheckbox) {
        dispatch(
          createInvite({
            email: supplierProps.email,
            businessID: myBusiness.id,
            type: 'supplier',
            tags: supplierProps.tags,
            fullName: supplierProps.name,
            phone: supplierProps.phone,
          }),
        );
      }
      toast({
        variant: 'main',
        title: t('suppliers.toast.supplier_added'),
      });
    }
    handleClose();
  }, [
    dispatch,
    editableSupplier,
    inviteCheckbox,
    myBusiness?.id,
    handleClose,
    supplierProps,
    t,
    toast,
    validate,
  ]);
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius={'3xl'}
        mx={2}
        maxW={'1102px'}
        px={'20px'}
        pb={'20px'}
        pt={'48px'}>
        <ModalHeader p={0}>
          <HStack gap={2} align={{ sm: 'start', md: 'center' }}>
            <VStack align="start" flex={1} spacing={0}>
              <Text variant="cardTitle">
                {editableSupplier
                  ? t('suppliers.edit_supplier')
                  : t('suppliers.add_supplier')}
              </Text>
              <Text variant="cardSubTitle">
                {t('suppliers.modals.add_supplier.title')}
              </Text>
            </VStack>
            <Button
              variant="h1cta"
              ms="auto"
              px={{ base: 4, md: 4 }}
              onClick={handleSave}
              isLoading={loading}>
              {t('suppliers.save')}
            </Button>
            <ModalCloseButton right={0} position={'relative'} top={0} />
          </HStack>
        </ModalHeader>
        <Flex mt={'24px'} direction="column" align="center" position="relative">
          <Flex p={0} w="100%">
            <Flex
              direction="row"
              w="100%"
              gap={6}
              wrap="wrap"
              justify={'space-between'}>
              <VStack
                gap={4}
                flex={1}
                align="start"
                minWidth={310}
                maxW={{ base: '100%', md: 310 }}>
                <InputField
                  id="name"
                  w="100%"
                  value={supplierProps?.name}
                  placeholder={t('suppliers.modals.add_supplier.business_name')}
                  onChange={handleChange}
                  label={t('suppliers.modals.add_supplier.business_name')}
                  errorLabel={errorLabels.name}
                />
                <InputField
                  value={supplierProps?.email}
                  id="email"
                  isLoading={loadingExistingSupplier}
                  onBlur={(e: any) => {
                    if (
                      !editableSupplier &&
                      e.target.value &&
                      e.target.value.includes('@')
                    ) {
                      findBusinessBy(e.target.value, null);
                    }
                  }}
                  w="100%"
                  onChange={handleChange}
                  placeholder={t('suppliers.modals.add_supplier.email_address')}
                  label={t('suppliers.modals.add_supplier.email_address')}
                  errorLabel={errorLabels.email}
                />
                <Checkbox
                  display={editableSupplier?.id ? 'none' : 'flex'}
                  checked={inviteCheckbox}
                  onChange={(e: any) => setInviteCheckbox(e.target.checked)}>
                  <Text fontSize="sm" color={'brand.900'}>
                    {t('suppliers.modals.add_supplier.invite_to_platform')}
                  </Text>
                </Checkbox>

                <InputField
                  id="phone"
                  value={supplierProps?.phone}
                  w="100%"
                  onBlur={(e: any) => {
                    if (!editableSupplier && e.target.value) {
                      findBusinessBy(null, e.target.value);
                    }
                  }}
                  onChange={handleChange}
                  placeholder="eg. 051-2332456"
                  label={t('suppliers.modals.add_supplier.business_phone')}
                />

                <InputField
                  id="address"
                  w="100%"
                  value={supplierProps?.address}
                  onChange={handleChange}
                  placeholder={t(
                    'suppliers.modals.add_supplier.business_address',
                  )}
                  label={t('suppliers.modals.add_supplier.business_address')}
                />
                <InputField
                  id="vatId"
                  value={supplierProps?.vatId}
                  w="100%"
                  onChange={handleChange}
                  placeholder="eg. 123456789"
                  label={t('suppliers.modals.add_supplier.business_vat_id')}
                />
                <DropdownField
                  w="100%"
                  label={t('business_settings.business_category')}
                  options={businessCategories?.map(
                    (category) => `categories.${category.name}`,
                  )}
                  placeholder={t('suppliers.supplier_category_placeholder')}
                  onSelected={(selected: any) => {
                    console.log('on selected ->', selected);
                    const selectedCategory = businessCategories.find(
                      (bc) => bc.name === selected.split('.').pop(),
                    );
                    const newCategories = [...(supplierProps?.category || [])];
                    if (!newCategories.includes(selectedCategory?.id)) {
                      newCategories.push(selectedCategory?.id as string);
                    } else {
                      newCategories.splice(
                        newCategories.indexOf(selectedCategory?.id as string),
                        1,
                      );
                    }

                    console.log('selected cat ->', newCategories);
                    setSupplierProps({
                      ...supplierProps,
                      category: newCategories,
                    });
                    setErrorLabels({ name: '', email: '', category: '' });
                  }}
                  name="category"
                  selectedOptions={supplierProps?.category?.map((sc) => {
                    const selectedCategory = businessCategories.find(
                      (bc) => bc.id === sc,
                    );
                    return `categories.${selectedCategory?.name}`;
                  })}
                  menuButton={{ w: { base: '100%', md: '310px' } }}
                  error={errorLabels.category}
                  showedOptions={supplierProps?.category
                    ?.map((sc) => {
                      const selectedCategory = businessCategories.find(
                        (bc) => bc.id === sc,
                      );
                      return t(`categories.${selectedCategory?.name}`);
                    })
                    .join(',')}
                />
              </VStack>

              <VStack
                gap={4}
                flex={1}
                minWidth={310}
                maxW={{ base: '100%', md: 310 }}>
                {/* business id, categories, tag services, internal notes */}

                <InputField
                  id="bankName"
                  value={supplierProps?.bankDetails?.bankName}
                  w="100%"
                  onChange={handleChangeBankDetails}
                  placeholder="eg. 123456789"
                  label={t('suppliers.modals.add_supplier.bank_name')}
                />
                <InputField
                  id="bankBranch"
                  value={supplierProps?.bankDetails?.bankBranch}
                  w="100%"
                  onChange={handleChangeBankDetails}
                  placeholder="eg. 123456789"
                  label={t('suppliers.modals.add_supplier.bank_branch')}
                />
                <InputField
                  id="accountNumber"
                  value={supplierProps?.bankDetails?.accountNumber}
                  w="100%"
                  onChange={handleChangeBankDetails}
                  placeholder="eg. 123456789"
                  label={t('suppliers.modals.add_supplier.account_number')}
                />

                <InputField
                  id="serviceTypes"
                  w="100%"
                  isDisabled
                  value={supplierProps?.serviceTypes?.join(',') || ''}
                  onChange={handleChange}
                  placeholder="eg. Delivery, Pickup"
                  label={t('suppliers.modals.add_supplier.tag_services')}
                />
                <TextField
                  id="internalNotes"
                  w="100%"
                  value={supplierProps?.internalNotes}
                  minH={130}
                  onChange={handleChange}
                  tooltipTopLabel={t(
                    'suppliers.modals.add_supplier.internal_notes_tooltip',
                  )}
                  placeholder="eg. Notes about the supplier"
                  label={t('suppliers.modals.add_supplier.internal_notes')}
                />
              </VStack>
              <HStack
                align="start"
                justify={'start'}
                gap={4}
                w={{ base: '100%', md: 'fit-content' }}>
                <Divider
                  orientation={'vertical'}
                  display={{ base: 'none', md: 'block' }}
                />
                <VStack
                  align="start"
                  flex={1}
                  minWidth={310}
                  maxW={{ base: '100%', md: 310 }}>
                  <HStack w="100%">
                    <Text fontSize={'xl'} fontWeight="bold" flex={1}>
                      {t('suppliers.modals.add_supplier.contact_person')}
                    </Text>
                    <Menu
                      isOpen={isContactMenuOpen}
                      onOpen={onContactMenuOpen}
                      onClose={onContactMenuClose}>
                      <ThreeDotsBtn
                        onClick={onContactMenuOpen}
                        mb="0px"
                        me="8px"
                      />
                      <MenuList
                        minW="unset"
                        maxW="180px !important"
                        border="transparent"
                        backdropFilter="blur(63px)"
                        borderRadius="20px"
                        py="10px"
                        px="14px">
                        <MenuItem
                          transition="0.2s linear"
                          color={textColor}
                          onClick={onAddContact}
                          py={2}
                          px={0}
                          borderRadius="8px"
                          display={
                            supplierProps.contacts?.length === 2
                              ? 'none'
                              : 'block'
                          }
                          _active={{
                            bg: 'transparent',
                          }}
                          _focus={{
                            bg: 'transparent',
                          }}>
                          <Text fontSize="sm" fontWeight="400">
                            {t('suppliers.modals.contact_person.menu.add')}
                          </Text>
                        </MenuItem>
                        <MenuItem
                          py={2}
                          transition="0.2s linear"
                          px="0px"
                          display={
                            supplierProps?.contacts?.length > 1
                              ? 'block'
                              : 'none'
                          }
                          borderRadius="8px"
                          color={textColor}
                          onClick={onRemoveContactB}
                          _active={{
                            bg: 'transparent',
                          }}
                          _focus={{
                            bg: 'transparent',
                          }}>
                          <Text color="red.500" fontSize="sm" fontWeight="400">
                            {t('suppliers.modals.contact_person.menu.remove_b')}
                          </Text>
                        </MenuItem>
                      </MenuList>{' '}
                    </Menu>
                  </HStack>
                  <AddContactPerson
                    label={t('suppliers.modals.contact_person.contact_a')}
                    contact={supplierProps.contacts?.[0] || {}}
                    handleContactChange={(contact) =>
                      handleContactChange(contact, 0)
                    }
                  />
                  {supplierProps.contacts?.[1] && (
                    <AddContactPerson
                      label={t('suppliers.modals.contact_person.contact_b')}
                      contact={supplierProps.contacts?.[1] || {}}
                      handleContactChange={(contact) =>
                        handleContactChange(contact, 1)
                      }
                    />
                  )}
                </VStack>
              </HStack>
            </Flex>
            <Flex></Flex>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
