import { loginWithToken, setCredentials } from 'contexts/redux/auth/authSlice';
import {
    closeSplashDialog,
    setSplashDialog
} from 'contexts/redux/dialog/dialogsSlice';
import useQueryParam from 'hooks/useQueryParam';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const useGoogleSignin = () => {
  const accessToken = useQueryParam('access_token');
  const refreshToken = useQueryParam('refresh_token');
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (accessToken && refreshToken) {
      dispatch(setSplashDialog({ title: 'dialogs.splash.signning_in' }));
      dispatch(
        setCredentials({
          user: null,
          tokens: {
            access: { token: accessToken, expires: new Date() },
            refresh: { token: refreshToken, expires: new Date() },
          },
        }),
      );
      dispatch(loginWithToken({ accessToken: accessToken }));
      setSearchParams({}); // clear the access tokens from the URL !IMPORTANT!
      setTimeout(
        () => {
          dispatch(closeSplashDialog());
        },
        process.env.NODE_ENV === 'production' ? 2000 : 500,
      );
    }
  }, [accessToken, refreshToken, dispatch, setSearchParams]);
  return { accessToken, refreshToken };
};
export default useGoogleSignin;
