import { Flex } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { memo } from 'react';
import SelectedUser from './SelectedUser';

const SelectedOptionsContainer = (props: {
  selectedOptions: string[];
  handleRemove: (value: string) => void;
}) => {
  const { selectedOptions, handleRemove } = props;
  return (
    <Flex
      height="100%"
      width="auto"
      maxW="300px"
      gap={2}
      px={selectedOptions?.length > 0 ? '13px' : 0}
      alignItems="center">
      <AnimatePresence>
        {selectedOptions?.map((id, key) => (
          <SelectedUser key={key} id={id} handleRemove={handleRemove} />
        ))}
      </AnimatePresence>
    </Flex>
  );
};

export default memo(SelectedOptionsContainer);
