import { Button, Flex, HStack, Icon, IconButton } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { deleteCalendar } from 'contexts/redux/calendar/calendarsSlice';
import { useTranslation } from 'react-i18next';
import { BsThreeDots } from 'react-icons/bs';
import { GoDotFill } from 'react-icons/go';
import { LuPencilLine, LuTrash, LuTrash2 } from 'react-icons/lu';
import { useDispatch } from 'react-redux';
import { Calendar } from 'services/@types';

interface CalendarItemComponentProps {
  calendar: Calendar;
  currentCalendar: Calendar | null;
  editingNameForCalendar: string | null;
  editInput: string;
  handleInputChanged: (e: any, calendar: Calendar) => void;
  handleUpdateCalendarName: () => void;
  handleStartEditingCalendar: () => void;
  setEditingNameForCalendar: (id: string) => void;
  setCurrentCalendar: (calendar: Calendar | null) => void;
}

export const CalendarItemComponent: React.FC<CalendarItemComponentProps> = ({
  calendar,
  currentCalendar,
  editingNameForCalendar,
  editInput,
  handleInputChanged,
  handleUpdateCalendarName,
  setEditingNameForCalendar,
  handleStartEditingCalendar,
  setCurrentCalendar,
}) => {
  const dispatch = useDispatch<any>();
  const { t, i18n } = useTranslation();
  return (
    <HStack
      key={calendar.id}
      cursor={'pointer'}
      _hover={{ bg: 'gray.100' }}
      px={0}
      position="relative"
      w="100%"
      spacing={0}
      marginTop="0px !important"
      gap={0}
      py={0}>
      <InputField
        flex={1}
        onChange={(e) => handleInputChanged(e, calendar)}
        contentEditable={currentCalendar?.id !== calendar.id}
        isReadOnly={calendar.isSystem}
        isDisabled={currentCalendar?.id !== calendar.id}
        transition="all 0.2s"
        cursor={currentCalendar?.id === calendar.id ? 'text' : 'pointer'}
        w="100%"
        iconLeft={
          <Icon as={GoDotFill} fontSize={32} p={0} color={calendar.color} />
        }
        borderColor={'brand.600'}
        bg={calendar.isSystem ? '#93C3B333' : 'gray.100'}
        fontSize={'14px'}
        fontWeight={500}
        py={4}
        value={
          editingNameForCalendar === calendar.id
            ? editInput
            : calendar.isSystem
            ? t(`calendar.general_calendars.${calendar.name}`)
            : calendar.name || calendar.email
        }
      />
      {currentCalendar?.id === calendar.id && (
        <Flex
          display={editingNameForCalendar === calendar.id ? 'flex' : 'none'}
          align="center"
          sx={{ position: 'absolute' }}
          right={i18n.dir() === 'ltr' ? 0 : 'auto'}
          left={i18n.dir() === 'rtl' ? 0 : 'auto'}
          gap={2}
          px={0}>
          <Button
            variant="h4"
            onClick={() => {
              handleUpdateCalendarName();
            }}>
            {t('calendar.calendars_modal.done')}
          </Button>
        </Flex>
      )}
      <Flex
        display={
          currentCalendar?.id === calendar.id &&
          editingNameForCalendar === calendar.id
            ? 'none'
            : 'flex'
        }
        align="center"
        sx={{ position: 'absolute' }}
        right={i18n.dir() === 'ltr' ? 0 : 'auto'}
        left={i18n.dir() === 'rtl' ? 0 : 'auto'}
        gap={2}
        px={3}>
        <IconButton
          key="edit"
          display={
            currentCalendar?.id === calendar.id && calendar.type === 'internal'
              ? 'flex'
              : 'none'
          }
          variant="actionIconGrey"
          p={2}
          onClick={handleStartEditingCalendar}
          as={LuPencilLine}
          aria-label={''}
        />
        <IconButton
          display={currentCalendar?.id === calendar.id ? 'flex' : 'none'}
          key="delete"
          variant="actionIconRed"
          p={2}
          onClick={() => {
            dispatch(setCurrentCalendar(null));
            dispatch(
              deleteCalendar({
                id: calendar.id,
              }),
            );
          }}
          as={LuTrash2}
          aria-label={''}
        />
        <Icon
          key="more"
          cursor={'pointer'}
          color="#939393"
          zIndex={2}
          pointerEvents="all"
          _hover={{ color: 'brand.300' }}
          _active={{ color: 'brand.300' }}
          _focus={{ color: 'brand.300' }}
          as={BsThreeDots}
          display={calendar.isSystem ? 'none' : 'flex'}
          onClick={() => {
            if (currentCalendar?.id === calendar.id) {
              handleUpdateCalendarName();
              return;
            }
            dispatch(setCurrentCalendar(calendar));
          }}
          fontSize={24}
          p={0}
        />
      </Flex>
    </HStack>
  );
};
