const ENG_FONT_NAME: string = 'DM Sans';
const HEBR_FONT_NAME: string = 'Rubik Variable, sans-serif';

interface Fonts {
  heading: string;
  body: string;
  button: string;
}

// def fonts
const Engfonts: Fonts = {
  heading: ENG_FONT_NAME,
  body: ENG_FONT_NAME,
  button: ENG_FONT_NAME,
};

const Hebrfonts: Fonts = {
  heading: HEBR_FONT_NAME,
  body: HEBR_FONT_NAME,
  button: HEBR_FONT_NAME,
};

export { Engfonts, Hebrfonts };
