import {
  useQuery,
  useQueryClient,
  QueryKey,
  UseQueryResult,
} from '@tanstack/react-query';
import { User } from 'services/@types';
import _userService from 'services/user.api';

interface UseGetUserOptions {
  enabled?: boolean;
}

// Define the query key creator
const getUserQueryKey = (userId?: string): QueryKey => ['user', userId];

// Define the query options creator
const getUserQueryOptions = (userId?: string) => ({
  queryKey: getUserQueryKey(userId),
  queryFn: () => _userService.getUser(userId),
  staleTime: 5 * 60 * 1000, // 5 minutes
  gcTime: 15 * 60 * 1000, // 15 minutes (formerly cacheTime)
  retry: (failureCount: number, error: any) => {
    // Retry up to 3 times, but not for 404 errors
    return failureCount < 3 && error.response?.status !== 404;
  },
});

const useGetUser = (
  userId: string,
  options: UseGetUserOptions = {},
): UseQueryResult<User, Error> => {
  return useQuery({
    ...getUserQueryOptions(userId),
    enabled: options.enabled !== false && !!userId,
  });
};

// Prefetch function
useGetUser.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
  userId: string,
) => {
  await queryClient.prefetchQuery(getUserQueryOptions(userId));
};

// Invalidate function
useGetUser.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  userId: string,
) => {
  queryClient.invalidateQueries({ queryKey: getUserQueryKey(userId) });
};

export default useGetUser;
