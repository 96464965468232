export const menuStyles = {
  components: {
    Menu: {
      baseStyle: {},
      variants: {
        threeDots: {
          list: {
            display: 'flex',
            justifyContent: 'end',
            gap: '16.6px',
            bg: 'transparent',
            borderColor: 'transparent',
            boxShadow: 'none',
          },
          item: {
            bg: 'transparent',
            borderColor: 'transparent',
            boxShadow: 'none',
          },
        },
      },
    },
  },
};
