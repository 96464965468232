import { Avatar, Button, Divider, Flex, Spacer, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { setAddSeatDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Invite } from 'services/@types';

export default function TeamContainer() {
  const dispatch = useDispatch<any>();
  const invites = useSelector((state: RootState) => state.invites.invites);
  const seatsInvites = useMemo(
    () => invites.filter((invite: Invite) => invite.type === 'seat'),
    [invites],
  );
  const { t } = useTranslation();
  return (
    <Card
      flexDirection="column"
      w="100%"
      ps={5}
      pe={'38px'}
      maxW={{ base: '100%', md: 400 }}
      borderRadius={{ base: 0, md: '30px' }}
      maxH={550}
      overflow="scroll">
      <Flex flexDirection="column" justifyContent="center" wrap="wrap" gap={10}>
        <Text variant="cardTitle">{t('the_office.your_team')}</Text>
        <Flex flexDirection="column">
          {/* max 5 seats */}
          {[
            ...seatsInvites,
            ...Array.from({ length: 5 - seatsInvites.length }),
          ]?.map((seat: Invite | any, index) => (
            <Flex flexDirection={'column'} key={index}>
              <Flex py={4} key={index} justify="space-between" align="center">
                <Flex gap={2} align="center">
                  <Avatar w="24px" h="24px" />
                  <Text variant="cardSectionTitle">
                    {`${seat?.fullName || t('the_office.seat')}`}
                  </Text>
                </Flex>

                <Button
                  onClick={() =>
                    dispatch(setAddSeatDialog({ item: { ...seat } }))
                  }
                  variant={
                    (seat as Invite)?.status === 'pending'
                      ? 'h3outlinedYellow'
                      : 'h3outlined'
                  }>
                  {(seat as Invite)?.status === 'pending'
                    ? t('the_office.pending')
                    : t('the_office.set_up')}
                </Button>
              </Flex>
              <Divider />
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Spacer />
      <Button textAlign="center" color="rgba(170, 145, 93, 1)" variant={'h4'}>
        {t('the_office.need_more_seats')}
      </Button>
    </Card>
  );
}
