// Chakra Imports
import {
  Divider,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom Components
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
// Assets
import MessagesIcon from 'assets/img/navbar/messages.png';
import AuthImage from 'components/authImage/AuthImage';
import { RootState } from 'contexts/redux/store';
import { t } from 'i18next';
import { useMemo } from 'react';
import { MdNotificationsNone } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';
import WeatherIcon from '../../assets/img/weather/cloud-notif.png';
import useChangeLanguage from './hooks/useChangeLanguage';
import { useSignout } from './hooks/useSignout';
import InviteItem from '../../components/notifications/InviteItem';
export default function HeaderLinks(props: {
  secondary: boolean;
  [x: string]: any;
}) {
  const { secondary } = props;
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const { signOut, loading } = useSignout();
  const { handleChangeLanguage, selectedLanguage } = useChangeLanguage();

  const HeFontWeight = useMemo(
    () => (selectedLanguage === 'he' ? 'bold' : null),
    [selectedLanguage],
  );
  const EnFontWeight = useMemo(
    () => (selectedLanguage === 'en' ? 'bold' : null),
    [selectedLanguage],
  );
  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      zIndex="5"
      borderRadius="999px"
      boxShadow={shadow}>
      <SidebarResponsive routes={routes} />
      <SearchBar
        placeholder={t('header_links.search')}
        mb={secondary ? { base: '10px', md: 'unset' } : 'unset'}
        me="10px"
        borderRadius="20px"
      />
      <Menu>
        <Text fontSize="12px" fontWeight="400" color="primary.100">
          34ºC
        </Text>
        <Image src={WeatherIcon} alt="" w="18px" h="18px" m="0 7px" />
        <Flex position="relative" minW="19px" justifyContent="center" me="7px">
          <Flex
            alignItems="center"
            justifyContent="center"
            position="absolute"
            w="12px"
            h="12px"
            top="0"
            right="0"
            borderRadius="50%"
            bg="#AA915D">
            <Text variant="missedMessageCounter">1</Text>
          </Flex>
          <Image src={MessagesIcon} alt="" w="16px" h="15px" />
        </Flex>
        <MenuButton p="0px">
          <Icon
            mt="6px"
            as={MdNotificationsNone}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: '30px', md: 'unset' }}
          minW={{ base: 'unset', md: '400px', xl: '450px' }}
          maxW={{ base: '360px', md: 'unset' }}>
          <Flex w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              {t('notifications.title')}
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer">
              {t('notifications.mark_all_read')}
            </Text>
          </Flex>
          <Divider borderColor="#E9EDF7" />
          <Flex flexDirection="column" py={1}>
            <Text fontSize="xl" fontWeight={'bold'} my={5}>
              {t('notifications.new')}
            </Text>
            <InviteItem
              invite={undefined}
              onAccept={function (): void {
                throw new Error('Function not implemented.');
              }}
              onDecline={function (): void {
                throw new Error('Function not implemented.');
              }}
            />
            <Text fontSize="xl" fontWeight={'bold'} my={5}>
              {t('notifications.read')}
            </Text>
          </Flex>
          <Divider borderColor="#E9EDF7" />
        </MenuList>
      </Menu>

      <Menu>
        <MenuButton p="0px">
          <AuthImage
            _hover={{ cursor: 'pointer' }}
            color={textColor}
            name={user?.firstName}
            borderRadius="50%"
            overflow="hidden"
            size="sm"
            w="40px"
            h="40px"
            path={user?.media?.find((m) => m.type === 'avatar')?.url}
            id={user?.id}
            isAvatar
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none">
          {user?.firstName && (
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
                maxW="90%"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis">
                {`${t('header_links.hi')} ${user?.firstName}`} {user?.lastName}
              </Text>
            </Flex>
          )}
          <Flex flexDirection="column" p="10px" position={'relative'}>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick={() => navigate('/main/settings/business')}>
              <Text fontSize="sm">{t('header_links.account_information')}</Text>
            </MenuItem>{' '}
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick={() => navigate('/main/settings/profile')}>
              <Text fontSize="sm">{t('header_links.personal_settings')}</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick={() => handleChangeLanguage()}>
              <Text fontSize="sm" fontWeight={HeFontWeight}>
                He
              </Text>
              <Text fontSize="sm">/</Text>
              <Text fontSize="sm" fontWeight={EnFontWeight}>
                En
              </Text>
            </MenuItem>
            <MenuItem
              onClick={signOut}
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px">
              <Text fontSize="sm" display={loading ? 'none' : 'block'}>
                {t('header_links.log_out')}
              </Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
