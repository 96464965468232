// {
//     "invite_supplier": {
//       "welcome": "Welcome, {supplierName}!",
//       "invited_by": "{senderUser} has invited you to join Brilliant.",
//       "system_description": "Finally a system that has it all",
//       "real_time_app": "A real-time event planning app, where you can communicate and stay ahead with sharing details or receive change requests, create templates for unlimited events, ensure professional planning for events of all sizes, and stay organized with quotes, contracts and expenses.",
//       "be_brilliant": "I Want To Be Brilliant!",
//       "are_you_supplier": "Are you a supplier?",
//       "manage_business": "Manage your business on a system that was created by the industry, for the industry, with your true needs in mind!",
//       "showcase_work": "Let your potential clients get to know what you can do for them by showcasing your work and services on Brilliant. Get quick quotes, send invoices, manage schedules",
//       "set_me_up": "Set Me Up!",
//       "stress_free": "Create and manage events stress free",
//       "sign_up": "Sign Up",
//       "sign_up_with_email": "Sign up with email",
//       "log_in": "Log In"
//     }
//   }

// Chakra imports
import {
  Button,
  Flex,
  Heading,
  Hide,
  HStack,
  Icon,
  Image,
  keyframes,
  Show,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
// Assets

import inviteImg1 from 'assets/img/invite/supplier/invite-supplier1.png';
import inviteImg2 from 'assets/img/invite/supplier/invite-supplier2.png';
import inviteImg3 from 'assets/img/invite/supplier/invite-supplier3.png';
import inviteImg332 from 'assets/img/invite/supplier/invite-supplier32.png';
import Landing from 'layouts/auth/variants/Landing';
import { FaAngleDown } from 'react-icons/fa';

import { useTranslation } from 'react-i18next';

import Logo from 'assets/svg/brilliant-logo-gold.svg';
import { motion, Variants } from 'framer-motion';
import { useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Typewriter from 'typewriter-effect';
import { useInvite } from './hooks/useSupplierInvite';

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const cardVariants: Variants = {
  offscreenRight: {
    y: 50,
    x: 120,
    opacity: 0,
    scale: 0.8,
    rotate: 10,
  },
  offscreenLeft: {
    y: 50,
    x: -120,
    opacity: 0,
    scale: 0.8,
    rotate: -10,
  },
  onscreenRight: {
    y: 0,
    x: 0,
    scale: 1,
    rotate: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 1.2,
    },
  },
  onscreenLeft: {
    y: 0,
    x: 0,
    scale: 1,
    rotate: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.4,
      delay: 0.2,
      duration: 1.2,
    },
  },
};
function InviteSupplier() {
  const navigate = useNavigate();
  // Chakra color mode
  const { error, loading, supplier, senderUser } = useInvite();
  const { t } = useTranslation();
  const isDesktop = window.innerWidth > 768;
  const containerRef = useRef(null);

  if (loading) {
    return (
      <Landing containerRef={containerRef}>
        <VStack
          maxW="8xl"
          w="100%"
          alignSelf={'center'}
          p={{ sm: 3, md: 12 }}
          gap={0}
          h="100vh">
          <HStack
            justify={'space-between'}
            w="100%"
            mb={{ sm: 4, md: 10 }}
            align="center"
            px={{ sm: 2, md: 10 }}
            pt={{ sm: 4, md: 0 }}
            wrap="wrap"
            gap={{ sm: 2, md: 6 }}>
            <Image
              src={Logo}
              alt="logo"
              w={{ sm: '149px', md: '220px' }}
              mt={{ sm: -2, md: 0 }}
            />
            <Button
              px={{ sm: 6, md: 20 }}
              as={NavLink}
              to="/auth/get-started"
              variant="h1cta">
              {t('invite_supplier.sign_up')}
            </Button>
          </HStack>
          <Text color="red.400" fontWeight="400" fontSize="md">
            {error}
          </Text>
        </VStack>
      </Landing>
    );
  }

  return (
    <Landing containerRef={containerRef}>
      <VStack
        maxW="8xl"
        w="100%"
        alignSelf={'center'}
        p={{ sm: 3, md: 12 }}
        gap={0}>
        <HStack
          justify={'space-between'}
          w="100%"
          mb={{ sm: 4, md: 10 }}
          align="center"
          px={{ sm: 2, md: 10 }}
          pt={{ sm: 4, md: 0 }}
          wrap="wrap"
          gap={{ sm: 2, md: 6 }}>
          <Image
            src={Logo}
            alt="logo"
            w={{ sm: '149px', md: '220px' }}
            mt={{ sm: -2, md: 0 }}
          />
          <Button
            px={{ sm: 6, md: 20 }}
            as={NavLink}
            to="/auth/get-started"
            variant="h1cta">
            {t('invite_supplier.sign_up')}
          </Button>
        </HStack>

        {/* section 1 */}

        <HStack justify={'space-between'} w="100%" wrap="wrap" align="start">
          <Flex
            w={'100%'}
            h="100%"
            justifyContent="start"
            flexDirection="column"
            gap={10}
            flex={1}
            align="start">
            <Spacer />
            <VStack
              gap={5}
              align="flex-start"
              flex={1}
              justify="end"
              maxW={420}>
              <Flex minH={{ sm: 100, md: 150 }}>
                {supplier && (
                  <Typewriter
                    component={Text}
                    onInit={(typewriter) => {
                      const style = `<string style="font-weight: bold; font-size: ${
                        isDesktop ? '42px' : '32px'
                      }">`;
                      typewriter
                        .changeDelay(40)
                        .typeString(
                          `${style}${t('invite_supplier.welcome', {
                            supplierName:
                              supplier?.name ||
                              t('invite_supplier.default_supplier_name'),
                          })}</strong>`,
                        )
                        .pauseFor(1000)
                        .deleteAll()
                        .typeString(
                          `${style}${t('invite_supplier.invited_by', {
                            senderUser:
                              senderUser?.firstName ||
                              t('invite_supplier.default_sender_user'),
                          })}</strong>`,
                        )
                        .pauseFor(1000)
                        .deleteAll()
                        .typeString(
                          `${style}${t(
                            'invite_supplier.system_description',
                          )}</strong>`,
                        )
                        .start();
                    }}
                  />
                )}
              </Flex>
              <Text color="primary.100" fontWeight="400" fontSize="md">
                {t('invite_supplier.real_time_app')}
              </Text>
              <Button
                variant="h1cta"
                onClick={() => navigate('/auth/get-started')}
                px={12}>
                {t('invite_supplier.be_brilliant')}
              </Button>
            </VStack>
            <Spacer />
          </Flex>

          <Show below="400px">
            <HStack
              justify={'center'}
              w="100%"
              wrap="wrap"
              align="start"
              pb={20}>
              <VStack>
                <Text>{t('invite_supplier.scroll_down_for_more')}</Text>
                <Icon
                  as={FaAngleDown}
                  animation={`${bounce} 0.5s ease-in-out 10`}
                />
              </VStack>
            </HStack>
          </Show>
          <motion.div
            className="card-container"
            initial="offscreenRight"
            whileInView="onscreenRight"
            viewport={{ once: true, amount: 0.8 }}>
            <Flex
              flex={1}
              minW={{ sm: '100%', md: 420 }}
              justify="center"
              align="center"
              p={2}
              as={motion.div}
              variants={cardVariants}>
              <Image
                src={inviteImg1}
                alt="inviteImg1"
                w="100%"
                h="100%"
                objectFit={'contain'}
                sx={{ maxW: 681, maxH: 613 }}
              />
            </Flex>
          </motion.div>
        </HStack>

        <Hide below="400px">
          <HStack
            justify={'center'}
            w="100%"
            wrap="wrap"
            align="start"
            pb={{ sm: 4, md: 20 }}>
            <VStack>
              <Text>{t('invite_supplier.scroll_down_for_more')}</Text>
              <Icon
                as={FaAngleDown}
                animation={`${bounce} 0.5s ease-in-out 10`}
              />
            </VStack>
          </HStack>
        </Hide>
        {/* section 2 */}

        <HStack
          justify={'space-between'}
          wrap="wrap"
          w="100%"
          flexDirection={{ sm: 'column-reverse', md: 'row' }}
          as={motion.div}
          variants={cardVariants}>
          <motion.div
            className="card-container"
            initial="offscreenLeft"
            whileInView="onscreenLeft"
            viewport={{ once: true, amount: 0.8 }}>
            <Flex
              flex={1}
              minW={{ sm: '100%', md: 420 }}
              justify="center"
              align="center"
              p={2}
              as={motion.div}
              variants={cardVariants}>
              <Image
                src={inviteImg2}
                alt="inviteImg1"
                w="100%"
                h="100%"
                objectFit={'contain'}
                sx={{ maxW: 681, maxH: 613 }}
              />
            </Flex>
          </motion.div>
          <Flex
            w={'100%'}
            h="100%"
            justifyContent="center"
            flexDirection="column"
            gap={10}
            flex={1}
            align="center">
            <Spacer />
            <VStack
              gap={6}
              align="flex-start"
              flex={1}
              justify="end"
              maxW={420}>
              <Heading color="brand.900">
                {t('invite_supplier.are_you_supplier')}
              </Heading>
              <Text fontWeight={'bold'}>
                {t('invite_supplier.manage_business')}
              </Text>
              <Text color="primary.100" fontWeight="400" fontSize="md">
                {t('invite_supplier.showcase_work')}
              </Text>
              <Button
                variant="h1cta"
                onClick={() => navigate('/auth/get-started')}
                px={20}>
                {t('invite_supplier.set_me_up')}
              </Button>
            </VStack>
            <Spacer />
          </Flex>
        </HStack>

        {/* section 3 */}
        <HStack justify={'space-between'} w="100%" wrap="wrap">
          <Flex
            w={'100%'}
            h="100%"
            justifyContent="center"
            flexDirection="column"
            gap={10}
            flex={1}
            align="center">
            <Spacer />
            <VStack
              gap={{ sm: 2, md: 6 }}
              spacing={0}
              align="flex-start"
              flex={1}
              justify="space-around"
              minW={{ sm: '100%', md: 420 }}>
              <Text fontSize={'3xl'} fontWeight="bold" alignSelf={'center'}>
                {t('invite_supplier.stress_free')}
              </Text>
              <motion.div
                className="card-container"
                initial="offscreenLeft"
                whileInView="onscreenLeft"
                viewport={{ once: true, amount: 0.8 }}>
                <Flex
                  w="100%"
                  height="100%"
                  as={motion.div}
                  variants={cardVariants}>
                  <Image
                    src={inviteImg332}
                    alt="inviteImg32"
                    w="100%"
                    h="100%"
                    objectFit={'contain'}
                    sx={{ maxW: 501, maxH: 420 }}
                  />
                </Flex>
              </motion.div>
              <Button
                alignSelf={'center'}
                variant="h1cta"
                onClick={() => navigate('/auth/get-started')}
                px={20}>
                {t('invite_supplier.set_me_up')}
              </Button>
            </VStack>
            <Spacer />
          </Flex>
          <motion.div
            className="card-container"
            initial="offscreenRight"
            whileInView="onscreenRight"
            viewport={{ once: true, amount: 0.8 }}>
            <Flex
              flex={1}
              minW={{ sm: '100%', md: 420 }}
              justify="center"
              align="center"
              p={2}
              as={motion.div}
              variants={cardVariants}>
              <Image
                src={inviteImg3}
                alt="inviteImg3"
                w="100%"
                h="100%"
                objectFit={'contain'}
                sx={{ maxW: 681, maxH: 613 }}
                borderRadius="30px"
                overflow={'hidden'}
              />
            </Flex>
          </motion.div>
        </HStack>

        <Show below="450px">
          <VStack px={10} w="100%" gap={4}>
            <Button
              w="100%"
              variant="h1cta"
              onClick={() => navigate('/auth/get-started')}>
              {t('get_started.sign_up_with_email')}
            </Button>
            <Button
              w="100%"
              variant="outlineBrand"
              onClick={() => navigate('/auth/sign-in')}>
              {t('get_started.log_in')}
            </Button>
            <Image src={Logo} alt="logo" w="149px" />
          </VStack>
        </Show>
      </VStack>
    </Landing>
  );
}

export default InviteSupplier;
