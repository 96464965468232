import {
  CircularProgress,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { RootState } from 'contexts/redux/store';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import brilliantLogo from '../../assets/svg/brilliant-logo.svg';
import bgSquaresSplash from '../../assets/svg/splash/bg-squares-splash.svg';
const Splash: React.FC<{ forceShow?: boolean }> = ({ forceShow }) => {
  const { i18n, t } = useTranslation();

  const splashDialog = useSelector(
    (state: RootState) => state.dialogs.splashDialog,
  );
  const { title } = splashDialog || { title: '' };
  // const isDesktop = useBreakpointValue({ base: false, md: true });

  const FlexMotion = motion(Flex);

  return (
    <Modal isOpen={!!splashDialog || forceShow} onClose={undefined}>
      <ModalOverlay style={{ padding: 0 }} />
      <ModalContent
        h="full"
        maxW="full"
        bgGradient={'linear(to-r, brand.400, brand.600)'}
        w="full"
        mt={0}
        mb={0}
        borderRadius="none">
        <ModalBody>
          <Flex
            w="full"
            direction={'column'}
            h="full"
            px={10}
            bg=""
            gap={4}
            align="center"
            justify={'center'}>
            <Flex
              w="full"
              h="full"
              align="center"
              justify="center"
              position={'absolute'}>
              <FlexMotion
                flexDirection="row-reverse"
                gap={0}
                h="full"
                w="full"
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: 'easeInOut',
                }}
                animate={{ opacity: [1, 0.5, 1] }}>
                <Image
                  aria-label="brand-logo"
                  src={bgSquaresSplash}
                  alt="logo"
                  w={'full'}
                  h="full"
                  objectFit={'cover'}
                />
              </FlexMotion>
            </Flex>
            <Image
              aria-label="brand-logo"
              src={brilliantLogo}
              alt="logo"
              w="full"
              h="full"
              filter="brightness(0) invert(1)"
              maxW={575}
              maxH={150}
            />
            <HStack dir={i18n.dir()}>
              <Text
                fontSize={{
                  base: 'md',
                  sm: 'sm',
                  md: 'lg',
                  lg: 'xl',
                  xl: '2xl',
                }}
                color="gray.600"
                fontWeight="medium"
                textAlign="center">
                {t(title) || t('dialogs.splash.please_wait')}
              </Text>
              <CircularProgress size={'20px'} isIndeterminate color="white" />
            </HStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Splash;
