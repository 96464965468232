import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface RGB {
  r: number;
  g: number;
  b: number;
}

interface ImageColorModifierProps extends BoxProps {
  icon: React.ReactElement; // This now expects an img element
  isActive: boolean;
  activeColor: string;
  inactiveColor: string;
}

const hexToRgb = (hex: string): RGB | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const generateFilter = (color: string): string => {
  const rgb = hexToRgb(color);
  if (!rgb) return '';

  const { r, g, b } = rgb;
  const hueRotate = (Math.atan2(b - r, g - r) * 180) / Math.PI;

  return (
    `brightness(0) saturate(100%) ` +
    `invert(${r / 255}) sepia(${g / 255}) saturate(${b / 255}) ` +
    `hue-rotate(${hueRotate}deg)`
  );
};

const ImageColorModifier: React.FC<ImageColorModifierProps> = ({
  icon,
  isActive,
  activeColor,
  inactiveColor,
  ...boxProps
}) => {
  const filter = React.useMemo(
    () => generateFilter(isActive ? activeColor : inactiveColor),
    [isActive, activeColor, inactiveColor],
  );

  const modifiedIcon = React.cloneElement(icon, {
    style: { ...icon.props.style, filter },
    width: '100%',
    height: '100%',
  });

  return <Box {...boxProps}>{modifiedIcon}</Box>;
};

export default ImageColorModifier;
