import { AxiosInstance, AxiosResponse } from 'axios';
import { UserPreferences, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class userPreferencesService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createUserPreferences(
    UserPreferences: UserPreferences,
  ): Promise<UserPreferences> {
    const response: AxiosResponse<UserPreferences> = await this.api.post(
      '/userPreferences',
      UserPreferences,
    );
    return response.data;
  }

  public async getUsersPreferences(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<UserPreferences>> {
    const response: AxiosResponse<PaginatedResults<UserPreferences>> =
      await this.api.get('/userPreferences', {
        params: queryParams,
      });
    return response.data;
  }

  public async getUserPreferences(
    userPreferencesId: string,
  ): Promise<UserPreferences> {
    const response: AxiosResponse<UserPreferences> = await this.api.get(
      `/userPreferences/${userPreferencesId}`,
    );
    return response.data;
  }

  public async updateUserPreferences(
    userPreferencesId: string,
    updates: Partial<UserPreferences>,
  ): Promise<UserPreferences> {
    const response: AxiosResponse<UserPreferences> = await this.api.patch(
      `/userPreferences/${userPreferencesId}`,
      updates,
    );
    return response.data;
  }

  public async deleteUserPreferences(userPreferencesId: string): Promise<void> {
    await this.api.delete(`/userPreferences/${userPreferencesId}`);
  }
}

const _userPreferencesService = new userPreferencesService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _userPreferencesService;
