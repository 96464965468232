import i18n from 'config/i18n/config';
import { useState } from 'react';

export default function useChangeLanguage() {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    localStorage.getItem('language'),
  );
  const handleChangeLanguage = () => {
    setSelectedLanguage((prevValue) => {
      if (prevValue === 'he') {
        i18n.changeLanguage('en');
        localStorage.setItem('language', 'en');
        return 'en';
      } else {
        i18n.changeLanguage('he');
        localStorage.setItem('language', 'he');
        return 'he';
      }
    });
  };
  return { handleChangeLanguage, selectedLanguage };
}
