import React, { useCallback, useEffect, useState } from 'react';

// Chakra imports
import {
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { FaCheckCircle } from 'react-icons/fa';

// Custom components
import CenteredAuth from 'layouts/auth/variants/Centered';
// Assets

import { updateMyUser } from 'contexts/redux/auth/authSlice';
import {
  closeSplashDialog,
  showSplashDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { AnimatePresence, motion } from 'framer-motion';
import useMeasureUserLeftPage from 'hooks/useMeasureUserLeavePage';
import { useDispatch, useSelector } from 'react-redux';
import { GAEventAction, GAEventCategory, GAEventLabel } from 'types/ga-enums';
import Indicator from './components/Indicator';
import PaymentTab from './components/PaymentTab';
import PersonalizeTab from './components/PersonalizeTab';
import PlansTab from './components/PlansTab';
import SignUpTab from './components/SignUpTab';
import useGoogleLogin from './hooks/useGoogleSignin';
import { useNavigate } from 'react-router-dom';

export enum SignUpStep {
  signup = 'signup',
  business = 'business',
  personalization = 'personalization',
  pricing = 'pricing',
  plans = 'plans',
  payment = 'payment',
  completed = 'completed',
}

export const OnboardFlow = [
  SignUpStep.signup,
  SignUpStep.business,
  SignUpStep.personalization,
  SignUpStep.pricing,
  SignUpStep.plans,
  SignUpStep.payment,
  SignUpStep.completed,
];
export const OnboardHeaders = ['signup', 'personalization', 'pricing'];
function SignUp() {
  // Chakra color mode
  const { isOpen, onClose } = useDisclosure();

  const onboardingStep = useSelector(
    (state: RootState) => state.auth?.user?.onboardingStep,
  );

  const [activeTab, setActiveTab] = useState<SignUpStep>(SignUpStep.signup);
  const [show, setShow] = React.useState(false);
  const dispatch = useDispatch<any>();
  const [paymentInProgress] = React.useState(false);
  const handleClick = () => setShow(!show);
  const {} = useGoogleLogin();
  const navigate = useNavigate();

  useMeasureUserLeftPage(
    GAEventAction.SupplierInvitePaused,
    GAEventCategory.SupplierInvite,
    GAEventLabel.SupplierInvitePaused,
    onboardingStep !== SignUpStep.completed,
  );

  useEffect(() => {
    console.log('onboardingStep ->', onboardingStep);
    const currentStage = OnboardFlow.indexOf(onboardingStep as SignUpStep);
    setActiveTab(
      currentStage >= 0 ? OnboardFlow[currentStage] : SignUpStep.signup,
    );
  }, [onboardingStep]);

  const handleNextClicked = useCallback(() => {
    const currentIndex = OnboardFlow.indexOf(activeTab);
    const newOnboardingStep = OnboardFlow[currentIndex + 1];
    if (newOnboardingStep === SignUpStep.completed) {
      dispatch(
        showSplashDialog({ title: 'dialogs.splash.generating_dashboard' }),
      );
      setTimeout(() => {
        dispatch(showSplashDialog({ title: 'dialogs.splash.dashboard_ready' }));
        setTimeout(async () => {
          const res = await dispatch(
            updateMyUser({
              updates: { onboardingStep: newOnboardingStep },
            }),
          );
          if (res.payload.onboardingStep === 'completed') {
            navigate('/main/settings/business');
          }
          dispatch(closeSplashDialog());
        });
      }, 3000);
      return;
    }

    dispatch(
      updateMyUser({
        updates: { onboardingStep: newOnboardingStep },
      }),
    );
    // scroll document to top:
    window.scrollTo(0, 0);
  }, [activeTab, dispatch, navigate]);

  return (
    <CenteredAuth
      cardTop={{ base: '140px', md: '14vh', sm: 14 }}
      cardBottom={{ base: '50px', lg: '100px' }}
      cardBackground="transparent">
      <>
        <Flex
          alignSelf={'center'}
          w={{ base: 'sm', sm: 'xs', md: 'sm', lg: 'sm', xl: 'md' }}
          justify={'space-between'}>
          {Array.from({ length: 5 }).map((_, index) => (
            <Indicator
              key={index}
              index={index}
              activeTab={activeTab}
              updateMyUser={updateMyUser}
            />
          ))}
        </Flex>
        <Flex
          direction="column"
          w="100%"
          mt={{
            base: '40px',
            sm: '40px',
            md: '40px',
            lg: '30px',
            xl: '30px',
          }}>
          <AnimatePresence>
            <motion.div
              key={activeTab}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}>
              <SignUpTab activeTab={activeTab} nextClick={handleNextClicked} />

              <PersonalizeTab
                activeTab={activeTab}
                nextClick={handleNextClicked}
              />

              <PlansTab activeTab={activeTab} nextClick={handleNextClicked} />

              <PaymentTab
                activeTab={activeTab}
                nextClick={handleNextClicked}
                paymentInProgress={paymentInProgress}
              />
            </motion.div>
          </AnimatePresence>
        </Flex>
        <Modal isOpen={isOpen} onClose={() => onClose()}>
          <ModalOverlay />
          <ModalContent borderRadius={'2xl'} alignSelf="center" mx={2}>
            <HStack px={6} pt={6}>
              <Icon as={FaCheckCircle} color="green.300" w={6} h={6} />
              <Text fontWeight={'bold'} color="green.300">
                Your purchase was successful
              </Text>
            </HStack>

            <ModalCloseButton />
            <ModalBody>
              <Text color="green.300">
                Thank you for joining, now the fun part begins!
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button w="full" variant="h1cta" mr={3} onClick={handleClick}>
                Let's start
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </CenteredAuth>
  );
}

export default SignUp;
