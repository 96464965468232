import { useCallback, useEffect, useState } from 'react';

export const useAutocompleteItems = (
  items: { value: string; label: string }[],
) => {
  const [newItemInputValue, setNewItemInputValue] = useState('');
  const [displayingOptions, setDisplayingOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    let filteredItems = items;

    filteredItems = filteredItems.filter(
      (p: { value: string; label: string }) =>
        p.label?.toLowerCase()?.includes(newItemInputValue?.toLowerCase()) &&
        p.label !== newItemInputValue,
    );
    setDisplayingOptions(filteredItems);
    setSelectedItem('');
  }, [items, newItemInputValue]);

  const handleOnSelected = useCallback(
    (value) => {
      setNewItemInputValue(
        displayingOptions.find((p) => p.value === value)?.label,
      );
      setSelectedItem(value);
    },
    [displayingOptions],
  );

  const resetItems = useCallback(() => {
    setNewItemInputValue('');
  }, []);
  return {
    displayingOptions,
    setNewItemInputValue,
    handleOnSelected,
    newItemInputValue,
    resetItems,
    selectedItem,
  };
};
