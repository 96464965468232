// Chakra imports
import {
    Flex
} from '@chakra-ui/react';
// Custom components
import Footer from 'components/footer/FooterAuthCentered';
import PropTypes from 'prop-types';

function Landing(props: {
    children: JSX.Element;
    title?: string;
    description?: string;
    image?: string;
    containerRef: any;
    cardTop?: { [x: string]: string | number };
    cardBottom?: { [x: string]: string | number };
    [x: string]: any;
}) {
    // eslint-disable-next-line
    const {
        children,
    } = props;

    return (
        <Flex
            direction="column"
            alignSelf="center"
            justifySelf="center"
            overflow="hidden"
            ref={props.containerRef}
            mx={{ base: '10px', sm: '2px', md: '20px' }}
            minH="100vh"

        >
            {children}

            <Footer />
        </Flex>
    );
}
// PROPS

Landing.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.any,
};

export default Landing;
