import {
  Button,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import AssignAutocompleteField from 'components/AssignAutocompleteField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { closeShareMyCalendarDialog } from 'contexts/redux/dialog/dialogsSlice';
import { createInvites } from 'contexts/redux/invite/inviteSlice';
import { RootState } from 'contexts/redux/store';
import { fetchUser, fetchUsers } from 'contexts/redux/user/userSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { UrlItem, User } from 'services/@types';

export default function ShareMyCalendarModal() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const myUser = useMyUser();
  const [sendingInvites, setSendingInvites] = useState(false);
  const [assigned, setAssigned] = useState<User[]>([]);
  const [currentAssigned, setCurrentAssigned] = useState<string>('');

  const toast = useToast();
  const { shareMyCalendarDialog } = useSelector(
    (state: RootState) => state.dialogs,
  );
  const { loading } = useSelector((state: RootState) => state.calendars);
  const handleClose = useCallback(() => {
    dispatch(closeShareMyCalendarDialog());
  }, [dispatch]);

  const handleCloseCompleted = useCallback(() => {
    setAssigned([]);
  }, []);
  const isOpen = useMemo(
    () => !!shareMyCalendarDialog,
    [shareMyCalendarDialog],
  );

  const [displayingAssigned, setDisplayingAssigned] = useState<
    {
      avatar: UrlItem | undefined;
      email: string;
      label: string;
      value: string;
    }[]
  >([]);

  const myBusiness = useMyBusiness();
  useEffect(() => {
    if (currentAssigned.length < 1 || !currentAssigned) {
      return;
    }
    const fetchSeats = async () => {
      const users = await dispatch(
        fetchUsers({
          name: currentAssigned,
          limit: 5,
        }),
      );

      if (users?.payload?.results) {
        setDisplayingAssigned(
          users.payload.results
            .filter((user: User) => !assigned.find((a) => a.id === user?.id))
            .filter((user: User) => user.id !== myUser?.id)
            .map((user: User) => ({
              avatar: user.media?.find(
                (media: UrlItem) => media.type === 'avatar',
              ),
              email: user.email,
              label: `${user.firstName} ${user.lastName}`,
              value: user?.id,
            })),
        );
      }
    };
    fetchSeats();
  }, [currentAssigned, dispatch, myBusiness?.id, assigned, myUser?.id]);

  const handleSelectAssigned = useCallback(
    async (value: string) => {
      const { payload: user } = await dispatch(fetchUser(value));
      setAssigned([...assigned, user]);
      setCurrentAssigned('');
    },
    [assigned, dispatch],
  );
  const handleRemoveAssigned = useCallback(
    (value: string) => {
      setAssigned(assigned.filter((item) => item.id !== value));
    },
    [assigned],
  );
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      onCloseComplete={handleCloseCompleted}>
      <ModalOverlay />
      <ModalContent mx="16px" borderRadius="16px" p="24px" maxW={540}>
        <ModalBody p={0}>
          <Flex flexDirection="column" w="100%" gap="24px">
            <HStack justifyContent="space-between">
              <Text fontSize="2xl" fontWeight={'bold'}>
                {t('calendar.share_my_calendar.title')}
              </Text>
              <IconButton
                variant="action"
                size="sm"
                borderRadius={'xl'}
                bg="white"
                fontSize={20}
                icon={<FiX color="#939393" />}
                onClick={handleClose}
                aria-label={''}
              />
            </HStack>
            <VStack gap="16px">
              <AssignAutocompleteField
                labelProps={{ ms: '15px', mb: '8px' }}
                options={displayingAssigned}
                value={currentAssigned}
                onSelected={handleSelectAssigned}
                selectedOptions={assigned.map((item) => item.id)}
                handleRemove={handleRemoveAssigned}
                label={t('calendar.share_my_calendar.share_with')}
                onChange={(e) => setCurrentAssigned(e.target.value)}
                placeholder={t(
                  'calendar.share_my_calendar.share_with_placeholder',
                )}
                w="100%"
              />

              <Button
                alignSelf="end"
                variant="h1cta"
                isLoading={loading || sendingInvites}
                onClick={async () => {
                  if (assigned?.length > 0) {
                    setSendingInvites(true);
                    try {
                      const { payload } = await dispatch(
                        createInvites(
                          assigned.map((u: User) => ({
                            businessID: myBusiness.id!,
                            email: u.email!,
                            recipientID: u.id!,
                            fullName: `${u.firstName} ${u.lastName}`,
                            tags: [],
                            type: 'calendar',
                          })),
                        ),
                      );
                      setSendingInvites(false);

                      if (payload?.response?.status === 409) {
                        return;
                      }
                      toast({
                        variant: payload?.id ? 'main' : 'error',
                        title: payload?.id
                          ? t('calendar.add_calendar_modal.toast.invite_sent')
                          : t(
                              'calendar.add_calendar_modal.toast.invite_failed',
                            ),
                      });
                    } catch (e) {
                      toast({
                        variant: 'error',
                        title: t(
                          'calendar.add_calendar_modal.toast.invite_failed',
                        ),
                      });
                    }
                  }
                  dispatch(closeShareMyCalendarDialog());
                }}
                p="15px 72px"
                isDisabled={assigned?.length === 0}>
                {t('calendar.share_my_calendar.share')}
              </Button>
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
