import { Flex, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { IoMdCheckmark } from 'react-icons/io';
interface RadioButtonItemProps {
  value: string;
  label: string;
  description?: string;
  checked: boolean;
  [x: string]: any;
  onChange: (value: string) => void;
}
export const CheckboxItem: React.FC<RadioButtonItemProps> = ({
  label,
  description,
  value,
  checked,
  onChange,
  ...props
}) => {
  return (
    <HStack
      w="100%"
      spacing={0}
      onClick={() => onChange(value)}
      cursor="pointer"
      {...props}>
      <VStack align="start" spacing={0} gap={0}>
        <Text fontWeight={'500'}>{label}</Text>
        <Text fontSize="sm" color="#00000090">
          {description}
        </Text>
      </VStack>
      <Spacer />
      <Flex
        borderWidth={2}
        borderColor="brand.900"
        cursor="pointer"
        w={4}
        h={4}
        align="center"
        onClick={() => onChange(value)}
        justify={'center'}
        borderRadius="50%"
        bg={checked ? 'brand.900' : 'transparent'}>
        {checked && <IoMdCheckmark color="white" />}
      </Flex>
    </HStack>
  );
};
