import { createIcon } from '@chakra-ui/react';

export const FbIcon = createIcon({
  displayName: 'FbIcon',
  viewBox: '0 0 9 16',
  path: (
    <path
      d="M8.02734 8.875H5.97656V15H3.24219V8.875H1V6.35938H3.24219V4.41797C3.24219 2.23047 4.55469 1 6.55078 1C7.50781 1 8.51953 1.19141 8.51953 1.19141V3.35156H7.39844C6.30469 3.35156 5.97656 4.00781 5.97656 4.71875V6.35938H8.41016L8.02734 8.875Z"
      strokeWidth="0.5"
    />
  ),
});

export const InstIcon = createIcon({
  displayName: 'InstIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M8.01562 4.39062C9.98437 4.39062 11.6094 6.01562 11.6094 7.98438C11.6094 9.98438 9.98437 11.5781 8.01562 11.5781C6.01562 11.5781 4.42187 9.98438 4.42187 7.98438C4.42187 6.01562 6.01562 4.39062 8.01562 4.39062ZM8.01562 10.3281C9.29687 10.3281 10.3281 9.29688 10.3281 7.98438C10.3281 6.70312 9.29687 5.67188 8.01562 5.67188C6.70312 5.67188 5.67187 6.70312 5.67187 7.98438C5.67187 9.29688 6.73437 10.3281 8.01562 10.3281ZM12.5781 4.26562C12.5781 4.73438 12.2031 5.10938 11.7344 5.10938C11.2656 5.10938 10.8906 4.73438 10.8906 4.26562C10.8906 3.79688 11.2656 3.42188 11.7344 3.42188C12.2031 3.42188 12.5781 3.79688 12.5781 4.26562ZM14.9531 5.10938C15.0156 6.26562 15.0156 9.73438 14.9531 10.8906C14.8906 12.0156 14.6406 12.9844 13.8281 13.8281C13.0156 14.6406 12.0156 14.8906 10.8906 14.9531C9.73437 15.0156 6.26562 15.0156 5.10937 14.9531C3.98437 14.8906 3.01562 14.6406 2.17188 13.8281C1.35938 12.9844 1.10938 12.0156 1.04688 10.8906C0.984375 9.73438 0.984375 6.26562 1.04688 5.10938C1.10938 3.98438 1.35938 2.98438 2.17188 2.17188C3.01562 1.35938 3.98437 1.10938 5.10937 1.04688C6.26562 0.984375 9.73437 0.984375 10.8906 1.04688C12.0156 1.10938 13.0156 1.35938 13.8281 2.17188C14.6406 2.98438 14.8906 3.98438 14.9531 5.10938ZM13.4531 12.1094C13.8281 11.2031 13.7344 9.01562 13.7344 7.98438C13.7344 6.98438 13.8281 4.79688 13.4531 3.85938C13.2031 3.26562 12.7344 2.76562 12.1406 2.54688C11.2031 2.17188 9.01562 2.26562 8.01562 2.26562C6.98437 2.26562 4.79688 2.17188 3.89062 2.54688C3.26562 2.79688 2.79688 3.26562 2.54688 3.85938C2.17188 4.79688 2.26562 6.98438 2.26562 7.98438C2.26562 9.01562 2.17188 11.2031 2.54688 12.1094C2.79688 12.7344 3.26562 13.2031 3.89062 13.4531C4.79688 13.8281 6.98437 13.7344 8.01562 13.7344C9.01562 13.7344 11.2031 13.8281 12.1406 13.4531C12.7344 13.2031 13.2344 12.7344 13.4531 12.1094Z"
      strokeWidth="0.5"
    />
  ),
});
