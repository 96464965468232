import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { PaginatedResults, UserPreferences } from 'services/@types';
import UserPreferencesService from 'services/userPreferences.api';
import store, { RootState } from '../store';

export interface UserPreferencesState {
  usersPreferencess: UserPreferences[];
  currentUserPreferences: UserPreferences | null;
  myUserPreferences: UserPreferences;
  userPreferences: UserPreferences | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: UserPreferencesState = {
  usersPreferencess: [],
  userPreferences: null,
  loading: false,
  error: null,
  currentUserPreferences: null,
  myUserPreferences: {
    activeCalendars: [],
  },
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const createUserPreferences = createAsyncThunk(
  'userPreferences/createUserPreferences',
  async (params: UserPreferences, { rejectWithValue }) => {
    try {
      const response = await UserPreferencesService.createUserPreferences(
        params,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getUsersPreferences = createAsyncThunk(
  'userPreferences/getUsersPreferences',
  async (params: PaginatedResults<UserPreferences>, { rejectWithValue }) => {
    try {
      const response = await UserPreferencesService.getUsersPreferences(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getUserPreferences = createAsyncThunk(
  'userPreferences/getUserPreferences',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await UserPreferencesService.getUserPreferences(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateUserPreferences = createAsyncThunk(
  'userPreferences/updateUserPreferences',
  async (
    params: { id: string; updates: Partial<UserPreferences> },
    { rejectWithValue },
  ) => {
    try {
      const response = await UserPreferencesService.updateUserPreferences(
        params.id,
        params.updates,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteUserPreferences = createAsyncThunk(
  'userPreferences/deleteUserPreferences',
  async (id: string, { rejectWithValue }) => {
    try {
      await UserPreferencesService.deleteUserPreferences(id);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchMyUserPreferences = createAsyncThunk(
  'userPreferences/getMyUserPreferences',
  async (_, { rejectWithValue }) => {
    try {
      const myId = store.getState().auth.user?.id;
      const response = await UserPreferencesService.getUserPreferences(myId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const UserPreferencesSlice = createSlice({
  name: 'UserPreferences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createUserPreferences.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUserPreferences.fulfilled, (state, action) => {
      state.loading = false;
      state.userPreferences = action.payload;
      state.usersPreferencess.push(action.payload);
    });
    builder.addCase(createUserPreferences.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(getUsersPreferences.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsersPreferences.fulfilled, (state, action) => {
      state.loading = false;
      state.usersPreferencess = action.payload.results;
      state.pagination = action.payload;
    });
    builder.addCase(getUsersPreferences.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(getUserPreferences.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserPreferences.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUserPreferences = action.payload;
    });
    builder.addCase(getUserPreferences.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(updateUserPreferences.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserPreferences.fulfilled, (state, action) => {
      state.loading = false;
      state.myUserPreferences = action.payload;
    });
    builder.addCase(updateUserPreferences.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteUserPreferences.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUserPreferences.fulfilled, (state, action) => {
      state.loading = false;
      state.usersPreferencess = state.usersPreferencess.filter(
        (pref: UserPreferences) => pref.id !== action.payload,
      );
    });
    builder.addCase(deleteUserPreferences.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchMyUserPreferences.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMyUserPreferences.fulfilled, (state, action) => {
      state.loading = false;
      state.myUserPreferences = action.payload;
    });
    builder.addCase(fetchMyUserPreferences.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const useMyUserPreferences = () => {
  return useSelector(
    (state: RootState) => state.userPreferences.myUserPreferences,
  );
};

export default UserPreferencesSlice.reducer;
