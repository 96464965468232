import { Icon, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MdInfoOutline } from 'react-icons/md';

interface AppTooltipProps {
  label: string;
}
export const AppTooltip: React.FC<AppTooltipProps> = ({
  children,
  label,
  ...rest
}) => {
  const { t } = useTranslation();
  const {
    isOpen: isOpenTooltip,
    onOpen: onOpenTooltip,
    onClose: onCloseTooltip,
    onToggle: onToggleTooltip,
  } = useDisclosure();
  return (
    <Tooltip
      hasArrow
      isOpen={isOpenTooltip}
      label={t(label)}
      variant="business"
      {...rest}>
      <span
        style={{
          display: 'flex',
        }}>
        <Icon
          fontSize="24px"
          onClick={onToggleTooltip}
          onMouseEnter={onOpenTooltip}
          onMouseLeave={onCloseTooltip}
          as={MdInfoOutline}
          color="brand.400"
        />
      </span>
    </Tooltip>
  );
};
