import { AxiosInstance, AxiosResponse } from 'axios';
import { IIntegration, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class IIntegrationService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async getIntegrations(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<IIntegration>> {
    const response: AxiosResponse<PaginatedResults<IIntegration>> =
      await this.api.get('/integrations', {
        params: queryParams,
      });
    return response.data;
  }

  public async getIIntegration(integrationId: string): Promise<IIntegration> {
    const response: AxiosResponse<IIntegration> = await this.api.get(
      `/integrations/${integrationId}`,
    );
    return response.data;
  }

  public async updateIIntegration(
    integrationId: string,
    updates: Partial<IIntegration>,
  ): Promise<IIntegration> {
    const response: AxiosResponse<IIntegration> = await this.api.patch(
      `/integrations/${integrationId}`,
      updates,
    );
    return response.data;
  }

  public async deleteIIntegration(integrationId: string): Promise<void> {
    await this.api.delete(`/integrations/${integrationId}`);
  }
}

const _businessService = new IIntegrationService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _businessService;
