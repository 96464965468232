import {
  Avatar,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { AppDialogProps } from 'contexts/redux/dialog/dialogsSlice';
// Custom components
import { RootState } from 'contexts/redux/store';
import { useBusinessContact } from 'hooks/useBusinessContact';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoWarning } from 'react-icons/io5';
import { MdCheck, MdClear } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Business } from 'services/@types';
import { DetailInfoItem } from './DetailInfoItem';

export interface SupplierFoundModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SupplierFoundModal: React.FC<SupplierFoundModalProps> = ({
  isOpen,
  onClose,
}) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const { t } = useTranslation();
  const supplierFoundDialog = useSelector(
    (state: RootState) => state.dialogs.supplierFoundDialog,
  );
  const dialog = supplierFoundDialog as AppDialogProps & { item: Business };
  const { businessContact } = useBusinessContact(dialog?.item);
  const { businessCategories } = useSelector(
    (state: RootState) => state.businessCategories,
  );
  return (
    <Modal isOpen={!!supplierFoundDialog} onClose={onClose} isCentered>
      <ModalOverlay bg={'#000000d3'} />
      <ModalContent
        borderRadius={'2xl'}
        overflow="hidden"
        maxW={'340px'}
        mx={2}
        pt={'20px'}
        borderColor="orange.400"
        borderWidth={1}>
        <Flex
          px={'22px'}
          py="10px"
          borderColor={'orange.400'}
          align="center"
          pb={6}
          gap={{ sm: 1, md: 2 }}>
          <ModalCloseButton onClick={dialog?.onClose} />
          <Avatar
            h={{ base: '40px', md: '60px' }}
            w={{ base: '40px', md: '60px' }}
            name={dialog?.item?.businessName}
            src={dialog?.item?.media
              ?.find((m: any) => m.type === 'profileImage')
              ?.url?.toString()}
            me="28px"
          />
          <Flex gap={0} flexDirection="column">
            <HStack align="center">
              <Text
                color={textColor}
                fontSize={{ base: 'sm', md: 'md' }}
                fontWeight="700"
                noOfLines={2}>
                {dialog?.item?.businessName}
              </Text>
              <Text
                color="brand.900"
                noOfLines={1}
                pt={1}
                fontWeight="700"
                fontSize={{ base: 'sm', sm: 'xs', '2xl': 'sm' }}
                display={dialog?.item?.category?.length > 0 ? 'flex' : 'none'}>
                {dialog?.item?.category
                  ?.map((c: any) =>
                    t(
                      `categories.${
                        businessCategories?.find((bc) => bc.id === c)?.name
                      }`,
                    ),
                  )
                  .join(', ')}
              </Text>
            </HStack>
            <Text
              color={textColor}
              fontSize={{ base: 'xs', md: 'md' }}
              fontWeight="300">
              {dialog?.item?.contacts?.[0]?.firstName}
            </Text>
          </Flex>
          <Flex align="center" ms="auto" />
        </Flex>

        <Flex w="100%" flex={1} position="relative">
          <Flex overflow="hidden" w="100%">
            <Flex
              pb="20px"
              direction="column"
              w="100%"
              boxSizing="border-box"
              gap={4}
              px={'20px'}>
              <Divider />

              <DetailInfoItem
                title={t('suppliers.company_email')}
                value={dialog?.item?.email}
              />
              <DetailInfoItem
                title={t('suppliers.company_phone')}
                value={dialog?.item?.phone}
              />
              <DetailInfoItem
                title={t('suppliers.business_id')}
                value={dialog?.item?.businessVATId}
              />
              <DetailInfoItem
                title={t('suppliers.address')}
                value={dialog?.item?.address}
              />
              <DetailInfoItem
                title={t('suppliers.email', {
                  contact: businessContact?.fullName,
                })}
                value={businessContact?.email}
              />
              <DetailInfoItem
                title={t('suppliers.contact_phone', {
                  contact: businessContact?.fullName,
                })}
                value={businessContact?.phone}
              />
            </Flex>
          </Flex>
        </Flex>
        <VStack
          bg={'orange.400'}
          py={4}
          px={5}
          gap={{ sm: 2, md: 0 }}
          align={'center'}>
          <Icon as={IoWarning} color="white" fontSize={'22px'} />
          <VStack align={'center'} spacing={0}>
            <Text color={'white'}>
              {t('suppliers.modals.found_supplier.title')}
            </Text>
            <Text color="white" fontWeight="bold">
              {t('suppliers.modals.found_supplier.question')}
            </Text>
          </VStack>
          <Spacer />
          <HStack gap={'38px'} alignSelf="center">
            <IconButton
              aria-label="approve-merge"
              color="green.300"
              p={1}
              borderRadius={'xl'}
              size="sm"
              onClick={dialog?.onConfirm}
              as={MdCheck}
            />
            <IconButton
              size="sm"
              borderRadius={'xl'}
              aria-label="clear"
              color={'red.400'}
              as={MdClear}
              onClick={dialog?.onClose}
              p={1}
            />
          </HStack>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
