import { mode } from '@chakra-ui/theme-tools';
export const switchStyles = {
  components: {
    Switch: {
      baseStyle: {
        thumb: {
          fontWeight: 400,
          borderRadius: '50%',
          w: '14px',
          h: '14px',
          _checked: { transform: 'translate(18px, 0px)' },
        },
        track: {
          display: 'flex',
          alignItems: 'center',
          boxSizing: 'border-box',
          w: '36px',
          h: '18px',
          p: '2px',
          ps: '2px',
          _focus: {
            boxShadow: 'none',
          },
        },
      },

      variants: {
        main: (props: any) => ({
          track: {
            bg: mode('brand.900', 'brand.900')(props),
          },
        }),
        brand: (props: any) => ({
          track: {
            _checked: {
              bg: mode(`brand.900`, `$brand.900`)(props),
            },
          },
          thumb: {
            _checked: {
              transform:
                props.dir === 'ltr'
                  ? 'translate(18px, 0px)'
                  : 'translate(-18px, 0px)',
            },
          },
        }),
      },
    },
  },
};
