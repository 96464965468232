// src/features/items/userSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { MediaItem, PaginatedResults } from 'services/@types';
import MediaItemService from '../../../services/mediaItem.api';
import {
  closeAddPhotoDialog,
  closeAlertDialog,
  closeSplashDialog,
  showSplashDialog,
} from '../dialog/dialogsSlice';
import store, { RootState } from '../store';
export interface MediaItemState {
  items: MediaItem[];
  upload: MediaItem | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: MediaItemState = {
  items: [],
  upload: null,
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const createMediaItem = createAsyncThunk(
  'mediaItem/createMediaItem',
  async (mediaItem: MediaItem, { rejectWithValue }) => {
    try {
      store.dispatch(
        showSplashDialog({
          title: 'signup.gallery.mediaItem_info.creating_mediaItem',
        }),
      );
      const newMediaItem = await MediaItemService.createMediaItem(mediaItem);
      console.log('#Redux create mediaItem ->', newMediaItem);
      store.dispatch(closeSplashDialog());

      return newMediaItem;
    } catch (error) {
      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);

export const fetchMediaItems = createAsyncThunk(
  'items/fetchMediaItems',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await MediaItemService.getMediaItems(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateMediaItem = createAsyncThunk(
  'items/updateMediaItem',
  async (
    {
      mediaItemId,
      updates,
    }: {
      mediaItemId: string;
      updates?: Partial<MediaItem>;
    },
    { rejectWithValue },
  ) => {
    try {
      delete updates?.updatedAt;
      delete updates?.createdAt;
      delete updates?.id;
      delete updates.type;
      delete updates.albumID;
      delete updates.privacy;

      const response = await MediaItemService.updateMediaItem(
        mediaItemId,
        updates,
      );
      store.dispatch(closeAddPhotoDialog());
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteMediaItem = createAsyncThunk(
  'upload/deleteMediaItem',
  async (
    {
      mediaItemId,
    }: {
      mediaItemId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      await MediaItemService.deleteMediaItem(mediaItemId);
      store.dispatch(closeAlertDialog());
      return mediaItemId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const mediaItemSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMediaItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createMediaItem.fulfilled,
        (state, action: PayloadAction<MediaItem>) => {
          state.items.push(action.payload);
          state.upload = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        createMediaItem.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      .addCase(fetchMediaItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchMediaItems.fulfilled,
        (state, action: PayloadAction<PaginatedResults<MediaItem>>) => {
          state.items = action.payload.results;
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      // Update a upload
      .addCase(updateMediaItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateMediaItem.fulfilled,
        (state, action: PayloadAction<MediaItem>) => {
          const index = state.items.findIndex(
            (item) => item.createdAt === action.payload.createdAt,
          );
          if (index !== -1) {
            state.items[index] = action.payload;
          }
          state.upload = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        updateMediaItem.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      // Delete a upload
      .addCase(deleteMediaItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteMediaItem.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.items = state.items.filter(
            (item) => item.id !== action.payload,
          );
          state.loading = false;
        },
      )
      .addCase(
        deleteMediaItem.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      );
  },
});

export function useMyMediaItems() {
  const mediaItems = useSelector((state: RootState) => state.mediaItem.items);
  return mediaItems;
}

export default mediaItemSlice.reducer;
