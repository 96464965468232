import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from '@tanstack/react-query';
import { Invite } from 'services/@types';
import _inviteService from 'services/invite.api';

interface UpdateInviteParams {
  id: string;
  updates: Partial<Invite>;
}

const useUpdateInvite = (): UseMutationResult<
  Invite,
  Error,
  UpdateInviteParams
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UpdateInviteParams) =>
      _inviteService.updateInvite(params.id, params.updates),
    onSuccess: (updatedInvite) => {
      // Update the invite in the cache
      queryClient.setQueryData(['invite', updatedInvite.id], updatedInvite);

      // Invalidate and refetch any queries that may be affected by this update
      queryClient.invalidateQueries({ queryKey: ['invites'] });
    },
    onError: (error) => {
      // Handle error (e.g., show a toast notification)
      console.error('Failed to update invite:', error);
    },
  });
};

export default useUpdateInvite;
