import { AxiosInstance, AxiosResponse } from 'axios';
import { BusinessCategory, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class BusinessCategoryService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createBusinessCategory(
    business: BusinessCategory,
  ): Promise<BusinessCategory> {
    const response: AxiosResponse<BusinessCategory> = await this.api.post(
      '/categories',
      business,
    );
    return response.data;
  }

  public async getBusinessCategories(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<BusinessCategory>> {
    const response: AxiosResponse<PaginatedResults<BusinessCategory>> =
      await this.api.get('/categories', {
        params: queryParams,
      });
    return response.data;
  }

  public async getBusinessCategory(
    businessId: string,
  ): Promise<BusinessCategory> {
    const response: AxiosResponse<BusinessCategory> = await this.api.get(
      `/categories/${businessId}`,
    );
    return response.data;
  }

  public async updateBusinessCategory(
    businessId: string,
    updates: Partial<BusinessCategory>,
  ): Promise<BusinessCategory> {
    const response: AxiosResponse<BusinessCategory> = await this.api.patch(
      `/categories/${businessId}`,
      updates,
    );
    return response.data;
  }

  public async deleteBusinessCategory(businessId: string): Promise<void> {
    await this.api.delete(`/categories/${businessId}`);
  }
}

const _businessService = new BusinessCategoryService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _businessService;
