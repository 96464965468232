export const iconButtonStyles = {
  components: {
    Button: {
      baseStyle: {},
      variants: {
        actionIconBlue: (props: any) => ({
          h: props.size === 'sm' ? '24px' : '32px',
          w: props.size === 'sm' ? '24px' : '32px',
          minW: props.size === 'sm' ? '24px' : '32px',
          borderRadius: props.size === 'sm' ? '8px' : '10px',
          color: 'white',
          cursor: 'pointer',
          bg: '#93C3B3',
          _active: {
            bg: '#465D55',
          },
        }),
        actionIconGrey: (props: any) => ({
          h: props.size === 'sm' ? '24px' : '32px',
          w: props.size === 'sm' ? '24px' : '32px',
          minW: props.size === 'sm' ? '24px' : '32px',
          borderRadius: props.size === 'sm' ? '8px' : '10px',
          color: 'white',
          cursor: 'pointer',
          bg: '#ACACAC',
          _active: {
            bg: '#00000080',
          },
        }),
        actionIconTransparent: (props: any) => ({
          h: props.size === 'sm' ? '24px' : '32px',
          w: props.size === 'sm' ? '24px' : '32px',
          minW: props.size === 'sm' ? '24px' : '32px',
          borderRadius: props.size === 'sm' ? '8px' : '10px',
          color: '#93C3B3',
          cursor: 'pointer',
          bg: 'transparent',
          _active: {
            color: '#465D55',
            bg: 'transparent',
          },
          _focus: {
            color: '#465D55',
            bg: 'transparent',
          },
        }),
        actionIconRed: (props: any) => ({
          h: props.size === 'sm' ? '24px' : '32px',
          w: props.size === 'sm' ? '24px' : '32px',
          minW: props.size === 'sm' ? '24px' : '32px',
          borderRadius: props.size === 'sm' ? '8px' : '10px',
          color: 'white',
          cursor: 'pointer',
          bg: '#FF4D4F',
          _active: {
            bg: '#cc3e3f',
          },
          _focus: {
            bg: '#cc3e3f',
          },
        }),
      },
    },
  },
};
