import { useEffect } from 'react';
import { GAEventAction, GAEventCategory, GAEventLabel } from 'types/ga-enums';
import { sendEvent } from 'utils/analytics.util';

export const useMeasureUserLeftPage = (
  eventAction: GAEventAction,
  eventCategory: GAEventCategory,
  eventLabel: GAEventLabel,
  enabled: boolean = true,
) => {
  useEffect(() => {
    if (!enabled) return;
    const handleBeforeUnload = (event: any) => {
      sendEvent(eventCategory, eventAction, eventLabel);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [enabled, eventAction, eventCategory, eventLabel]);
};

export default useMeasureUserLeftPage;
